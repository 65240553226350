
body {
    font-family: 'Montserrat', sans-serif;
}

p {
  @apply text-gray-600;
}

.container {
  @apply mx-auto px-5;
}
.btn {
  @apply text-white bg-blue-600 px-10 py-3 rounded-full duration-300;
}
.btn:hover {
  @apply bg-blue-500;
}
.sticky {
  @apply fixed bg-white shadow;
}
.collapse.show {
  @apply block;
}
.toggler-icon {
  @apply block bg-gray-700 relative duration-300;
  height: 2px;
  width: 30px;
  margin: 6px 0;
}
.active>.toggler-icon:nth-child(1) {
  @apply transform rotate-45;
  top: 7px;
}
.active>.toggler-icon:nth-child(2) {
  @apply opacity-0;
}
.active>.toggler-icon:nth-child(3) {
  top: -8px;
  transform: rotate(135deg);
}
.page-scroll {
  @apply block px-5 py-2 uppercase text-gray-700 duration-300;
}
.page-scroll.active,
.page-scroll:hover {
  @apply text-blue-600;
}
.section-heading {
  @apply text-4xl text-gray-700 font-bold tracking-wide;
}

.icon {
  @apply mb-4 text-blue-600;
}

.service-title {
  @apply text-gray-800 font-semibold text-lg block mb-3 uppercase;
}

.feature-title {
  @apply text-gray-800 font-medium block mb-3;
}
.team-item {
  @apply m-3 text-center bg-white shadow duration-300;
}
.team-item:hover {
  @apply shadow-lg;
}
.team-overlay {
  @apply w-full h-full flex items-center justify-center absolute top-0 left-0 bg-gray-200 duration-300 opacity-0;
}
.team-overlay:hover {
  @apply opacity-100;
}

.social-link {
  @apply flex justify-center items-center w-10 h-10 bg-blue-600 rounded text-lg text-white duration-300;
}
.team-name {
  @apply text-gray-800 font-bold uppercase text-lg my-2;
}

.client-logo {
  @apply opacity-50 duration-300;
}
  
.main-btn {
  @apply inline-block  px-8 py-4 font-bold text-white duration-300  rounded-full;
}
.main-btn:hover {
  @apply bg-blue-400;
}

.gradient-btn {
  background-image: linear-gradient(to right, #0067f4 0%, #2bdbdc 50%, #0067f4 100%);
  background-size: 200% auto;
  overflow: hidden;
}
.gradient-btn:hover {
  background-position: right center;
  @apply shadow-xl;
}


.client-logo:hover {
  @apply opacity-100;
}
.tns-nav {
  @apply absolute w-full bottom-0 -mb-16 text-center;
}
.tns-nav button {
  @apply w-3 h-3 rounded-full bg-white opacity-50 mx-1 my-6 duration-300;
}
.tns-nav>.tns-nav-active,
.tns-nav button:hover {
  @apply opacity-100;
}
.tns-nav button:focus {
  @apply outline-none;
}
.pricing-box {
  @apply m-3 text-center p-6 pb-10 shadow rounded duration-300;
}
.pricing-box:hover {
  @apply shadow-lg bg-blue-100;
}
.package-name {
  @apply text-blue-600 font-bold uppercase text-sm;
}

.form-input {
  @apply w-full mb-5 bg-white border border-gray-500 px-4 py-2 duration-300 outline-none
}
.form-input:focus {
  @apply border-blue-600;
}
.contact-icon {
  @apply w-10 h-10 flex items-center justify-center bg-blue-600 text-xl text-white rounded-full;
}

.footer-links {
  @apply leading-9 text-gray-100 duration-300;
}
.footer-links:hover {
  @apply text-blue-500;
}
.footer-icon {
  @apply flex justify-center items-center w-8 h-8 bg-white rounded-full text-sm text-gray-700 duration-300;
}
.footer-icon:hover {
  @apply text-white;
}

#google-map-area{
  width: 100vw;
  height: 40vh;
  background: url(/public/assets/images/hacc-geo-location.png);
  background-size: cover;
}