.hacc__infobar{
    /* display: none; */
    /* position: absolute; */

    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 41.8px 33.4px rgba(0, 0, 0, 0.086);

    padding: .5rem 6rem;
    background-color: #193C40;
}

.hacc__infobar-links{
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}


.hacc__infobar-links_container {
    display: flex;
    flex-direction: row;
}

.hacc__infobar-links_container  p{
    display: flex;
    justify-content:center;
    align-items: center;

    font-family: var(--font-family);
    font-weight: bolder;
    font-size: 15px;
    line-height: 25px;
    text-transform: capitalize;

    margin: 0 1rem;
    cursor: pointer;
    color: white;

}


.hacc__infobar-links_container p a{
    color: aliceblue;
    margin-left: .3rem;
}





@media screen and ( max-width: 1237px ) {

    .hacc__infobar{ 
        display: none;
    }

     
}


/* 
.nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
  }
  
  .nav-link {
    display: block;
    padding: 0.5rem 1rem;
    -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
    -o-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
  }
  
  @media (prefers-reduced-motion: reduce) {
    .nav-link {
      -webkit-transition: none;
      -o-transition: none;
      transition: none;
    }
  }
  
  .nav-link:hover, .nav-link:focus {
    text-decoration: none;
  }
  
  .nav-link.disabled {
    color: #C3C1C8;
    pointer-events: none;
    cursor: default;
  }
  
  .nav-tabs {
    border-bottom: 1px solid #E1E0E4;
  }
  
  .nav-tabs .nav-link {
    margin-bottom: -1px;
    background: none;
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }
  
  .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
    border-color: #EBEBED #EBEBED #E1E0E4;
    isolation: isolate;
  }
  
  .nav-tabs .nav-link.disabled {
    color: #C3C1C8;
    background-color: transparent;
    border-color: transparent;
  }
  
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #7D7987;
    background-color: #fff;
    border-color: #E1E0E4 #E1E0E4 #fff;
  }
  
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  
  .nav-pills .nav-link {
    background: none;
    border: 0;
    border-radius: 0.25rem;
  }
  
  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    color: #fff;
    background-color: #2500F9;
  }
  
  .nav-fill > .nav-link,
  .nav-fill .nav-item {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    text-align: center;
  }
  
  .nav-justified > .nav-link,
  .nav-justified .nav-item {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    text-align: center;
  }
  
  .tab-content > .tab-pane {
    display: none;
  }
  
  .tab-content > .active {
    display: block;
  }
  
  .myNavbar {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding-top: 0.5rem;
    padding-right: 1rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
  }
  
  .myNavbar > .myContainer,
  .myNavbar > .myContainer-fluid, .myNavbar > .myContainer-sm, .myNavbar > .myContainer-md, .myNavbar > .myContainer-lg, .myNavbar > .myContainer-xl, .myNavbar > .myContainer-xxl {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: inherit;
    flex-wrap: inherit;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  
  .myNavbar-brand {
    padding-top: -0.046rem;
    padding-bottom: -0.046rem;
    margin-right: 1rem;
    font-size: calc(1.2978rem + 0.5736vw);
    white-space: nowrap;
  }
  
  @media (min-width: 1200px) {
    .myNavbar-brand {
      font-size: 1.728rem;
    }
  }
  
  .myNavbar-brand:hover, .myNavbar-brand:focus {
    text-decoration: none;
  }
  
  .myNavbar-nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
  }
  
  .myNavbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0;
  }
  
  .myNavbar-nav .dropdown-menu {
    position: static;
  }
  
  .myNavbar-text {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  
  .myNavbar-collapse {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .myNavbar-toggler {
    padding: 0.25rem 0.75rem;
    font-size: 1.2rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0.313rem;
    -webkit-transition: -webkit-box-shadow 0.15s ease-in-out;
    transition: -webkit-box-shadow 0.15s ease-in-out;
    -o-transition: box-shadow 0.15s ease-in-out;
    transition: box-shadow 0.15s ease-in-out;
    transition: box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  }


.collapsed .collapse-icon {
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
}
  
  @media (prefers-reduced-motion: reduce) {
    .myNavbar-toggler {
      -webkit-transition: none;
      -o-transition: none;
      transition: none;
    }
  }
  
  .myNavbar-toggler:hover {
    text-decoration: none;
  }
  
  .myNavbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    -webkit-box-shadow: 0 0 0 0;
    box-shadow: 0 0 0 0;
  }
  
  .myNavbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
  }
  
  .myNavbar-nav-scroll {
    max-height: var(--bs-scroll-height, 75vh);
    overflow-y: auto;
  }
  
  @media (min-width: 576px) {
    .myNavbar-expand-sm {
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start;
    }
    .myNavbar-expand-sm .myNavbar-nav {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
    }
    .myNavbar-expand-sm .myNavbar-nav .dropdown-menu {
      position: absolute;
    }
    .myNavbar-expand-sm .myNavbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem;
    }
    .myNavbar-expand-sm .myNavbar-nav-scroll {
      overflow: visible;
    }
    .myNavbar-expand-sm .myNavbar-collapse {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-preferred-size: auto;
      flex-basis: auto;
    }
    .myNavbar-expand-sm .myNavbar-toggler {
      display: none;
    }
  }
  
  @media (min-width: 768px) {
    .myNavbar-expand-md {
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start;
    }
    .myNavbar-expand-md .myNavbar-nav {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
    }
    .myNavbar-expand-md .myNavbar-nav .dropdown-menu {
      position: absolute;
    }
    .myNavbar-expand-md .myNavbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem;
    }
    .myNavbar-expand-md .myNavbar-nav-scroll {
      overflow: visible;
    }
    .myNavbar-expand-md .myNavbar-collapse {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-preferred-size: auto;
      flex-basis: auto;
    }
    .myNavbar-expand-md .myNavbar-toggler {
      display: none;
    }
  }
  
  @media (min-width: 992px) {
    .myNavbar-expand-lg {
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start;
    }
    .myNavbar-expand-lg .myNavbar-nav {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
    }
    .myNavbar-expand-lg .myNavbar-nav .dropdown-menu {
      position: absolute;
    }
    .myNavbar-expand-lg .myNavbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem;
    }
    .myNavbar-expand-lg .myNavbar-nav-scroll {
      overflow: visible;
    }
    .myNavbar-expand-lg .myNavbar-collapse {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-preferred-size: auto;
      flex-basis: auto;
    }
    .myNavbar-expand-lg .myNavbar-toggler {
      display: none;
    }
  }
  
  @media (min-width: 1200px) {
    .myNavbar-expand-xl {
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start;
    }
    .myNavbar-expand-xl .myNavbar-nav {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
    }
    .myNavbar-expand-xl .myNavbar-nav .dropdown-menu {
      position: absolute;
    }
    .myNavbar-expand-xl .myNavbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem;
    }
    .myNavbar-expand-xl .myNavbar-nav-scroll {
      overflow: visible;
    }
    .myNavbar-expand-xl .myNavbar-collapse {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-preferred-size: auto;
      flex-basis: auto;
    }
    .myNavbar-expand-xl .myNavbar-toggler {
      display: none;
    }
  }
  
  @media (min-width: 1540px) {
    .myNavbar-expand-xxl {
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start;
    }
    .myNavbar-expand-xxl .myNavbar-nav {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
    }
    .myNavbar-expand-xxl .myNavbar-nav .dropdown-menu {
      position: absolute;
    }
    .myNavbar-expand-xxl .myNavbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem;
    }
    .myNavbar-expand-xxl .myNavbar-nav-scroll {
      overflow: visible;
    }
    .myNavbar-expand-xxl .myNavbar-collapse {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-preferred-size: auto;
      flex-basis: auto;
    }
    .myNavbar-expand-xxl .myNavbar-toggler {
      display: none;
    }
  }
  
  .myNavbar-expand {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  
  .myNavbar-expand .myNavbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  
  .myNavbar-expand .myNavbar-nav .dropdown-menu {
    position: absolute;
  }
  
  .myNavbar-expand .myNavbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
}

.myNavbar-expand .myNavbar-nav-scroll {
overflow: visible;
}

.myNavbar-expand .myNavbar-collapse {
display: -webkit-box !important;
display: -ms-flexbox !important;
display: flex !important;
-ms-flex-preferred-size: auto;
flex-basis: auto;
}
  
.myNavbar-expand .myNavbar-toggler {
display: none;
}

.py-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
}

  
  .myNavbar-light .myNavbar-brand {
    color: #1F1534;
  }
  
  .myNavbar-light .myNavbar-brand:hover, .myNavbar-light .myNavbar-brand:focus {
    color: #1F1534;
  }
  
  .myNavbar-light .myNavbar-nav .nav-link {
    color: rgba(0, 0, 0, 0.95);
  }
  
  .myNavbar-light .myNavbar-nav .nav-link:hover, .myNavbar-light .myNavbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.95);
  }
  
  .myNavbar-light .myNavbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.25);
  }
  
  .myNavbar-light .myNavbar-nav .show > .nav-link,
  .myNavbar-light .myNavbar-nav .nav-link.active {
    color: #1F1534;
  }
  
  .myNavbar-light .myNavbar-toggler {
    color: rgba(0, 0, 0, 0.95);
    border-color: rgba(0, 0, 0, 0.1);
  }
  
  .myNavbar-light .myNavbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba%280, 0, 0, 0.95%29' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M0 6h30M0 14h30M0 22h30'/%3E%3C/svg%3E");
  }
  
  .myNavbar-light .myNavbar-text {
    color: rgba(0, 0, 0, 0.95);
  }
  
  .myNavbar-light .myNavbar-text a,
  .myNavbar-light .myNavbar-text a:hover,
  .myNavbar-light .myNavbar-text a:focus {
    color: #1F1534;
  }
  
  .myNavbar-dark .myNavbar-brand {
    color: #fff;
  }
  
  .myNavbar-dark .myNavbar-brand:hover, .myNavbar-dark .myNavbar-brand:focus {
    color: #fff;
  }
  
  .myNavbar-dark .myNavbar-nav .nav-link {
    color: rgba(255, 255, 255, 0.7);
  }
  
  .myNavbar-dark .myNavbar-nav .nav-link:hover, .myNavbar-dark .myNavbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.9);
  }
  
  .myNavbar-dark .myNavbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25);
  }
  
  .myNavbar-dark .myNavbar-nav .show > .nav-link,
  .myNavbar-dark .myNavbar-nav .nav-link.active {
    color: #fff;
  }
  
  .myNavbar-dark .myNavbar-toggler {
    color: rgba(255, 255, 255, 0.7);
    border-color: rgba(255, 255, 255, 0.1);
  }
  
  .myNavbar-dark .myNavbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.7%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  }
  
  .myNavbar-dark .myNavbar-text {
    color: rgba(255, 255, 255, 0.7);
  }
  
  .myNavbar-dark .myNavbar-text a,
  .myNavbar-dark .myNavbar-text a:hover,
  .myNavbar-dark .myNavbar-text a:focus {
    color: #fff;
  }

.fs-2 {
    font-size: 1.44rem !important;
}

.text-warning {
    color: #FCAE61 !important;
}

.text-1000 {
    color: #0f48f2 !important;
}

.align-items-center {
-webkit-box-align: center !important;
-ms-flex-align: center !important;
align-items: center !important;
}
  
.fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
}

.ps-lg-5 {
    padding-left: 3rem !important;
}


.fw-medium {
    font-weight: 500 !important;
}

.border-top {
    border-top: 1px solid #E1E0E4 !important;
}

.border-lg-0 {
    border: 0 !important;
}

.mt-lg-0 {
    margin-top: 0 !important;
}


.myContainer,
.myContainer-fluid,
.myContainer-sm,
.myContainer-md,
.myContainer-lg,
.myContainer-xl,
.myContainer-xxl {
  width: 100%;
  padding-right: var(--bs-gutter-x, 1rem);
  padding-left: var(--bs-gutter-x, 1rem);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .myContainer, .myContainer-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .myContainer, .myContainer-sm, .myContainer-md {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .myContainer, .myContainer-sm, .myContainer-md, .myContainer-lg {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .myContainer, .myContainer-sm, .myContainer-md, .myContainer-lg, .myContainer-xl {
    max-width: 1140px;
  }
}

@media (min-width: 1540px) {
  .myContainer, .myContainer-sm, .myContainer-md, .myContainer-lg, .myContainer-xl, .myContainer-xxl {
    max-width: 1480px;
  }
}


.accordion-button.collapsed {
    border-bottom-width: 0;
  }
  
  .accordion-button:not(.collapsed) {
    color: #1F1534;
    background-color: #F5F7FA;
  }
  
  .accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-dash' viewBox='0 0 16 16'%3E%3Cpath d='M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z'/%3E%3C/svg%3E");
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  
  .accordion-button::after {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    width: 1.8rem;
    height: 1.8rem;
    margin-left: auto;
    content: "";
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-plus' viewBox='0 0 16 16'%3E%3Cpath d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: 1.8rem;
    -webkit-transition: -webkit-transform 0.2s ease-in-out;
    transition: -webkit-transform 0.2s ease-in-out;
    -o-transition: transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
  }
  
  @media (prefers-reduced-motion: reduce) {
    .accordion-button::after {
      -webkit-transition: none;
      -o-transition: none;
      transition: none;
    }
  }
  
  .accordion-button:hover {
    z-index: 2;
  }
  
  .accordion-button:focus {
    z-index: 3;
    border-color: #fff;
    outline: 0;
    -webkit-box-shadow: #E2EAED;
    box-shadow: #E2EAED;
  }
  
  .accordion-header {
    margin-bottom: 0;
  }
  
  .accordion-item:first-of-type .accordion-button {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  
  .accordion-item:last-of-type .accordion-button.collapsed {
    border-bottom-width: 1px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  
  .accordion-item:last-of-type .accordion-collapse {
    border-bottom-width: 1px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  
  .accordion-collapse {
    border: solid rgba(255, 255, 255, 0.125);
    border-width: 0 1px;
  }
  
  .accordion-body {
    padding: 1.5rem 2.6rem;
  }
  
  .accordion-flush .accordion-button {
    border-right: 0;
    border-left: 0;
    border-radius: 0;
  }
  
  .accordion-flush .accordion-collapse {
    border-width: 0;
  }
  
  .accordion-flush .accordion-item:first-of-type .accordion-button {
    border-top-width: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  
  .accordion-flush .accordion-item:last-of-type .accordion-button.collapsed {
    border-bottom-width: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  

.ms-auto {
    margin-left: auto !important;
}

.pt-lg-0 {
    padding-top: 0 !important;
} */
