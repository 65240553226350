


.btn {
    display: inline-block;
    color: white;
    text-align: center;
    padding: 1.75em 3.5em;
    white-space: nowrap;
    border-radius: 5px;
  }
  
  .product {
    position: relative;
    min-width: 200px;
    padding: 40px;
    border-radius: 8px;
    background-color: white;
  }
  
  .product__image {
    width: 100px;
    margin-left: 40%;
    /* margin-right: -40%; */
    /* margin-top: -5px; */
    margin-bottom: 30px;
    max-width: none;
  }
  
  .product__title {
    font-size: 30px;
    color: black;
    margin: 0 0 0.5em 0;
    line-height: 1.1;
  }
  
  .product__price {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: -30px;
    left: -30px;
    width: 100px;
    height: 100px;
    background-color: rgba(black, 0.95);
    color: white;
    border-radius: 50%;
  }
  
  .product__category {
    display: block;
    margin-bottom: 1em;
  }



.gradient-btn {
    background-image: linear-gradient(to right, #0067f4 0%, #2bdbdc 50%, #0067f4 100%);
    background-size: 200% auto;
    overflow: hidden;
  }
  .gradient-btn:hover {
    background-position: right center;
    @apply shadow-xl;
  }
  