
.header_css{
    height: 100vh;
    width: 100vw;
    position: relative;
    z-index: 0;

    display: flex;
    align-items: center;
    justify-content: center;
}

.header_css h1{
    font-family: "Incosolata", Consolas, monospace;
    font-size: 4em;
    color: #f9f9f9;
    z-index: 1;
    position: absolute;
    left: 120px;
    
}

#slide3{
    background-image: url(/public/assets/images/home/hacc_entrance\ Large.jpeg);

    background-position: center;
    position: relative;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

#slide2{

    background-image: url(/public/assets/images/events/celor-au-friends-food/celor-au-frien-foods-large.jpeg);
    background-position: center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    text-align: center;

}

#slide2 > h2{
    margin-left: auto;
    position: relative;
    color: rgb(255, 255, 255);
    font-size: 4em;
    justify-self: center;
    align-self: center;
    margin: 10px;
    margin-right: 20px;

}

#slide2 > p{
    color: #fff;
    font-weight: bold;
    font-size: 2em;
    margin-bottom: 90px;
    translate: -50px;
    text-align: center;
}

#slide2 > p span{
    background-color: #F02A1A;
    padding: 5px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 25px;
    cursor: pointer;
}

#slide2 > p span:hover{
    color:#fff;
}


#slide1{
    background-image: url(/public/assets/images/reciepes/De-meilleurs-grillades-avec-celor.jpeg);
    background-size: cover;
}


.wave {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden; 
    line-height: 0;
}

.wave svg {
    position: relative;
    display: block;
    width: calc(125% + 1.3px);
    height: 119px;
    transform: rotateY(180deg);
}

.wave .shape-fill {
    fill: #ffffff;
}

.waveb {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.waveb svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 97px;
    transform: rotateY(180deg);
}

.waveb .shape-fill {
    fill: #FFFFFF;
}


#visual{
    position: absolute;
    left: 0;
    height: 100%;
    /* width: 100%; */
}




.swiper {
    width: 100%;
    height: 100%;
}
  
.swiper-slide {
text-align: center;
font-size: 18px;
background: #fff;

/* Center slide text vertically */
display: -webkit-box;
display: -ms-flexbox;
display: -webkit-flex;
display: flex;
-webkit-box-pack: center;
-ms-flex-pack: center;
-webkit-justify-content: center;
justify-content: center;
-webkit-box-align: center;
-ms-flex-align: center;
-webkit-align-items: center;
align-items: center;
}
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  





@media screen and (max-width: 1050px) {
    .hacc__header{
        flex-direction: column;
    }
    .hacc__header-content{
        margin: 0 0 3rem;
    }
}


@media screen and (max-width: 872px) {

    #slide2 > p{
        margin-left: 100px;

    }
}


@media screen and (max-width: 597px) {

    #slide3 > h1{
        font-size: 50px;
        text-align: center;

    }
}



@media screen and (max-width: 447px) {
   
    #slide2 > h2{
        font-size: 3em;
        margin-bottom: 10px;
    }

    #slide2 > p{
        margin-left: 100px;
        font-size: 2em;

    }

  
}


@media screen and (max-width: 400px) {

    #slide2 > h2{
        font-size: 2em;
    }

    #slide2 > p{
        margin-left: 100px;
        font-size: 1em;
    }
    #slide3 > h1{
        font-size: 30px;

    }

}

