/* .container{

  width: 100%;
  padding-right: var(--bs-gutter-x, 1rem);
  padding-left: var(--bs-gutter-x, 1rem);
  margin-right: auto;
  margin-left: auto;

}


.card {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #FFFEFE;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.5rem;
}


.card-span {
    -webkit-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
    border: 0;
}


.card-body {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1rem 1rem;
}


.justify-content-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
}


.row {
    --bs-gutter-x: 2rem;
    --bs-gutter-y: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-top: calc(var(--bs-gutter-y) * -1);
    margin-right: calc(var(--bs-gutter-x) / -2);
    margin-left: calc(var(--bs-gutter-x) / -2);
  }
  
  .row > * {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) / 2);
    padding-left: calc(var(--bs-gutter-x) / 2);
    margin-top: var(--bs-gutter-y);
  }
  
  .col {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
}


.col-md-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .col-md-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .col-md-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .col-md-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 66.66667%;
}

.border-0 {
    border: 0 !important;
}

.order-md-1 {
    -webkit-box-ordinal-group: 2 !important;
    -ms-flex-order: 1 !important;
    order: 1 !important;
}


.img-fluid {
    max-width: 100%;
    height: auto;
}

.w-100 {
    width: 100% !important;
}

.fit-cover {
    -o-object-fit: cover;
    object-fit: cover;
}
  

.h-100 {
    height: 100% !important;
}


.rounded-top {
    border-top-left-radius: 0.5rem !important;
    border-top-right-radius: 0.5rem !important;
}

.rounded-md-end {
    border-top-right-radius: 0.5rem !important;
    border-bottom-right-radius: 0.5rem !important;
}
.rounded-md-top-0 {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
}

.card-title {
    margin-bottom: 0.5rem;
}
.mt-xl-5 {
    margin-top: 3rem !important;
}

.fs-1 {
    font-size: 1.2rem !important;
}

.d-grid {
    display: grid !important;
}


.bottom-0 {
    bottom: 0 !important;
}
.mt-xl-6 {
    margin-top: 4rem !important;
}

.btn-primary {
    color: #616161;
    background-color: #FFB30E;
    border-color: #FFB30E;
}


.btn-lg, .btn-group-lg > .btn {
    padding: 0.5rem 2.8rem;
    font-size: 1rem;
    border-radius: 0.35rem;
}

.col-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 100%;
}

 */


/* -------------------------------------------------------------------------- */
/*                                 Theme                                      */
/* -------------------------------------------------------------------------- */
/* prettier-ignore */
/* -------------------------------------------------------------------------- */
/*                                  Utilities                                 */
/* -------------------------------------------------------------------------- */
/*-----------------------------------------------
|   Bootstrap Styles
-----------------------------------------------*/
/*!
 * Bootstrap v5.0.0-alpha1 (https://getbootstrap.com/)
 * Copyright 2011-2020 The Bootstrap Authors
 * Copyright 2011-2020 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
 
  *,
  *::before,
  *::after {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    :root {
      scroll-behavior: smooth;
    }
  }
  
  body {
    margin: 0;
    font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #424242;
    background-color: #FFFEFE;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  
  hr {
    margin: 1rem 0;
    color: #EEEEEE;
    background-color: currentColor;
    border: 0;
    opacity: 1;
  }
  
  hr:not([size]) {
    height: 1px;
  }
  
  h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-family: "Source Sans Pro", "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-weight: 700;
    line-height: 1.2;
    color: #212121;
  }
  
  h1, .h1 {
    font-size: calc(1.37383rem + 1.48598vw);
  }
  
  @media (min-width: 1200px) {
    h1, .h1 {
      font-size: 2.48832rem;
    }
  }
  
  h2, .h2 {
    font-size: calc(1.33236rem + 0.98832vw);
  }
  
  @media (min-width: 1200px) {
    h2, .h2 {
      font-size: 2.0736rem;
    }
  }
  
  h3, .h3 {
    font-size: calc(1.2978rem + 0.5736vw);
  }
  
  @media (min-width: 1200px) {
    h3, .h3 {
      font-size: 1.728rem;
    }
  }
  
  h4, .h4 {
    font-size: calc(1.269rem + 0.228vw);
  }
  
  @media (min-width: 1200px) {
    h4, .h4 {
      font-size: 1.44rem;
    }
  }
  
  h5, .h5 {
    font-size: 1.2rem;
  }
  
  h6, .h6 {
    font-size: 0.83333rem;
  }
  
  p {
    margin-top: 0;
    margin-bottom: 1rem;
  }
  
  abbr[title],
  abbr[data-bs-original-title] {
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted;
    cursor: help;
    -webkit-text-decoration-skip-ink: none;
    text-decoration-skip-ink: none;
  }
  
  address {
    margin-bottom: 1rem;
    font-style: normal;
    line-height: inherit;
  }
  
  ol,
  ul {
    padding-left: 2rem;
  }
  
  ol,
  ul,
  dl {
    margin-top: 0;
    margin-bottom: 1rem;
  }
  
  ol ol,
  ul ul,
  ol ul,
  ul ol {
    margin-bottom: 0;
  }
  
  dt {
    font-weight: 700;
  }
  
  dd {
    margin-bottom: .5rem;
    margin-left: 0;
  }
  
  blockquote {
    margin: 0 0 1rem;
  }
  
  b,
  strong {
    font-weight: 800;
  }
  
  small, .small {
    font-size: 75%;
  }
  
  mark, .mark {
    padding: 0.2em;
    background-color: #fcf8e3;
  }
  
  sub,
  sup {
    position: relative;
    font-size: 0.75em;
    line-height: 0;
    vertical-align: baseline;
  }
  
  sub {
    bottom: -.25em;
  }
  
  sup {
    top: -.5em;
  }
  
  a {
    color: #212121;
    text-decoration: none;
  }
  
  a:hover {
    color: #1a1a1a;
    text-decoration: none;
  }
  
  a:not([href]):not([class]), a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none;
  }
  
  pre,
  code,
  kbd,
  samp {
    font-family: var(--bs-font-monospace);
    font-size: 1em;
    direction: ltr /* rtl:ignore */;
    unicode-bidi: bidi-override;
  }
  
  pre {
    display: block;
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto;
    font-size: 75%;
  }
  
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal;
  }
  
  code {
    font-size: 75%;
    color: #D42DA3;
    word-wrap: break-word;
  }
  
  a > code {
    color: inherit;
  }
  
  kbd {
    padding: 0.2rem 0.4rem;
    font-size: 75%;
    color: #FFFEFE;
    background-color: #424242;
    border-radius: 0.25rem;
  }
  
  kbd kbd {
    padding: 0;
    font-size: 1em;
    font-weight: 700;
  }
  
  figure {
    margin: 0 0 1rem;
  }
  
  img,
  svg {
    vertical-align: middle;
  }
  
  table {
    caption-side: bottom;
    border-collapse: collapse;
  }
  
  caption {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    color: #BDBDBD;
    text-align: left;
  }
  
  th {
    text-align: inherit;
    text-align: -webkit-match-parent;
  }
  
  thead,
  tbody,
  tfoot,
  tr,
  td,
  th {
    border-color: inherit;
    border-style: solid;
    border-width: 0;
  }
  
  label {
    display: inline-block;
  }
  
  button {
    border-radius: 0;
  }
  
  button:focus:not(:focus-visible) {
    outline: 0;
  }
  
  input,
  button,
  select,
  optgroup,
  textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
  }
  
  button,
  select {
    text-transform: none;
  }
  
  [role="button"] {
    cursor: pointer;
  }
  
  select {
    word-wrap: normal;
  }
  
  select:disabled {
    opacity: 1;
  }
  
  [list]::-webkit-calendar-picker-indicator {
    display: none;
  }
  
  button,
  [type="button"],
  [type="reset"],
  [type="submit"] {
    -webkit-appearance: button;
  }
  
  button:not(:disabled),
  [type="button"]:not(:disabled),
  [type="reset"]:not(:disabled),
  [type="submit"]:not(:disabled) {
    cursor: pointer;
  }
  
  ::-moz-focus-inner {
    padding: 0;
    border-style: none;
  }
  
  textarea {
    resize: vertical;
  }
  
  fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0;
  }
  
  legend {
    float: left;
    width: 100%;
    padding: 0;
    margin-bottom: 0.5rem;
    font-size: calc(1.275rem + 0.3vw);
    line-height: inherit;
  }
  
  @media (min-width: 1200px) {
    legend {
      font-size: 1.5rem;
    }
  }
  
  legend + * {
    clear: left;
  }
  
  ::-webkit-datetime-edit-fields-wrapper,
  ::-webkit-datetime-edit-text,
  ::-webkit-datetime-edit-minute,
  ::-webkit-datetime-edit-hour-field,
  ::-webkit-datetime-edit-day-field,
  ::-webkit-datetime-edit-month-field,
  ::-webkit-datetime-edit-year-field {
    padding: 0;
  }
  
  ::-webkit-inner-spin-button {
    height: auto;
  }
  
  [type="search"] {
    outline-offset: -2px;
    -webkit-appearance: textfield;
  }
  
  /* rtl:raw:
  [type="tel"],
  [type="url"],
  [type="email"],
  [type="number"] {
    direction: ltr;
  }
  */
  ::-webkit-search-decoration {
    -webkit-appearance: none;
  }
  
  ::-webkit-color-swatch-wrapper {
    padding: 0;
  }
  
  ::file-selector-button {
    font: inherit;
  }
  
  ::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button;
  }
  
  output {
    display: inline-block;
  }
  
  iframe {
    border: 0;
  }
  
  summary {
    display: list-item;
    cursor: pointer;
  }
  
  progress {
    vertical-align: baseline;
  }
  
  [hidden] {
    display: none !important;
  }
  
  .lead {
    font-size: 1.2rem;
    font-weight: 400;
  }
  
  .display-1 {
    font-size: calc(1.55498rem + 3.65978vw);
    font-weight: 900;
    line-height: 1;
  }
  
  @media (min-width: 1200px) {
    .display-1 {
      font-size: 4.29982rem;
    }
  }
  
  .display-2 {
    font-size: calc(1.48332rem + 2.79982vw);
    font-weight: 900;
    line-height: 1;
  }
  
  @media (min-width: 1200px) {
    .display-2 {
      font-size: 3.58318rem;
    }
  }
  
  .display-3 {
    font-size: calc(1.4236rem + 2.08318vw);
    font-weight: 900;
    line-height: 1;
  }
  
  @media (min-width: 1200px) {
    .display-3 {
      font-size: 2.98598rem;
    }
  }
  
  .display-4 {
    font-size: calc(1.37383rem + 1.48598vw);
    font-weight: 900;
    line-height: 1;
  }
  
  @media (min-width: 1200px) {
    .display-4 {
      font-size: 2.48832rem;
    }
  }
  
  .display-5 {
    font-size: calc(1.33236rem + 0.98832vw);
    font-weight: 900;
    line-height: 1;
  }
  
  @media (min-width: 1200px) {
    .display-5 {
      font-size: 2.0736rem;
    }
  }
  
  .display-6 {
    font-size: calc(1.2978rem + 0.5736vw);
    font-weight: 900;
    line-height: 1;
  }
  
  @media (min-width: 1200px) {
    .display-6 {
      font-size: 1.728rem;
    }
  }
  
  .list-unstyled {
    padding-left: 0;
    list-style: none;
  }
  
  .list-inline {
    padding-left: 0;
    list-style: none;
  }
  
  .list-inline-item {
    display: inline-block;
  }
  
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem;
  }
  
  .initialism {
    font-size: 75%;
    text-transform: uppercase;
  }
  
  .blockquote {
    margin-bottom: 1rem;
    font-size: 1.2rem;
  }
  
  .blockquote > :last-child {
    margin-bottom: 0;
  }
  
  .blockquote-footer {
    margin-top: -1rem;
    margin-bottom: 1rem;
    font-size: 75%;
    color: #9E9E9E;
  }
  
  .blockquote-footer::before {
    content: "\2014\00A0";
  }
  
  .img-fluid {
    max-width: 100%;
    height: auto;
  }
  
  .img-thumbnail {
    padding: 0.25rem;
    background-color: #FFFEFE;
    border: 1px solid #EEEEEE;
    border-radius: 0.5rem;
    max-width: 100%;
    height: auto;
  }
  
  .figure {
    display: inline-block;
  }
  
  .figure-img {
    margin-bottom: 0.5rem;
    line-height: 1;
  }
  
  .figure-caption {
    font-size: 75%;
    color: #9E9E9E;
  }
  
  .container,
  .container-fluid,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl,
  .container-xxl {
    width: 100%;
    padding-right: var(--bs-gutter-x, 1rem);
    padding-left: var(--bs-gutter-x, 1rem);
    margin-right: auto;
    margin-left: auto;
  }
  
  @media (min-width: 576px) {
    .container, .container-sm {
      max-width: 540px;
    }
  }
  
  @media (min-width: 768px) {
    .container, .container-sm, .container-md {
      max-width: 720px;
    }
  }
  
  @media (min-width: 992px) {
    .container, .container-sm, .container-md, .container-lg {
      max-width: 960px;
    }
  }
  
  @media (min-width: 1200px) {
    .container, .container-sm, .container-md, .container-lg, .container-xl {
      max-width: 1140px;
    }
  }
  
  @media (min-width: 1540px) {
    .container, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
      max-width: 1480px;
    }
  }
  
  .row {
    --bs-gutter-x: 2rem;
    --bs-gutter-y: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-top: calc(var(--bs-gutter-y) * -1);
    margin-right: calc(var(--bs-gutter-x) / -2);
    margin-left: calc(var(--bs-gutter-x) / -2);
  }
  
  .row > * {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) / 2);
    padding-left: calc(var(--bs-gutter-x) / 2);
    margin-top: var(--bs-gutter-y);
  }
  
  .col {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
  }
  
  .row-cols-auto > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
  }
  
  .row-cols-1 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 100%;
  }
  
  .row-cols-2 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 50%;
  }
  
  .row-cols-3 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 33.33333%;
  }
  
  .row-cols-4 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 25%;
  }
  
  .row-cols-5 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 20%;
  }
  
  .row-cols-6 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 16.66667%;
  }
  
  .col-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
  }
  
  .col-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 8.33333%;
  }
  
  .col-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 16.66667%;
  }
  
  .col-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 25%;
  }
  
  .col-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 33.33333%;
  }
  
  .col-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 41.66667%;
  }
  
  .col-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 50%;
  }
  
  .col-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 58.33333%;
  }
  
  .col-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 66.66667%;
  }
  
  .col-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 75%;
  }
  
  .col-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 83.33333%;
  }
  
  .col-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 91.66667%;
  }
  
  .col-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 100%;
  }
  
  .offset-1 {
    margin-left: 8.33333%;
  }
  
  .offset-2 {
    margin-left: 16.66667%;
  }
  
  .offset-3 {
    margin-left: 25%;
  }
  
  .offset-4 {
    margin-left: 33.33333%;
  }
  
  .offset-5 {
    margin-left: 41.66667%;
  }
  
  .offset-6 {
    margin-left: 50%;
  }
  
  .offset-7 {
    margin-left: 58.33333%;
  }
  
  .offset-8 {
    margin-left: 66.66667%;
  }
  
  .offset-9 {
    margin-left: 75%;
  }
  
  .offset-10 {
    margin-left: 83.33333%;
  }
  
  .offset-11 {
    margin-left: 91.66667%;
  }
  
  .g-0,
  .gx-0 {
    --bs-gutter-x: 0;
  }
  
  .g-0,
  .gy-0 {
    --bs-gutter-y: 0;
  }
  
  .g-1,
  .gx-1 {
    --bs-gutter-x: 0.25rem;
  }
  
  .g-1,
  .gy-1 {
    --bs-gutter-y: 0.25rem;
  }
  
  .g-2,
  .gx-2 {
    --bs-gutter-x: 0.5rem;
  }
  
  .g-2,
  .gy-2 {
    --bs-gutter-y: 0.5rem;
  }
  
  .g-3,
  .gx-3 {
    --bs-gutter-x: 1rem;
  }
  
  .g-3,
  .gy-3 {
    --bs-gutter-y: 1rem;
  }
  
  .g-4,
  .gx-4 {
    --bs-gutter-x: 1.8rem;
  }
  
  .g-4,
  .gy-4 {
    --bs-gutter-y: 1.8rem;
  }
  
  .g-5,
  .gx-5 {
    --bs-gutter-x: 3rem;
  }
  
  .g-5,
  .gy-5 {
    --bs-gutter-y: 3rem;
  }
  
  .g-6,
  .gx-6 {
    --bs-gutter-x: 4rem;
  }
  
  .g-6,
  .gy-6 {
    --bs-gutter-y: 4rem;
  }
  
  .g-7,
  .gx-7 {
    --bs-gutter-x: 5rem;
  }
  
  .g-7,
  .gy-7 {
    --bs-gutter-y: 5rem;
  }
  
  .g-8,
  .gx-8 {
    --bs-gutter-x: 7.5rem;
  }
  
  .g-8,
  .gy-8 {
    --bs-gutter-y: 7.5rem;
  }
  
  .g-9,
  .gx-9 {
    --bs-gutter-x: 10rem;
  }
  
  .g-9,
  .gy-9 {
    --bs-gutter-y: 10rem;
  }
  
  .g-10,
  .gx-10 {
    --bs-gutter-x: 12.5rem;
  }
  
  .g-10,
  .gy-10 {
    --bs-gutter-y: 12.5rem;
  }
  
  .g-11,
  .gx-11 {
    --bs-gutter-x: 15rem;
  }
  
  .g-11,
  .gy-11 {
    --bs-gutter-y: 15rem;
  }
  
  @media (min-width: 576px) {
    .col-sm {
      -webkit-box-flex: 1;
      -ms-flex: 1 0 0%;
      flex: 1 0 0%;
    }
    .row-cols-sm-auto > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: auto;
    }
    .row-cols-sm-1 > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 100%;
    }
    .row-cols-sm-2 > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 50%;
    }
    .row-cols-sm-3 > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 33.33333%;
    }
    .row-cols-sm-4 > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 25%;
    }
    .row-cols-sm-5 > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 20%;
    }
    .row-cols-sm-6 > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 16.66667%;
    }
    .col-sm-auto {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: auto;
    }
    .col-sm-1 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 8.33333%;
    }
    .col-sm-2 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 16.66667%;
    }
    .col-sm-3 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 25%;
    }
    .col-sm-4 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 33.33333%;
    }
    .col-sm-5 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 41.66667%;
    }
    .col-sm-6 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 50%;
    }
    .col-sm-7 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 58.33333%;
    }
    .col-sm-8 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 66.66667%;
    }
    .col-sm-9 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 75%;
    }
    .col-sm-10 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 83.33333%;
    }
    .col-sm-11 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 91.66667%;
    }
    .col-sm-12 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 100%;
    }
    .offset-sm-0 {
      margin-left: 0;
    }
    .offset-sm-1 {
      margin-left: 8.33333%;
    }
    .offset-sm-2 {
      margin-left: 16.66667%;
    }
    .offset-sm-3 {
      margin-left: 25%;
    }
    .offset-sm-4 {
      margin-left: 33.33333%;
    }
    .offset-sm-5 {
      margin-left: 41.66667%;
    }
    .offset-sm-6 {
      margin-left: 50%;
    }
    .offset-sm-7 {
      margin-left: 58.33333%;
    }
    .offset-sm-8 {
      margin-left: 66.66667%;
    }
    .offset-sm-9 {
      margin-left: 75%;
    }
    .offset-sm-10 {
      margin-left: 83.33333%;
    }
    .offset-sm-11 {
      margin-left: 91.66667%;
    }
    .g-sm-0,
    .gx-sm-0 {
      --bs-gutter-x: 0;
    }
    .g-sm-0,
    .gy-sm-0 {
      --bs-gutter-y: 0;
    }
    .g-sm-1,
    .gx-sm-1 {
      --bs-gutter-x: 0.25rem;
    }
    .g-sm-1,
    .gy-sm-1 {
      --bs-gutter-y: 0.25rem;
    }
    .g-sm-2,
    .gx-sm-2 {
      --bs-gutter-x: 0.5rem;
    }
    .g-sm-2,
    .gy-sm-2 {
      --bs-gutter-y: 0.5rem;
    }
    .g-sm-3,
    .gx-sm-3 {
      --bs-gutter-x: 1rem;
    }
    .g-sm-3,
    .gy-sm-3 {
      --bs-gutter-y: 1rem;
    }
    .g-sm-4,
    .gx-sm-4 {
      --bs-gutter-x: 1.8rem;
    }
    .g-sm-4,
    .gy-sm-4 {
      --bs-gutter-y: 1.8rem;
    }
    .g-sm-5,
    .gx-sm-5 {
      --bs-gutter-x: 3rem;
    }
    .g-sm-5,
    .gy-sm-5 {
      --bs-gutter-y: 3rem;
    }
    .g-sm-6,
    .gx-sm-6 {
      --bs-gutter-x: 4rem;
    }
    .g-sm-6,
    .gy-sm-6 {
      --bs-gutter-y: 4rem;
    }
    .g-sm-7,
    .gx-sm-7 {
      --bs-gutter-x: 5rem;
    }
    .g-sm-7,
    .gy-sm-7 {
      --bs-gutter-y: 5rem;
    }
    .g-sm-8,
    .gx-sm-8 {
      --bs-gutter-x: 7.5rem;
    }
    .g-sm-8,
    .gy-sm-8 {
      --bs-gutter-y: 7.5rem;
    }
    .g-sm-9,
    .gx-sm-9 {
      --bs-gutter-x: 10rem;
    }
    .g-sm-9,
    .gy-sm-9 {
      --bs-gutter-y: 10rem;
    }
    .g-sm-10,
    .gx-sm-10 {
      --bs-gutter-x: 12.5rem;
    }
    .g-sm-10,
    .gy-sm-10 {
      --bs-gutter-y: 12.5rem;
    }
    .g-sm-11,
    .gx-sm-11 {
      --bs-gutter-x: 15rem;
    }
    .g-sm-11,
    .gy-sm-11 {
      --bs-gutter-y: 15rem;
    }
  }
  
  @media (min-width: 768px) {
    .col-md {
      -webkit-box-flex: 1;
      -ms-flex: 1 0 0%;
      flex: 1 0 0%;
    }
    .row-cols-md-auto > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: auto;
    }
    .row-cols-md-1 > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 100%;
    }
    .row-cols-md-2 > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 50%;
    }
    .row-cols-md-3 > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 33.33333%;
    }
    .row-cols-md-4 > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 25%;
    }
    .row-cols-md-5 > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 20%;
    }
    .row-cols-md-6 > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 16.66667%;
    }
    .col-md-auto {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: auto;
    }
    .col-md-1 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 8.33333%;
    }
    .col-md-2 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 16.66667%;
    }
    .col-md-3 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 25%;
    }
    .col-md-4 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 33.33333%;
    }
    .col-md-5 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 41.66667%;
    }
    .col-md-6 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 50%;
    }
    .col-md-7 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 58.33333%;
    }
    .col-md-8 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 66.66667%;
    }
    .col-md-9 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 75%;
    }
    .col-md-10 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 83.33333%;
    }
    .col-md-11 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 91.66667%;
    }
    .col-md-12 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 100%;
    }
    .offset-md-0 {
      margin-left: 0;
    }
    .offset-md-1 {
      margin-left: 8.33333%;
    }
    .offset-md-2 {
      margin-left: 16.66667%;
    }
    .offset-md-3 {
      margin-left: 25%;
    }
    .offset-md-4 {
      margin-left: 33.33333%;
    }
    .offset-md-5 {
      margin-left: 41.66667%;
    }
    .offset-md-6 {
      margin-left: 50%;
    }
    .offset-md-7 {
      margin-left: 58.33333%;
    }
    .offset-md-8 {
      margin-left: 66.66667%;
    }
    .offset-md-9 {
      margin-left: 75%;
    }
    .offset-md-10 {
      margin-left: 83.33333%;
    }
    .offset-md-11 {
      margin-left: 91.66667%;
    }
    .g-md-0,
    .gx-md-0 {
      --bs-gutter-x: 0;
    }
    .g-md-0,
    .gy-md-0 {
      --bs-gutter-y: 0;
    }
    .g-md-1,
    .gx-md-1 {
      --bs-gutter-x: 0.25rem;
    }
    .g-md-1,
    .gy-md-1 {
      --bs-gutter-y: 0.25rem;
    }
    .g-md-2,
    .gx-md-2 {
      --bs-gutter-x: 0.5rem;
    }
    .g-md-2,
    .gy-md-2 {
      --bs-gutter-y: 0.5rem;
    }
    .g-md-3,
    .gx-md-3 {
      --bs-gutter-x: 1rem;
    }
    .g-md-3,
    .gy-md-3 {
      --bs-gutter-y: 1rem;
    }
    .g-md-4,
    .gx-md-4 {
      --bs-gutter-x: 1.8rem;
    }
    .g-md-4,
    .gy-md-4 {
      --bs-gutter-y: 1.8rem;
    }
    .g-md-5,
    .gx-md-5 {
      --bs-gutter-x: 3rem;
    }
    .g-md-5,
    .gy-md-5 {
      --bs-gutter-y: 3rem;
    }
    .g-md-6,
    .gx-md-6 {
      --bs-gutter-x: 4rem;
    }
    .g-md-6,
    .gy-md-6 {
      --bs-gutter-y: 4rem;
    }
    .g-md-7,
    .gx-md-7 {
      --bs-gutter-x: 5rem;
    }
    .g-md-7,
    .gy-md-7 {
      --bs-gutter-y: 5rem;
    }
    .g-md-8,
    .gx-md-8 {
      --bs-gutter-x: 7.5rem;
    }
    .g-md-8,
    .gy-md-8 {
      --bs-gutter-y: 7.5rem;
    }
    .g-md-9,
    .gx-md-9 {
      --bs-gutter-x: 10rem;
    }
    .g-md-9,
    .gy-md-9 {
      --bs-gutter-y: 10rem;
    }
    .g-md-10,
    .gx-md-10 {
      --bs-gutter-x: 12.5rem;
    }
    .g-md-10,
    .gy-md-10 {
      --bs-gutter-y: 12.5rem;
    }
    .g-md-11,
    .gx-md-11 {
      --bs-gutter-x: 15rem;
    }
    .g-md-11,
    .gy-md-11 {
      --bs-gutter-y: 15rem;
    }
  }
  
  @media (min-width: 992px) {
    .col-lg {
      -webkit-box-flex: 1;
      -ms-flex: 1 0 0%;
      flex: 1 0 0%;
    }
    .row-cols-lg-auto > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: auto;
    }
    .row-cols-lg-1 > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 100%;
    }
    .row-cols-lg-2 > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 50%;
    }
    .row-cols-lg-3 > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 33.33333%;
    }
    .row-cols-lg-4 > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 25%;
    }
    .row-cols-lg-5 > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 20%;
    }
    .row-cols-lg-6 > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 16.66667%;
    }
    .col-lg-auto {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: auto;
    }
    .col-lg-1 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 8.33333%;
    }
    .col-lg-2 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 16.66667%;
    }
    .col-lg-3 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 25%;
    }
    .col-lg-4 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 33.33333%;
    }
    .col-lg-5 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 41.66667%;
    }
    .col-lg-6 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 50%;
    }
    .col-lg-7 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 58.33333%;
    }
    .col-lg-8 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 66.66667%;
    }
    .col-lg-9 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 75%;
    }
    .col-lg-10 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 83.33333%;
    }
    .col-lg-11 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 91.66667%;
    }
    .col-lg-12 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 100%;
    }
    .offset-lg-0 {
      margin-left: 0;
    }
    .offset-lg-1 {
      margin-left: 8.33333%;
    }
    .offset-lg-2 {
      margin-left: 16.66667%;
    }
    .offset-lg-3 {
      margin-left: 25%;
    }
    .offset-lg-4 {
      margin-left: 33.33333%;
    }
    .offset-lg-5 {
      margin-left: 41.66667%;
    }
    .offset-lg-6 {
      margin-left: 50%;
    }
    .offset-lg-7 {
      margin-left: 58.33333%;
    }
    .offset-lg-8 {
      margin-left: 66.66667%;
    }
    .offset-lg-9 {
      margin-left: 75%;
    }
    .offset-lg-10 {
      margin-left: 83.33333%;
    }
    .offset-lg-11 {
      margin-left: 91.66667%;
    }
    .g-lg-0,
    .gx-lg-0 {
      --bs-gutter-x: 0;
    }
    .g-lg-0,
    .gy-lg-0 {
      --bs-gutter-y: 0;
    }
    .g-lg-1,
    .gx-lg-1 {
      --bs-gutter-x: 0.25rem;
    }
    .g-lg-1,
    .gy-lg-1 {
      --bs-gutter-y: 0.25rem;
    }
    .g-lg-2,
    .gx-lg-2 {
      --bs-gutter-x: 0.5rem;
    }
    .g-lg-2,
    .gy-lg-2 {
      --bs-gutter-y: 0.5rem;
    }
    .g-lg-3,
    .gx-lg-3 {
      --bs-gutter-x: 1rem;
    }
    .g-lg-3,
    .gy-lg-3 {
      --bs-gutter-y: 1rem;
    }
    .g-lg-4,
    .gx-lg-4 {
      --bs-gutter-x: 1.8rem;
    }
    .g-lg-4,
    .gy-lg-4 {
      --bs-gutter-y: 1.8rem;
    }
    .g-lg-5,
    .gx-lg-5 {
      --bs-gutter-x: 3rem;
    }
    .g-lg-5,
    .gy-lg-5 {
      --bs-gutter-y: 3rem;
    }
    .g-lg-6,
    .gx-lg-6 {
      --bs-gutter-x: 4rem;
    }
    .g-lg-6,
    .gy-lg-6 {
      --bs-gutter-y: 4rem;
    }
    .g-lg-7,
    .gx-lg-7 {
      --bs-gutter-x: 5rem;
    }
    .g-lg-7,
    .gy-lg-7 {
      --bs-gutter-y: 5rem;
    }
    .g-lg-8,
    .gx-lg-8 {
      --bs-gutter-x: 7.5rem;
    }
    .g-lg-8,
    .gy-lg-8 {
      --bs-gutter-y: 7.5rem;
    }
    .g-lg-9,
    .gx-lg-9 {
      --bs-gutter-x: 10rem;
    }
    .g-lg-9,
    .gy-lg-9 {
      --bs-gutter-y: 10rem;
    }
    .g-lg-10,
    .gx-lg-10 {
      --bs-gutter-x: 12.5rem;
    }
    .g-lg-10,
    .gy-lg-10 {
      --bs-gutter-y: 12.5rem;
    }
    .g-lg-11,
    .gx-lg-11 {
      --bs-gutter-x: 15rem;
    }
    .g-lg-11,
    .gy-lg-11 {
      --bs-gutter-y: 15rem;
    }
  }
  
  @media (min-width: 1200px) {
    .col-xl {
      -webkit-box-flex: 1;
      -ms-flex: 1 0 0%;
      flex: 1 0 0%;
    }
    .row-cols-xl-auto > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: auto;
    }
    .row-cols-xl-1 > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 100%;
    }
    .row-cols-xl-2 > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 50%;
    }
    .row-cols-xl-3 > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 33.33333%;
    }
    .row-cols-xl-4 > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 25%;
    }
    .row-cols-xl-5 > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 20%;
    }
    .row-cols-xl-6 > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 16.66667%;
    }
    .col-xl-auto {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: auto;
    }
    .col-xl-1 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 8.33333%;
    }
    .col-xl-2 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 16.66667%;
    }
    .col-xl-3 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 25%;
    }
    .col-xl-4 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 33.33333%;
    }
    .col-xl-5 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 41.66667%;
    }
    .col-xl-6 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 50%;
    }
    .col-xl-7 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 58.33333%;
    }
    .col-xl-8 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 66.66667%;
    }
    .col-xl-9 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 75%;
    }
    .col-xl-10 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 83.33333%;
    }
    .col-xl-11 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 91.66667%;
    }
    .col-xl-12 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 100%;
    }
    .offset-xl-0 {
      margin-left: 0;
    }
    .offset-xl-1 {
      margin-left: 8.33333%;
    }
    .offset-xl-2 {
      margin-left: 16.66667%;
    }
    .offset-xl-3 {
      margin-left: 25%;
    }
    .offset-xl-4 {
      margin-left: 33.33333%;
    }
    .offset-xl-5 {
      margin-left: 41.66667%;
    }
    .offset-xl-6 {
      margin-left: 50%;
    }
    .offset-xl-7 {
      margin-left: 58.33333%;
    }
    .offset-xl-8 {
      margin-left: 66.66667%;
    }
    .offset-xl-9 {
      margin-left: 75%;
    }
    .offset-xl-10 {
      margin-left: 83.33333%;
    }
    .offset-xl-11 {
      margin-left: 91.66667%;
    }
    .g-xl-0,
    .gx-xl-0 {
      --bs-gutter-x: 0;
    }
    .g-xl-0,
    .gy-xl-0 {
      --bs-gutter-y: 0;
    }
    .g-xl-1,
    .gx-xl-1 {
      --bs-gutter-x: 0.25rem;
    }
    .g-xl-1,
    .gy-xl-1 {
      --bs-gutter-y: 0.25rem;
    }
    .g-xl-2,
    .gx-xl-2 {
      --bs-gutter-x: 0.5rem;
    }
    .g-xl-2,
    .gy-xl-2 {
      --bs-gutter-y: 0.5rem;
    }
    .g-xl-3,
    .gx-xl-3 {
      --bs-gutter-x: 1rem;
    }
    .g-xl-3,
    .gy-xl-3 {
      --bs-gutter-y: 1rem;
    }
    .g-xl-4,
    .gx-xl-4 {
      --bs-gutter-x: 1.8rem;
    }
    .g-xl-4,
    .gy-xl-4 {
      --bs-gutter-y: 1.8rem;
    }
    .g-xl-5,
    .gx-xl-5 {
      --bs-gutter-x: 3rem;
    }
    .g-xl-5,
    .gy-xl-5 {
      --bs-gutter-y: 3rem;
    }
    .g-xl-6,
    .gx-xl-6 {
      --bs-gutter-x: 4rem;
    }
    .g-xl-6,
    .gy-xl-6 {
      --bs-gutter-y: 4rem;
    }
    .g-xl-7,
    .gx-xl-7 {
      --bs-gutter-x: 5rem;
    }
    .g-xl-7,
    .gy-xl-7 {
      --bs-gutter-y: 5rem;
    }
    .g-xl-8,
    .gx-xl-8 {
      --bs-gutter-x: 7.5rem;
    }
    .g-xl-8,
    .gy-xl-8 {
      --bs-gutter-y: 7.5rem;
    }
    .g-xl-9,
    .gx-xl-9 {
      --bs-gutter-x: 10rem;
    }
    .g-xl-9,
    .gy-xl-9 {
      --bs-gutter-y: 10rem;
    }
    .g-xl-10,
    .gx-xl-10 {
      --bs-gutter-x: 12.5rem;
    }
    .g-xl-10,
    .gy-xl-10 {
      --bs-gutter-y: 12.5rem;
    }
    .g-xl-11,
    .gx-xl-11 {
      --bs-gutter-x: 15rem;
    }
    .g-xl-11,
    .gy-xl-11 {
      --bs-gutter-y: 15rem;
    }
  }
  
  @media (min-width: 1540px) {
    .col-xxl {
      -webkit-box-flex: 1;
      -ms-flex: 1 0 0%;
      flex: 1 0 0%;
    }
    .row-cols-xxl-auto > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: auto;
    }
    .row-cols-xxl-1 > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 100%;
    }
    .row-cols-xxl-2 > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 50%;
    }
    .row-cols-xxl-3 > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 33.33333%;
    }
    .row-cols-xxl-4 > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 25%;
    }
    .row-cols-xxl-5 > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 20%;
    }
    .row-cols-xxl-6 > * {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 16.66667%;
    }
    .col-xxl-auto {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: auto;
    }
    .col-xxl-1 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 8.33333%;
    }
    .col-xxl-2 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 16.66667%;
    }
    .col-xxl-3 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 25%;
    }
    .col-xxl-4 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 33.33333%;
    }
    .col-xxl-5 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 41.66667%;
    }
    .col-xxl-6 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 50%;
    }
    .col-xxl-7 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 58.33333%;
    }
    .col-xxl-8 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 66.66667%;
    }
    .col-xxl-9 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 75%;
    }
    .col-xxl-10 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 83.33333%;
    }
    .col-xxl-11 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 91.66667%;
    }
    .col-xxl-12 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 100%;
    }
    .offset-xxl-0 {
      margin-left: 0;
    }
    .offset-xxl-1 {
      margin-left: 8.33333%;
    }
    .offset-xxl-2 {
      margin-left: 16.66667%;
    }
    .offset-xxl-3 {
      margin-left: 25%;
    }
    .offset-xxl-4 {
      margin-left: 33.33333%;
    }
    .offset-xxl-5 {
      margin-left: 41.66667%;
    }
    .offset-xxl-6 {
      margin-left: 50%;
    }
    .offset-xxl-7 {
      margin-left: 58.33333%;
    }
    .offset-xxl-8 {
      margin-left: 66.66667%;
    }
    .offset-xxl-9 {
      margin-left: 75%;
    }
    .offset-xxl-10 {
      margin-left: 83.33333%;
    }
    .offset-xxl-11 {
      margin-left: 91.66667%;
    }
    .g-xxl-0,
    .gx-xxl-0 {
      --bs-gutter-x: 0;
    }
    .g-xxl-0,
    .gy-xxl-0 {
      --bs-gutter-y: 0;
    }
    .g-xxl-1,
    .gx-xxl-1 {
      --bs-gutter-x: 0.25rem;
    }
    .g-xxl-1,
    .gy-xxl-1 {
      --bs-gutter-y: 0.25rem;
    }
    .g-xxl-2,
    .gx-xxl-2 {
      --bs-gutter-x: 0.5rem;
    }
    .g-xxl-2,
    .gy-xxl-2 {
      --bs-gutter-y: 0.5rem;
    }
    .g-xxl-3,
    .gx-xxl-3 {
      --bs-gutter-x: 1rem;
    }
    .g-xxl-3,
    .gy-xxl-3 {
      --bs-gutter-y: 1rem;
    }
    .g-xxl-4,
    .gx-xxl-4 {
      --bs-gutter-x: 1.8rem;
    }
    .g-xxl-4,
    .gy-xxl-4 {
      --bs-gutter-y: 1.8rem;
    }
    .g-xxl-5,
    .gx-xxl-5 {
      --bs-gutter-x: 3rem;
    }
    .g-xxl-5,
    .gy-xxl-5 {
      --bs-gutter-y: 3rem;
    }
    .g-xxl-6,
    .gx-xxl-6 {
      --bs-gutter-x: 4rem;
    }
    .g-xxl-6,
    .gy-xxl-6 {
      --bs-gutter-y: 4rem;
    }
    .g-xxl-7,
    .gx-xxl-7 {
      --bs-gutter-x: 5rem;
    }
    .g-xxl-7,
    .gy-xxl-7 {
      --bs-gutter-y: 5rem;
    }
    .g-xxl-8,
    .gx-xxl-8 {
      --bs-gutter-x: 7.5rem;
    }
    .g-xxl-8,
    .gy-xxl-8 {
      --bs-gutter-y: 7.5rem;
    }
    .g-xxl-9,
    .gx-xxl-9 {
      --bs-gutter-x: 10rem;
    }
    .g-xxl-9,
    .gy-xxl-9 {
      --bs-gutter-y: 10rem;
    }
    .g-xxl-10,
    .gx-xxl-10 {
      --bs-gutter-x: 12.5rem;
    }
    .g-xxl-10,
    .gy-xxl-10 {
      --bs-gutter-y: 12.5rem;
    }
    .g-xxl-11,
    .gx-xxl-11 {
      --bs-gutter-x: 15rem;
    }
    .g-xxl-11,
    .gy-xxl-11 {
      --bs-gutter-y: 15rem;
    }
  }
  
  .table {
    --bs-table-bg: transparent;
    --bs-table-accent-bg: transparent;
    --bs-table-striped-color: #424242;
    --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
    --bs-table-active-color: #424242;
    --bs-table-active-bg: rgba(0, 0, 0, 0.1);
    --bs-table-hover-color: #424242;
    --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
    width: 100%;
    margin-bottom: 1rem;
    color: #424242;
    vertical-align: top;
    border-color: #EEEEEE;
  }
  
  .table > :not(caption) > * > * {
    padding: 0.5rem 0.5rem;
    background-color: var(--bs-table-bg);
    border-bottom-width: 1px;
    -webkit-box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
    box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
  }
  
  
  .btn {
    display: inline-block;
    font-weight: 700;
    line-height: 1.5;
    color: #424242;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.5rem 2.2rem;
    font-size: 1rem;
    border-radius: 0.2rem;
    -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    -o-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  }
  
  @media (prefers-reduced-motion: reduce) {
    .btn {
      -webkit-transition: none;
      -o-transition: none;
      transition: none;
    }
  }
  
  .btn:hover {
    color: #424242;
  }
  
  .btn-check:focus + .btn, .btn:focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.25rem rgba(255, 179, 14, 0.25);
    box-shadow: 0 0 0 0.25rem rgba(255, 179, 14, 0.25);
  }
  
  .btn:disabled, .btn.disabled,
  fieldset:disabled .btn {
    pointer-events: none;
    opacity: 0.65;
  }
  
  .btn-primary {
    color: #616161;
    background-color: #FFB30E;
    border-color: #FFB30E;
  }
  
  .btn-primary:hover {
    color: #616161;
    background-color: #ffbe32;
    border-color: #ffbb26;
  }
  
  .btn-check:focus + .btn-primary, .btn-primary:focus {
    color: #616161;
    background-color: #ffbe32;
    border-color: #ffbb26;
    -webkit-box-shadow: 0 0 0 0 rgba(231, 167, 26, 0.5);
    box-shadow: 0 0 0 0 rgba(231, 167, 26, 0.5);
  }
  
  .btn-check:checked + .btn-primary,
  .btn-check:active + .btn-primary, .btn-primary:active, .btn-primary.active,
  .show > .btn-primary.dropdown-toggle {
    color: #616161;
    background-color: #ffc23e;
    border-color: #ffbb26;
  }
  
  .btn-check:checked + .btn-primary:focus,
  .btn-check:active + .btn-primary:focus, .btn-primary:active:focus, .btn-primary.active:focus,
  .show > .btn-primary.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(231, 167, 26, 0.5);
    box-shadow: 0 0 0 0 rgba(231, 167, 26, 0.5);
  }
  
  .btn-primary:disabled, .btn-primary.disabled {
    color: #616161;
    background-color: #FFB30E;
    border-color: #FFB30E;
  }
  
  .btn-secondary {
    color: #FFFEFE;
    background-color: #2780ED;
    border-color: #2780ED;
  }
  
  .btn-secondary:hover {
    color: #FFFEFE;
    background-color: #216dc9;
    border-color: #1f66be;
  }
  
  .btn-check:focus + .btn-secondary, .btn-secondary:focus {
    color: #FFFEFE;
    background-color: #216dc9;
    border-color: #1f66be;
    -webkit-box-shadow: 0 0 0 0 rgba(71, 147, 240, 0.5);
    box-shadow: 0 0 0 0 rgba(71, 147, 240, 0.5);
  }
  
  .btn-check:checked + .btn-secondary,
  .btn-check:active + .btn-secondary, .btn-secondary:active, .btn-secondary.active,
  .show > .btn-secondary.dropdown-toggle {
    color: #FFFEFE;
    background-color: #1f66be;
    border-color: #1d60b2;
  }
  
  .btn-check:checked + .btn-secondary:focus,
  .btn-check:active + .btn-secondary:focus, .btn-secondary:active:focus, .btn-secondary.active:focus,
  .show > .btn-secondary.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(71, 147, 240, 0.5);
    box-shadow: 0 0 0 0 rgba(71, 147, 240, 0.5);
  }
  
  .btn-secondary:disabled, .btn-secondary.disabled {
    color: #FFFEFE;
    background-color: #2780ED;
    border-color: #2780ED;
  }
  
  .btn-success {
    color: #FFFEFE;
    background-color: #79B93C;
    border-color: #79B93C;
  }
  
  .btn-success:hover {
    color: #FFFEFE;
    background-color: #679d33;
    border-color: #619430;
  }
  
  .btn-check:focus + .btn-success, .btn-success:focus {
    color: #FFFEFE;
    background-color: #679d33;
    border-color: #619430;
    -webkit-box-shadow: 0 0 0 0 rgba(141, 195, 89, 0.5);
    box-shadow: 0 0 0 0 rgba(141, 195, 89, 0.5);
  }
  
  .btn-check:checked + .btn-success,
  .btn-check:active + .btn-success, .btn-success:active, .btn-success.active,
  .show > .btn-success.dropdown-toggle {
    color: #FFFEFE;
    background-color: #619430;
    border-color: #5b8b2d;
  }
  
  .btn-check:checked + .btn-success:focus,
  .btn-check:active + .btn-success:focus, .btn-success:active:focus, .btn-success.active:focus,
  .show > .btn-success.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(141, 195, 89, 0.5);
    box-shadow: 0 0 0 0 rgba(141, 195, 89, 0.5);
  }
  
  .btn-success:disabled, .btn-success.disabled {
    color: #FFFEFE;
    background-color: #79B93C;
    border-color: #79B93C;
  }
  
  .btn-info {
    color: #616161;
    background-color: #24FFEA;
    border-color: #24FFEA;
  }
  
  .btn-info:hover {
    color: #616161;
    background-color: #45ffed;
    border-color: #3affec;
  }
  
  .btn-check:focus + .btn-info, .btn-info:focus {
    color: #616161;
    background-color: #45ffed;
    border-color: #3affec;
    -webkit-box-shadow: 0 0 0 0 rgba(45, 231, 213, 0.5);
    box-shadow: 0 0 0 0 rgba(45, 231, 213, 0.5);
  }
  
  .btn-check:checked + .btn-info,
  .btn-check:active + .btn-info, .btn-info:active, .btn-info.active,
  .show > .btn-info.dropdown-toggle {
    color: #616161;
    background-color: #50ffee;
    border-color: #3affec;
  }
  
  .btn-check:checked + .btn-info:focus,
  .btn-check:active + .btn-info:focus, .btn-info:active:focus, .btn-info.active:focus,
  .show > .btn-info.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(45, 231, 213, 0.5);
    box-shadow: 0 0 0 0 rgba(45, 231, 213, 0.5);
  }
  
  .btn-info:disabled, .btn-info.disabled {
    color: #616161;
    background-color: #24FFEA;
    border-color: #24FFEA;
  }
  
  .btn-warning {
    color: #616161;
    background-color: #FFB30E;
    border-color: #FFB30E;
  }
  
  .btn-warning:hover {
    color: #616161;
    background-color: #ffbe32;
    border-color: #ffbb26;
  }
  
  .btn-check:focus + .btn-warning, .btn-warning:focus {
    color: #616161;
    background-color: #ffbe32;
    border-color: #ffbb26;
    -webkit-box-shadow: 0 0 0 0 rgba(231, 167, 26, 0.5);
    box-shadow: 0 0 0 0 rgba(231, 167, 26, 0.5);
  }
  
  .btn-check:checked + .btn-warning,
  .btn-check:active + .btn-warning, .btn-warning:active, .btn-warning.active,
  .show > .btn-warning.dropdown-toggle {
    color: #616161;
    background-color: #ffc23e;
    border-color: #ffbb26;
  }
  
  .btn-check:checked + .btn-warning:focus,
  .btn-check:active + .btn-warning:focus, .btn-warning:active:focus, .btn-warning.active:focus,
  .show > .btn-warning.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(231, 167, 26, 0.5);
    box-shadow: 0 0 0 0 rgba(231, 167, 26, 0.5);
  }
  
  .btn-warning:disabled, .btn-warning.disabled {
    color: #616161;
    background-color: #FFB30E;
    border-color: #FFB30E;
  }
  
  .btn-danger {
    color: #FFFEFE;
    background-color: #F17228;
    border-color: #F17228;
  }
  
  .btn-danger:hover {
    color: #FFFEFE;
    background-color: #cd6122;
    border-color: #c15b20;
  }
  
  .btn-check:focus + .btn-danger, .btn-danger:focus {
    color: #FFFEFE;
    background-color: #cd6122;
    border-color: #c15b20;
    -webkit-box-shadow: 0 0 0 0 rgba(243, 135, 72, 0.5);
    box-shadow: 0 0 0 0 rgba(243, 135, 72, 0.5);
  }
  
  .btn-check:checked + .btn-danger,
  .btn-check:active + .btn-danger, .btn-danger:active, .btn-danger.active,
  .show > .btn-danger.dropdown-toggle {
    color: #FFFEFE;
    background-color: #c15b20;
    border-color: #b5561e;
  }
  
  .btn-check:checked + .btn-danger:focus,
  .btn-check:active + .btn-danger:focus, .btn-danger:active:focus, .btn-danger.active:focus,
  .show > .btn-danger.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(243, 135, 72, 0.5);
    box-shadow: 0 0 0 0 rgba(243, 135, 72, 0.5);
  }
  
  .btn-danger:disabled, .btn-danger.disabled {
    color: #FFFEFE;
    background-color: #F17228;
    border-color: #F17228;
  }
  
  .btn-light {
    color: #616161;
    background-color: #F9FAFD;
    border-color: #F9FAFD;
  }
  
  .btn-light:hover {
    color: #616161;
    background-color: #fafbfd;
    border-color: #fafbfd;
  }
  
  .btn-check:focus + .btn-light, .btn-light:focus {
    color: #616161;
    background-color: #fafbfd;
    border-color: #fafbfd;
    -webkit-box-shadow: 0 0 0 0 rgba(226, 227, 230, 0.5);
    box-shadow: 0 0 0 0 rgba(226, 227, 230, 0.5);
  }
  
  .btn-check:checked + .btn-light,
  .btn-check:active + .btn-light, .btn-light:active, .btn-light.active,
  .show > .btn-light.dropdown-toggle {
    color: #616161;
    background-color: #fafbfd;
    border-color: #fafbfd;
  }
  
  .btn-check:checked + .btn-light:focus,
  .btn-check:active + .btn-light:focus, .btn-light:active:focus, .btn-light.active:focus,
  .show > .btn-light.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(226, 227, 230, 0.5);
    box-shadow: 0 0 0 0 rgba(226, 227, 230, 0.5);
  }
  
  .btn-light:disabled, .btn-light.disabled {
    color: #616161;
    background-color: #F9FAFD;
    border-color: #F9FAFD;
  }
  
  .btn-dark {
    color: #FFFEFE;
    background-color: #212121;
    border-color: #212121;
  }
  
  .btn-dark:hover {
    color: #FFFEFE;
    background-color: #1c1c1c;
    border-color: #1a1a1a;
  }
  
  .btn-check:focus + .btn-dark, .btn-dark:focus {
    color: #FFFEFE;
    background-color: #1c1c1c;
    border-color: #1a1a1a;
    -webkit-box-shadow: 0 0 0 0 rgba(66, 66, 66, 0.5);
    box-shadow: 0 0 0 0 rgba(66, 66, 66, 0.5);
  }
  
  .btn-check:checked + .btn-dark,
  .btn-check:active + .btn-dark, .btn-dark:active, .btn-dark.active,
  .show > .btn-dark.dropdown-toggle {
    color: #FFFEFE;
    background-color: #1a1a1a;
    border-color: #191919;
  }
  
  .btn-check:checked + .btn-dark:focus,
  .btn-check:active + .btn-dark:focus, .btn-dark:active:focus, .btn-dark.active:focus,
  .show > .btn-dark.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(66, 66, 66, 0.5);
    box-shadow: 0 0 0 0 rgba(66, 66, 66, 0.5);
  }
  
  .btn-dark:disabled, .btn-dark.disabled {
    color: #FFFEFE;
    background-color: #212121;
    border-color: #212121;
  }
  
  .btn-outline-primary {
    color: #FFB30E;
    border-color: #FFB30E;
  }
  
  .btn-outline-primary:hover {
    color: #616161;
    background-color: #FFB30E;
    border-color: #FFB30E;
  }
  
  .btn-check:focus + .btn-outline-primary, .btn-outline-primary:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 179, 14, 0.5);
    box-shadow: 0 0 0 0 rgba(255, 179, 14, 0.5);
  }
  
  .btn-check:checked + .btn-outline-primary,
  .btn-check:active + .btn-outline-primary, .btn-outline-primary:active, .btn-outline-primary.active, .btn-outline-primary.dropdown-toggle.show {
    color: #616161;
    background-color: #FFB30E;
    border-color: #FFB30E;
  }
  
  .btn-check:checked + .btn-outline-primary:focus,
  .btn-check:active + .btn-outline-primary:focus, .btn-outline-primary:active:focus, .btn-outline-primary.active:focus, .btn-outline-primary.dropdown-toggle.show:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 179, 14, 0.5);
    box-shadow: 0 0 0 0 rgba(255, 179, 14, 0.5);
  }
  
  .btn-outline-primary:disabled, .btn-outline-primary.disabled {
    color: #FFB30E;
    background-color: transparent;
  }
  
  .btn-outline-secondary {
    color: #2780ED;
    border-color: #2780ED;
  }
  
  .btn-outline-secondary:hover {
    color: #FFFEFE;
    background-color: #2780ED;
    border-color: #2780ED;
  }
  
  .btn-check:focus + .btn-outline-secondary, .btn-outline-secondary:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(39, 128, 237, 0.5);
    box-shadow: 0 0 0 0 rgba(39, 128, 237, 0.5);
  }
  
  .btn-check:checked + .btn-outline-secondary,
  .btn-check:active + .btn-outline-secondary, .btn-outline-secondary:active, .btn-outline-secondary.active, .btn-outline-secondary.dropdown-toggle.show {
    color: #FFFEFE;
    background-color: #2780ED;
    border-color: #2780ED;
  }
  
  .btn-check:checked + .btn-outline-secondary:focus,
  .btn-check:active + .btn-outline-secondary:focus, .btn-outline-secondary:active:focus, .btn-outline-secondary.active:focus, .btn-outline-secondary.dropdown-toggle.show:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(39, 128, 237, 0.5);
    box-shadow: 0 0 0 0 rgba(39, 128, 237, 0.5);
  }
  
  .btn-outline-secondary:disabled, .btn-outline-secondary.disabled {
    color: #2780ED;
    background-color: transparent;
  }
  
  .btn-outline-success {
    color: #79B93C;
    border-color: #79B93C;
  }
  
  .btn-outline-success:hover {
    color: #FFFEFE;
    background-color: #79B93C;
    border-color: #79B93C;
  }
  
  .btn-check:focus + .btn-outline-success, .btn-outline-success:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(121, 185, 60, 0.5);
    box-shadow: 0 0 0 0 rgba(121, 185, 60, 0.5);
  }
  
  .btn-check:checked + .btn-outline-success,
  .btn-check:active + .btn-outline-success, .btn-outline-success:active, .btn-outline-success.active, .btn-outline-success.dropdown-toggle.show {
    color: #FFFEFE;
    background-color: #79B93C;
    border-color: #79B93C;
  }
  
  .btn-check:checked + .btn-outline-success:focus,
  .btn-check:active + .btn-outline-success:focus, .btn-outline-success:active:focus, .btn-outline-success.active:focus, .btn-outline-success.dropdown-toggle.show:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(121, 185, 60, 0.5);
    box-shadow: 0 0 0 0 rgba(121, 185, 60, 0.5);
  }
  
  .btn-outline-success:disabled, .btn-outline-success.disabled {
    color: #79B93C;
    background-color: transparent;
  }
  
  .btn-outline-info {
    color: #24FFEA;
    border-color: #24FFEA;
  }
  
  .btn-outline-info:hover {
    color: #616161;
    background-color: #24FFEA;
    border-color: #24FFEA;
  }
  
  .btn-check:focus + .btn-outline-info, .btn-outline-info:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(36, 255, 234, 0.5);
    box-shadow: 0 0 0 0 rgba(36, 255, 234, 0.5);
  }
  
  .btn-check:checked + .btn-outline-info,
  .btn-check:active + .btn-outline-info, .btn-outline-info:active, .btn-outline-info.active, .btn-outline-info.dropdown-toggle.show {
    color: #616161;
    background-color: #24FFEA;
    border-color: #24FFEA;
  }
  
  .btn-check:checked + .btn-outline-info:focus,
  .btn-check:active + .btn-outline-info:focus, .btn-outline-info:active:focus, .btn-outline-info.active:focus, .btn-outline-info.dropdown-toggle.show:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(36, 255, 234, 0.5);
    box-shadow: 0 0 0 0 rgba(36, 255, 234, 0.5);
  }
  
  .btn-outline-info:disabled, .btn-outline-info.disabled {
    color: #24FFEA;
    background-color: transparent;
  }
  
  .btn-outline-warning {
    color: #FFB30E;
    border-color: #FFB30E;
  }
  
  .btn-outline-warning:hover {
    color: #616161;
    background-color: #FFB30E;
    border-color: #FFB30E;
  }
  
  .btn-check:focus + .btn-outline-warning, .btn-outline-warning:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 179, 14, 0.5);
    box-shadow: 0 0 0 0 rgba(255, 179, 14, 0.5);
  }
  
  .btn-check:checked + .btn-outline-warning,
  .btn-check:active + .btn-outline-warning, .btn-outline-warning:active, .btn-outline-warning.active, .btn-outline-warning.dropdown-toggle.show {
    color: #616161;
    background-color: #FFB30E;
    border-color: #FFB30E;
  }
  
  .btn-check:checked + .btn-outline-warning:focus,
  .btn-check:active + .btn-outline-warning:focus, .btn-outline-warning:active:focus, .btn-outline-warning.active:focus, .btn-outline-warning.dropdown-toggle.show:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 179, 14, 0.5);
    box-shadow: 0 0 0 0 rgba(255, 179, 14, 0.5);
  }
  
  .btn-outline-warning:disabled, .btn-outline-warning.disabled {
    color: #FFB30E;
    background-color: transparent;
  }
  
  .btn-outline-danger {
    color: #F17228;
    border-color: #F17228;
  }
  
  .btn-outline-danger:hover {
    color: #FFFEFE;
    background-color: #F17228;
    border-color: #F17228;
  }
  
  .btn-check:focus + .btn-outline-danger, .btn-outline-danger:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(241, 114, 40, 0.5);
    box-shadow: 0 0 0 0 rgba(241, 114, 40, 0.5);
  }
  
  .btn-check:checked + .btn-outline-danger,
  .btn-check:active + .btn-outline-danger, .btn-outline-danger:active, .btn-outline-danger.active, .btn-outline-danger.dropdown-toggle.show {
    color: #FFFEFE;
    background-color: #F17228;
    border-color: #F17228;
  }
  
  .btn-check:checked + .btn-outline-danger:focus,
  .btn-check:active + .btn-outline-danger:focus, .btn-outline-danger:active:focus, .btn-outline-danger.active:focus, .btn-outline-danger.dropdown-toggle.show:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(241, 114, 40, 0.5);
    box-shadow: 0 0 0 0 rgba(241, 114, 40, 0.5);
  }
  
  .btn-outline-danger:disabled, .btn-outline-danger.disabled {
    color: #F17228;
    background-color: transparent;
  }
  
  .btn-outline-light {
    color: #F9FAFD;
    border-color: #F9FAFD;
  }
  
  .btn-outline-light:hover {
    color: #616161;
    background-color: #F9FAFD;
    border-color: #F9FAFD;
  }
  
  .btn-check:focus + .btn-outline-light, .btn-outline-light:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(249, 250, 253, 0.5);
    box-shadow: 0 0 0 0 rgba(249, 250, 253, 0.5);
  }
  
  .btn-check:checked + .btn-outline-light,
  .btn-check:active + .btn-outline-light, .btn-outline-light:active, .btn-outline-light.active, .btn-outline-light.dropdown-toggle.show {
    color: #616161;
    background-color: #F9FAFD;
    border-color: #F9FAFD;
  }
  
  .btn-check:checked + .btn-outline-light:focus,
  .btn-check:active + .btn-outline-light:focus, .btn-outline-light:active:focus, .btn-outline-light.active:focus, .btn-outline-light.dropdown-toggle.show:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(249, 250, 253, 0.5);
    box-shadow: 0 0 0 0 rgba(249, 250, 253, 0.5);
  }
  
  .btn-outline-light:disabled, .btn-outline-light.disabled {
    color: #F9FAFD;
    background-color: transparent;
  }
  
  .btn-outline-dark {
    color: #212121;
    border-color: #212121;
  }
  
  .btn-outline-dark:hover {
    color: #FFFEFE;
    background-color: #212121;
    border-color: #212121;
  }
  
  .btn-check:focus + .btn-outline-dark, .btn-outline-dark:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(33, 33, 33, 0.5);
    box-shadow: 0 0 0 0 rgba(33, 33, 33, 0.5);
  }
  
  .btn-check:checked + .btn-outline-dark,
  .btn-check:active + .btn-outline-dark, .btn-outline-dark:active, .btn-outline-dark.active, .btn-outline-dark.dropdown-toggle.show {
    color: #FFFEFE;
    background-color: #212121;
    border-color: #212121;
  }
  
  .btn-check:checked + .btn-outline-dark:focus,
  .btn-check:active + .btn-outline-dark:focus, .btn-outline-dark:active:focus, .btn-outline-dark.active:focus, .btn-outline-dark.dropdown-toggle.show:focus {
    -webkit-box-shadow: 0 0 0 0 rgba(33, 33, 33, 0.5);
    box-shadow: 0 0 0 0 rgba(33, 33, 33, 0.5);
  }
  
  .btn-outline-dark:disabled, .btn-outline-dark.disabled {
    color: #212121;
    background-color: transparent;
  }
  
  .btn-link {
    font-weight: 400;
    color: #212121;
    text-decoration: none;
  }
  
  .btn-link:hover {
    color: #1a1a1a;
    text-decoration: none;
  }
  
  .btn-link:focus {
    text-decoration: none;
  }
  
  .btn-link:disabled, .btn-link.disabled {
    color: #9E9E9E;
  }
  
  .btn-lg, .btn-group-lg > .btn {
    padding: 0.5rem 2.8rem;
    font-size: 1rem;
    border-radius: 0.35rem;
  }
  
  .btn-sm, .btn-group-sm > .btn {
    padding: 0.5rem 1.3rem;
    font-size: 0.875rem;
    border-radius: 0.25rem;
  }
  
  .fade {
    -webkit-transition: opacity 0.15s linear;
    -o-transition: opacity 0.15s linear;
    transition: opacity 0.15s linear;
  }
  
  @media (prefers-reduced-motion: reduce) {
    .fade {
      -webkit-transition: none;
      -o-transition: none;
      transition: none;
    }
  }
  
  .fade:not(.show) {
    opacity: 0;
  }
  
  .collapse:not(.show) {
    display: none;
  }
  
  .collapsing {
    height: 0;
    overflow: hidden;
    -webkit-transition: height 0.35s ease;
    -o-transition: height 0.35s ease;
    transition: height 0.35s ease;
  }
  
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      -webkit-transition: none;
      -o-transition: none;
      transition: none;
    }
  }
  
  .card {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #FFFEFE;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.5rem;
  }
  
  .card > hr {
    margin-right: 0;
    margin-left: 0;
  }
  
  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit;
  }
  
  .card > .list-group:first-child {
    border-top-width: 0;
    border-top-left-radius: calc(0.5rem - 1px);
    border-top-right-radius: calc(0.5rem - 1px);
  }
  
  .card > .list-group:last-child {
    border-bottom-width: 0;
    border-bottom-right-radius: calc(0.5rem - 1px);
    border-bottom-left-radius: calc(0.5rem - 1px);
  }
  
  .card > .card-header + .list-group,
  .card > .list-group + .card-footer {
    border-top: 0;
  }
  
  .card-body {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1rem 1rem;
  }
  
  .card-title {
    margin-bottom: 0.5rem;
  }
  
  .card-subtitle {
    margin-top: -0.25rem;
    margin-bottom: 0;
  }
  
  .card-text:last-child {
    margin-bottom: 0;
  }
  
  .card-link:hover {
    text-decoration: none;
  }
  
  .card-link + .card-link {
    margin-left: 1rem;
  }
  
  .card-header {
    padding: 0.5rem 1rem;
    margin-bottom: 0;
    background-color: #f8f8f8;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  }
  
  .card-header:first-child {
    border-radius: calc(0.5rem - 1px) calc(0.5rem - 1px) 0 0;
  }
  
  .card-footer {
    padding: 0.5rem 1rem;
    background-color: #f8f8f8;
    border-top: 1px solid rgba(0, 0, 0, 0.125);
  }
  
  .card-footer:last-child {
    border-radius: 0 0 calc(0.5rem - 1px) calc(0.5rem - 1px);
  }
  
  .card-header-tabs {
    margin-right: -0.5rem;
    margin-bottom: -0.5rem;
    margin-left: -0.5rem;
    border-bottom: 0;
  }
  
  .card-header-tabs .nav-link.active {
    background-color: #FFFEFE;
    border-bottom-color: #FFFEFE;
  }
  
  .card-header-pills {
    margin-right: -0.5rem;
    margin-left: -0.5rem;
  }
  
  .card-img-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1rem;
    border-radius: calc(0.5rem - 1px);
  }
  
  .card-img,
  .card-img-top,
  .card-img-bottom {
    width: 100%;
  }
  
  .card-img,
  .card-img-top {
    border-top-left-radius: calc(0.5rem - 1px);
    border-top-right-radius: calc(0.5rem - 1px);
  }
  
  .card-img,
  .card-img-bottom {
    border-bottom-right-radius: calc(0.5rem - 1px);
    border-bottom-left-radius: calc(0.5rem - 1px);
  }
  
  .card-group > .card {
    margin-bottom: 1rem;
  }
  
  @media (min-width: 576px) {
    .card-group {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
    }
    .card-group > .card {
      -webkit-box-flex: 1;
      -ms-flex: 1 0 0%;
      flex: 1 0 0%;
      margin-bottom: 0;
    }
    .card-group > .card + .card {
      margin-left: 0;
      border-left: 0;
    }
    .card-group > .card:not(:last-child) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    .card-group > .card:not(:last-child) .card-img-top,
    .card-group > .card:not(:last-child) .card-header {
      border-top-right-radius: 0;
    }
    .card-group > .card:not(:last-child) .card-img-bottom,
    .card-group > .card:not(:last-child) .card-footer {
      border-bottom-right-radius: 0;
    }
    .card-group > .card:not(:first-child) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
    .card-group > .card:not(:first-child) .card-img-top,
    .card-group > .card:not(:first-child) .card-header {
      border-top-left-radius: 0;
    }
    .card-group > .card:not(:first-child) .card-img-bottom,
    .card-group > .card:not(:first-child) .card-footer {
      border-bottom-left-radius: 0;
    }
  }
  
  .user-select-auto {
    -webkit-user-select: auto !important;
    -moz-user-select: auto !important;
    -ms-user-select: auto !important;
    user-select: auto !important;
  }
  
  .user-select-none {
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
  }
  
  .pe-none {
    pointer-events: none !important;
  }
  
  .pe-auto {
    pointer-events: auto !important;
  }
  
  .rounded {
    border-radius: 0.5rem !important;
  }
  
  .rounded-0 {
    border-radius: 0 !important;
  }
  
  .rounded-1 {
    border-radius: 0.25rem !important;
  }
  
  .rounded-2 {
    border-radius: 0.5rem !important;
  }
  
  .rounded-3 {
    border-radius: 1rem !important;
  }
  
  .rounded-circle {
    border-radius: 50% !important;
  }
  
  .rounded-pill {
    border-radius: 50rem !important;
  }
  
  .rounded-top {
    border-top-left-radius: 0.5rem !important;
    border-top-right-radius: 0.5rem !important;
  }
  
  .rounded-top-lg {
    border-top-left-radius: 1rem !important;
    border-top-right-radius: 1rem !important;
  }
  
  .rounded-top-0 {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }
  
  .rounded-end {
    border-top-right-radius: 0.5rem !important;
    border-bottom-right-radius: 0.5rem !important;
  }
  
  .rounded-end-lg {
    border-top-right-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important;
  }
  
  .rounded-end-0 {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  
  .rounded-bottom {
    border-bottom-right-radius: 0.5rem !important;
    border-bottom-left-radius: 0.5rem !important;
  }
  
  .rounded-bottom-lg {
    border-bottom-right-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
  }
  
  .rounded-bottom-0 {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
  
  .rounded-start {
    border-bottom-left-radius: 0.5rem !important;
    border-top-left-radius: 0.5rem !important;
  }
  
  .rounded-start-lg {
    border-bottom-left-radius: 1rem !important;
    border-top-left-radius: 1rem !important;
  }
  
  .rounded-start-0 {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
  }
  
  .visible {
    visibility: visible !important;
  }
  
  .invisible {
    visibility: hidden !important;
  }
  
  .opacity-0 {
    opacity: 0 !important;
  }
  
  .opacity-25 {
    opacity: 0.25 !important;
  }
  
  .opacity-50 {
    opacity: 0.5 !important;
  }
  
  .opacity-75 {
    opacity: 0.75 !important;
  }
  
  .opacity-85 {
    opacity: 0.85 !important;
  }
  
  .opacity-100 {
    opacity: 1 !important;
  }
  
  .text-facebook {
    color: #3c5a99 !important;
  }
  
  .text-google-plus {
    color: #dd4b39 !important;
  }
  
  .text-twitter {
    color: #1da1f2 !important;
  }
  
  .text-linkedin {
    color: #0077b5 !important;
  }
  
  .text-youtube {
    color: #ff0000 !important;
  }
  
  .text-github {
    color: #333 !important;
  }
  
  .text-black {
    color: #000 !important;
  }
  
  .text-100 {
    color: #FCFCFC !important;
  }
  
  .text-200 {
    color: #F5F5F5 !important;
  }
  
  .text-300 {
    color: #EEEEEE !important;
  }
  
  .text-400 {
    color: #E0E0E0 !important;
  }
  
  .text-500 {
    color: #BDBDBD !important;
  }
  
  .text-600 {
    color: #9E9E9E !important;
  }
  
  .text-700 {
    color: #757575 !important;
  }
  
  .text-800 {
    color: #616161 !important;
  }
  
  .text-900 {
    color: #424242 !important;
  }
  
  .text-1000 {
    color: #212121 !important;
  }
  
  .text-white {
    color: #FFFEFE !important;
  }
  
  .max-vh-25 {
    max-height: 25vh !important;
  }
  
  .max-vh-50 {
    max-height: 50vh !important;
  }
  
  .max-vh-75 {
    max-height: 75vh !important;
  }
  
  .max-vh-100 {
    max-height: 100vh !important;
  }
  
  .border-x {
    border-left: 1px solid #EEEEEE !important;
    border-right: 1px solid #EEEEEE !important;
  }
  
  .border-x-0 {
    border-left: 0 !important;
    border-right: 0 !important;
  }
  
  .border-y {
    border-top: 1px solid #EEEEEE !important;
    border-bottom: 1px solid #EEEEEE !important;
  }
  
  .border-y-0 {
    border-top: 0 !important;
    border-bottom: 0 !important;
  }
  
  .border-dashed {
    border: 1px dashed #EEEEEE !important;
  }
  
  .border-dashed-top {
    border-top: 1px dashed #EEEEEE !important;
  }
  
  .border-dashed-end {
    border-right: 1px dashed #EEEEEE !important;
  }
  
  .border-dashed-start {
    border-left: 1px dashed #EEEEEE !important;
  }
  
  .border-dashed-bottom {
    border-bottom: 1px dashed #EEEEEE !important;
  }
  
  .border-dashed-x {
    border-left: 1px dashed #EEEEEE !important;
    border-right: 1px dashed #EEEEEE !important;
  }
  
  .border-dashed-y {
    border-top: 1px dashed #EEEEEE !important;
    border-bottom: 1px dashed #EEEEEE !important;
  }
  
  .rounded-0 {
    border-radius: 0 !important;
  }
  
  .bg-soft-primary {
    background-color: #fff4db !important;
  }
  
  .bg-soft-secondary {
    background-color: #dfecfc !important;
  }
  
  .bg-soft-success {
    background-color: #ebf5e2 !important;
  }
  
  .bg-soft-info {
    background-color: #defffc !important;
  }
  
  .bg-soft-warning {
    background-color: #fff4db !important;
  }
  
  .bg-soft-danger {
    background-color: #fdeadf !important;
  }
  
  .bg-soft-light {
    background-color: #fefeff !important;
  }
  
  .bg-soft-dark {
    background-color: #dedede !important;
  }
  
  @media (min-width: 576px) {
    .float-sm-start {
      float: left !important;
    }
    .float-sm-end {
      float: right !important;
    }
    .float-sm-none {
      float: none !important;
    }
    .d-sm-inline {
      display: inline !important;
    }
    .d-sm-inline-block {
      display: inline-block !important;
    }
    .d-sm-block {
      display: block !important;
    }
    .d-sm-grid {
      display: grid !important;
    }
    .d-sm-table {
      display: table !important;
    }
    .d-sm-table-row {
      display: table-row !important;
    }
    .d-sm-table-cell {
      display: table-cell !important;
    }
    .d-sm-flex {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
    }
    .d-sm-inline-flex {
      display: -webkit-inline-box !important;
      display: -ms-inline-flexbox !important;
      display: inline-flex !important;
    }
    .d-sm-none {
      display: none !important;
    }
    .position-sm-static {
      position: static !important;
    }
    .position-sm-absolute {
      position: absolute !important;
    }
    .position-sm-relative {
      position: relative !important;
    }
    .position-sm-fixed {
      position: fixed !important;
    }
    .position-sm-sticky {
      position: -webkit-sticky !important;
      position: sticky !important;
    }
    .translate-sm-middle {
      -webkit-transform: translateX(-50%) translateY(-50%) !important;
      -ms-transform: translateX(-50%) translateY(-50%) !important;
      transform: translateX(-50%) translateY(-50%) !important;
    }
    .translate-sm-middle-x {
      -webkit-transform: translateX(-50%) !important;
      -ms-transform: translateX(-50%) !important;
      transform: translateX(-50%) !important;
    }
    .translate-sm-middle-y {
      -webkit-transform: translateY(-50%) !important;
      -ms-transform: translateY(-50%) !important;
      transform: translateY(-50%) !important;
    }
    .border-sm {
      border: 1px solid #EEEEEE !important;
    }
    .border-sm-0 {
      border: 0 !important;
    }
    .border-sm-top {
      border-top: 1px solid #EEEEEE !important;
    }
    .border-sm-top-0 {
      border-top: 0 !important;
    }
    .border-sm-end {
      border-right: 1px solid #EEEEEE !important;
    }
    .border-sm-end-0 {
      border-right: 0 !important;
    }
    .border-sm-bottom {
      border-bottom: 1px solid #EEEEEE !important;
    }
    .border-sm-bottom-0 {
      border-bottom: 0 !important;
    }
    .border-sm-start {
      border-left: 1px solid #EEEEEE !important;
    }
    .border-sm-start-0 {
      border-left: 0 !important;
    }
    .border-sm-facebook {
      border-color: #3c5a99 !important;
    }
    .border-sm-google-plus {
      border-color: #dd4b39 !important;
    }
    .border-sm-twitter {
      border-color: #1da1f2 !important;
    }
    .border-sm-linkedin {
      border-color: #0077b5 !important;
    }
    .border-sm-youtube {
      border-color: #ff0000 !important;
    }
    .border-sm-github {
      border-color: #333 !important;
    }
    .border-sm-black {
      border-color: #000 !important;
    }
    .border-sm-100 {
      border-color: #FCFCFC !important;
    }
    .border-sm-200 {
      border-color: #F5F5F5 !important;
    }
    .border-sm-300 {
      border-color: #EEEEEE !important;
    }
    .border-sm-400 {
      border-color: #E0E0E0 !important;
    }
    .border-sm-500 {
      border-color: #BDBDBD !important;
    }
    .border-sm-600 {
      border-color: #9E9E9E !important;
    }
    .border-sm-700 {
      border-color: #757575 !important;
    }
    .border-sm-800 {
      border-color: #616161 !important;
    }
    .border-sm-900 {
      border-color: #424242 !important;
    }
    .border-sm-1000 {
      border-color: #212121 !important;
    }
    .border-sm-white {
      border-color: #FFFEFE !important;
    }
    .border-sm-primary {
      border-color: #FFB30E !important;
    }
    .border-sm-secondary {
      border-color: #2780ED !important;
    }
    .border-sm-success {
      border-color: #79B93C !important;
    }
    .border-sm-info {
      border-color: #24FFEA !important;
    }
    .border-sm-warning {
      border-color: #FFB30E !important;
    }
    .border-sm-danger {
      border-color: #F17228 !important;
    }
    .border-sm-light {
      border-color: #F9FAFD !important;
    }
    .border-sm-dark {
      border-color: #212121 !important;
    }
    .w-sm-25 {
      width: 25% !important;
    }
    .w-sm-50 {
      width: 50% !important;
    }
    .w-sm-75 {
      width: 75% !important;
    }
    .w-sm-100 {
      width: 100% !important;
    }
    .w-sm-auto {
      width: auto !important;
    }
    .vw-sm-25 {
      width: 25vw !important;
    }
    .vw-sm-50 {
      width: 50vw !important;
    }
    .vw-sm-75 {
      width: 75vw !important;
    }
    .vw-sm-100 {
      width: 100vw !important;
    }
    .h-sm-25 {
      height: 25% !important;
    }
    .h-sm-50 {
      height: 50% !important;
    }
    .h-sm-75 {
      height: 75% !important;
    }
    .h-sm-100 {
      height: 100% !important;
    }
    .h-sm-auto {
      height: auto !important;
    }
    .vh-sm-25 {
      height: 25vh !important;
    }
    .vh-sm-50 {
      height: 50vh !important;
    }
    .vh-sm-75 {
      height: 75vh !important;
    }
    .vh-sm-100 {
      height: 100vh !important;
    }
    .min-vh-sm-25 {
      min-height: 25vh !important;
    }
    .min-vh-sm-50 {
      min-height: 50vh !important;
    }
    .min-vh-sm-75 {
      min-height: 75vh !important;
    }
    .min-vh-sm-100 {
      min-height: 100vh !important;
    }
    .flex-sm-fill {
      -webkit-box-flex: 1 !important;
      -ms-flex: 1 1 auto !important;
      flex: 1 1 auto !important;
    }
    .flex-sm-row {
      -webkit-box-orient: horizontal !important;
      -webkit-box-direction: normal !important;
      -ms-flex-direction: row !important;
      flex-direction: row !important;
    }
    .flex-sm-column {
      -webkit-box-orient: vertical !important;
      -webkit-box-direction: normal !important;
      -ms-flex-direction: column !important;
      flex-direction: column !important;
    }
    .flex-sm-row-reverse {
      -webkit-box-orient: horizontal !important;
      -webkit-box-direction: reverse !important;
      -ms-flex-direction: row-reverse !important;
      flex-direction: row-reverse !important;
    }
    .flex-sm-column-reverse {
      -webkit-box-orient: vertical !important;
      -webkit-box-direction: reverse !important;
      -ms-flex-direction: column-reverse !important;
      flex-direction: column-reverse !important;
    }
    .flex-sm-grow-0 {
      -webkit-box-flex: 0 !important;
      -ms-flex-positive: 0 !important;
      flex-grow: 0 !important;
    }
    .flex-sm-grow-1 {
      -webkit-box-flex: 1 !important;
      -ms-flex-positive: 1 !important;
      flex-grow: 1 !important;
    }
    .flex-sm-shrink-0 {
      -ms-flex-negative: 0 !important;
      flex-shrink: 0 !important;
    }
    .flex-sm-shrink-1 {
      -ms-flex-negative: 1 !important;
      flex-shrink: 1 !important;
    }
    .flex-sm-wrap {
      -ms-flex-wrap: wrap !important;
      flex-wrap: wrap !important;
    }
    .flex-sm-nowrap {
      -ms-flex-wrap: nowrap !important;
      flex-wrap: nowrap !important;
    }
    .flex-sm-wrap-reverse {
      -ms-flex-wrap: wrap-reverse !important;
      flex-wrap: wrap-reverse !important;
    }
    .gap-sm-0 {
      gap: 0 !important;
    }
    .gap-sm-1 {
      gap: 0.25rem !important;
    }
    .gap-sm-2 {
      gap: 0.5rem !important;
    }
    .gap-sm-3 {
      gap: 1rem !important;
    }
    .gap-sm-4 {
      gap: 1.8rem !important;
    }
    .gap-sm-5 {
      gap: 3rem !important;
    }
    .gap-sm-6 {
      gap: 4rem !important;
    }
    .gap-sm-7 {
      gap: 5rem !important;
    }
    .gap-sm-8 {
      gap: 7.5rem !important;
    }
    .gap-sm-9 {
      gap: 10rem !important;
    }
    .gap-sm-10 {
      gap: 12.5rem !important;
    }
    .gap-sm-11 {
      gap: 15rem !important;
    }
    .justify-content-sm-start {
      -webkit-box-pack: start !important;
      -ms-flex-pack: start !important;
      justify-content: flex-start !important;
    }
    .justify-content-sm-end {
      -webkit-box-pack: end !important;
      -ms-flex-pack: end !important;
      justify-content: flex-end !important;
    }
    .justify-content-sm-center {
      -webkit-box-pack: center !important;
      -ms-flex-pack: center !important;
      justify-content: center !important;
    }
    .justify-content-sm-between {
      -webkit-box-pack: justify !important;
      -ms-flex-pack: justify !important;
      justify-content: space-between !important;
    }
    .justify-content-sm-around {
      -ms-flex-pack: distribute !important;
      justify-content: space-around !important;
    }
    .justify-content-sm-evenly {
      -webkit-box-pack: space-evenly !important;
      -ms-flex-pack: space-evenly !important;
      justify-content: space-evenly !important;
    }
    .align-items-sm-start {
      -webkit-box-align: start !important;
      -ms-flex-align: start !important;
      align-items: flex-start !important;
    }
    .align-items-sm-end {
      -webkit-box-align: end !important;
      -ms-flex-align: end !important;
      align-items: flex-end !important;
    }
    .align-items-sm-center {
      -webkit-box-align: center !important;
      -ms-flex-align: center !important;
      align-items: center !important;
    }
    .align-items-sm-baseline {
      -webkit-box-align: baseline !important;
      -ms-flex-align: baseline !important;
      align-items: baseline !important;
    }
    .align-items-sm-stretch {
      -webkit-box-align: stretch !important;
      -ms-flex-align: stretch !important;
      align-items: stretch !important;
    }
    .align-content-sm-start {
      -ms-flex-line-pack: start !important;
      align-content: flex-start !important;
    }
    .align-content-sm-end {
      -ms-flex-line-pack: end !important;
      align-content: flex-end !important;
    }
    .align-content-sm-center {
      -ms-flex-line-pack: center !important;
      align-content: center !important;
    }
    .align-content-sm-between {
      -ms-flex-line-pack: justify !important;
      align-content: space-between !important;
    }
    .align-content-sm-around {
      -ms-flex-line-pack: distribute !important;
      align-content: space-around !important;
    }
    .align-content-sm-stretch {
      -ms-flex-line-pack: stretch !important;
      align-content: stretch !important;
    }
    .align-self-sm-auto {
      -ms-flex-item-align: auto !important;
      align-self: auto !important;
    }
    .align-self-sm-start {
      -ms-flex-item-align: start !important;
      align-self: flex-start !important;
    }
    .align-self-sm-end {
      -ms-flex-item-align: end !important;
      align-self: flex-end !important;
    }
    .align-self-sm-center {
      -ms-flex-item-align: center !important;
      align-self: center !important;
    }
    .align-self-sm-baseline {
      -ms-flex-item-align: baseline !important;
      align-self: baseline !important;
    }
    .align-self-sm-stretch {
      -ms-flex-item-align: stretch !important;
      align-self: stretch !important;
    }
    .order-sm-first {
      -webkit-box-ordinal-group: 0 !important;
      -ms-flex-order: -1 !important;
      order: -1 !important;
    }
    .order-sm-0 {
      -webkit-box-ordinal-group: 1 !important;
      -ms-flex-order: 0 !important;
      order: 0 !important;
    }
    .order-sm-1 {
      -webkit-box-ordinal-group: 2 !important;
      -ms-flex-order: 1 !important;
      order: 1 !important;
    }
    .order-sm-2 {
      -webkit-box-ordinal-group: 3 !important;
      -ms-flex-order: 2 !important;
      order: 2 !important;
    }
    .order-sm-3 {
      -webkit-box-ordinal-group: 4 !important;
      -ms-flex-order: 3 !important;
      order: 3 !important;
    }
    .order-sm-4 {
      -webkit-box-ordinal-group: 5 !important;
      -ms-flex-order: 4 !important;
      order: 4 !important;
    }
    .order-sm-5 {
      -webkit-box-ordinal-group: 6 !important;
      -ms-flex-order: 5 !important;
      order: 5 !important;
    }
    .order-sm-last {
      -webkit-box-ordinal-group: 7 !important;
      -ms-flex-order: 6 !important;
      order: 6 !important;
    }
    .m-sm-0 {
      margin: 0 !important;
    }
    .m-sm-1 {
      margin: 0.25rem !important;
    }
    .m-sm-2 {
      margin: 0.5rem !important;
    }
    .m-sm-3 {
      margin: 1rem !important;
    }
    .m-sm-4 {
      margin: 1.8rem !important;
    }
    .m-sm-5 {
      margin: 3rem !important;
    }
    .m-sm-6 {
      margin: 4rem !important;
    }
    .m-sm-7 {
      margin: 5rem !important;
    }
    .m-sm-8 {
      margin: 7.5rem !important;
    }
    .m-sm-9 {
      margin: 10rem !important;
    }
    .m-sm-10 {
      margin: 12.5rem !important;
    }
    .m-sm-11 {
      margin: 15rem !important;
    }
    .m-sm-auto {
      margin: auto !important;
    }
    .mx-sm-0 {
      margin-right: 0 !important;
      margin-left: 0 !important;
    }
    .mx-sm-1 {
      margin-right: 0.25rem !important;
      margin-left: 0.25rem !important;
    }
    .mx-sm-2 {
      margin-right: 0.5rem !important;
      margin-left: 0.5rem !important;
    }
    .mx-sm-3 {
      margin-right: 1rem !important;
      margin-left: 1rem !important;
    }
    .mx-sm-4 {
      margin-right: 1.8rem !important;
      margin-left: 1.8rem !important;
    }
    .mx-sm-5 {
      margin-right: 3rem !important;
      margin-left: 3rem !important;
    }
    .mx-sm-6 {
      margin-right: 4rem !important;
      margin-left: 4rem !important;
    }
    .mx-sm-7 {
      margin-right: 5rem !important;
      margin-left: 5rem !important;
    }
    .mx-sm-8 {
      margin-right: 7.5rem !important;
      margin-left: 7.5rem !important;
    }
    .mx-sm-9 {
      margin-right: 10rem !important;
      margin-left: 10rem !important;
    }
    .mx-sm-10 {
      margin-right: 12.5rem !important;
      margin-left: 12.5rem !important;
    }
    .mx-sm-11 {
      margin-right: 15rem !important;
      margin-left: 15rem !important;
    }
    .mx-sm-auto {
      margin-right: auto !important;
      margin-left: auto !important;
    }
    .my-sm-0 {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }
    .my-sm-1 {
      margin-top: 0.25rem !important;
      margin-bottom: 0.25rem !important;
    }
    .my-sm-2 {
      margin-top: 0.5rem !important;
      margin-bottom: 0.5rem !important;
    }
    .my-sm-3 {
      margin-top: 1rem !important;
      margin-bottom: 1rem !important;
    }
    .my-sm-4 {
      margin-top: 1.8rem !important;
      margin-bottom: 1.8rem !important;
    }
    .my-sm-5 {
      margin-top: 3rem !important;
      margin-bottom: 3rem !important;
    }
    .my-sm-6 {
      margin-top: 4rem !important;
      margin-bottom: 4rem !important;
    }
    .my-sm-7 {
      margin-top: 5rem !important;
      margin-bottom: 5rem !important;
    }
    .my-sm-8 {
      margin-top: 7.5rem !important;
      margin-bottom: 7.5rem !important;
    }
    .my-sm-9 {
      margin-top: 10rem !important;
      margin-bottom: 10rem !important;
    }
    .my-sm-10 {
      margin-top: 12.5rem !important;
      margin-bottom: 12.5rem !important;
    }
    .my-sm-11 {
      margin-top: 15rem !important;
      margin-bottom: 15rem !important;
    }
    .my-sm-auto {
      margin-top: auto !important;
      margin-bottom: auto !important;
    }
    .mt-sm-0 {
      margin-top: 0 !important;
    }
    .mt-sm-1 {
      margin-top: 0.25rem !important;
    }
    .mt-sm-2 {
      margin-top: 0.5rem !important;
    }
    .mt-sm-3 {
      margin-top: 1rem !important;
    }
    .mt-sm-4 {
      margin-top: 1.8rem !important;
    }
    .mt-sm-5 {
      margin-top: 3rem !important;
    }
    .mt-sm-6 {
      margin-top: 4rem !important;
    }
    .mt-sm-7 {
      margin-top: 5rem !important;
    }
    .mt-sm-8 {
      margin-top: 7.5rem !important;
    }
    .mt-sm-9 {
      margin-top: 10rem !important;
    }
    .mt-sm-10 {
      margin-top: 12.5rem !important;
    }
    .mt-sm-11 {
      margin-top: 15rem !important;
    }
    .mt-sm-auto {
      margin-top: auto !important;
    }
    .me-sm-0 {
      margin-right: 0 !important;
    }
    .me-sm-1 {
      margin-right: 0.25rem !important;
    }
    .me-sm-2 {
      margin-right: 0.5rem !important;
    }
    .me-sm-3 {
      margin-right: 1rem !important;
    }
    .me-sm-4 {
      margin-right: 1.8rem !important;
    }
    .me-sm-5 {
      margin-right: 3rem !important;
    }
    .me-sm-6 {
      margin-right: 4rem !important;
    }
    .me-sm-7 {
      margin-right: 5rem !important;
    }
    .me-sm-8 {
      margin-right: 7.5rem !important;
    }
    .me-sm-9 {
      margin-right: 10rem !important;
    }
    .me-sm-10 {
      margin-right: 12.5rem !important;
    }
    .me-sm-11 {
      margin-right: 15rem !important;
    }
    .me-sm-auto {
      margin-right: auto !important;
    }
    .mb-sm-0 {
      margin-bottom: 0 !important;
    }
    .mb-sm-1 {
      margin-bottom: 0.25rem !important;
    }
    .mb-sm-2 {
      margin-bottom: 0.5rem !important;
    }
    .mb-sm-3 {
      margin-bottom: 1rem !important;
    }
    .mb-sm-4 {
      margin-bottom: 1.8rem !important;
    }
    .mb-sm-5 {
      margin-bottom: 3rem !important;
    }
    .mb-sm-6 {
      margin-bottom: 4rem !important;
    }
    .mb-sm-7 {
      margin-bottom: 5rem !important;
    }
    .mb-sm-8 {
      margin-bottom: 7.5rem !important;
    }
    .mb-sm-9 {
      margin-bottom: 10rem !important;
    }
    .mb-sm-10 {
      margin-bottom: 12.5rem !important;
    }
    .mb-sm-11 {
      margin-bottom: 15rem !important;
    }
    .mb-sm-auto {
      margin-bottom: auto !important;
    }
    .ms-sm-0 {
      margin-left: 0 !important;
    }
    .ms-sm-1 {
      margin-left: 0.25rem !important;
    }
    .ms-sm-2 {
      margin-left: 0.5rem !important;
    }
    .ms-sm-3 {
      margin-left: 1rem !important;
    }
    .ms-sm-4 {
      margin-left: 1.8rem !important;
    }
    .ms-sm-5 {
      margin-left: 3rem !important;
    }
    .ms-sm-6 {
      margin-left: 4rem !important;
    }
    .ms-sm-7 {
      margin-left: 5rem !important;
    }
    .ms-sm-8 {
      margin-left: 7.5rem !important;
    }
    .ms-sm-9 {
      margin-left: 10rem !important;
    }
    .ms-sm-10 {
      margin-left: 12.5rem !important;
    }
    .ms-sm-11 {
      margin-left: 15rem !important;
    }
    .ms-sm-auto {
      margin-left: auto !important;
    }
    .m-sm-n1 {
      margin: -0.25rem !important;
    }
    .m-sm-n2 {
      margin: -0.5rem !important;
    }
    .m-sm-n3 {
      margin: -1rem !important;
    }
    .m-sm-n4 {
      margin: -1.8rem !important;
    }
    .m-sm-n5 {
      margin: -3rem !important;
    }
    .m-sm-n6 {
      margin: -4rem !important;
    }
    .m-sm-n7 {
      margin: -5rem !important;
    }
    .m-sm-n8 {
      margin: -7.5rem !important;
    }
    .m-sm-n9 {
      margin: -10rem !important;
    }
    .m-sm-n10 {
      margin: -12.5rem !important;
    }
    .m-sm-n11 {
      margin: -15rem !important;
    }
    .mx-sm-n1 {
      margin-right: -0.25rem !important;
      margin-left: -0.25rem !important;
    }
    .mx-sm-n2 {
      margin-right: -0.5rem !important;
      margin-left: -0.5rem !important;
    }
    .mx-sm-n3 {
      margin-right: -1rem !important;
      margin-left: -1rem !important;
    }
    .mx-sm-n4 {
      margin-right: -1.8rem !important;
      margin-left: -1.8rem !important;
    }
    .mx-sm-n5 {
      margin-right: -3rem !important;
      margin-left: -3rem !important;
    }
    .mx-sm-n6 {
      margin-right: -4rem !important;
      margin-left: -4rem !important;
    }
    .mx-sm-n7 {
      margin-right: -5rem !important;
      margin-left: -5rem !important;
    }
    .mx-sm-n8 {
      margin-right: -7.5rem !important;
      margin-left: -7.5rem !important;
    }
    .mx-sm-n9 {
      margin-right: -10rem !important;
      margin-left: -10rem !important;
    }
    .mx-sm-n10 {
      margin-right: -12.5rem !important;
      margin-left: -12.5rem !important;
    }
    .mx-sm-n11 {
      margin-right: -15rem !important;
      margin-left: -15rem !important;
    }
    .my-sm-n1 {
      margin-top: -0.25rem !important;
      margin-bottom: -0.25rem !important;
    }
    .my-sm-n2 {
      margin-top: -0.5rem !important;
      margin-bottom: -0.5rem !important;
    }
    .my-sm-n3 {
      margin-top: -1rem !important;
      margin-bottom: -1rem !important;
    }
    .my-sm-n4 {
      margin-top: -1.8rem !important;
      margin-bottom: -1.8rem !important;
    }
    .my-sm-n5 {
      margin-top: -3rem !important;
      margin-bottom: -3rem !important;
    }
    .my-sm-n6 {
      margin-top: -4rem !important;
      margin-bottom: -4rem !important;
    }
    .my-sm-n7 {
      margin-top: -5rem !important;
      margin-bottom: -5rem !important;
    }
    .my-sm-n8 {
      margin-top: -7.5rem !important;
      margin-bottom: -7.5rem !important;
    }
    .my-sm-n9 {
      margin-top: -10rem !important;
      margin-bottom: -10rem !important;
    }
    .my-sm-n10 {
      margin-top: -12.5rem !important;
      margin-bottom: -12.5rem !important;
    }
    .my-sm-n11 {
      margin-top: -15rem !important;
      margin-bottom: -15rem !important;
    }
    .mt-sm-n1 {
      margin-top: -0.25rem !important;
    }
    .mt-sm-n2 {
      margin-top: -0.5rem !important;
    }
    .mt-sm-n3 {
      margin-top: -1rem !important;
    }
    .mt-sm-n4 {
      margin-top: -1.8rem !important;
    }
    .mt-sm-n5 {
      margin-top: -3rem !important;
    }
    .mt-sm-n6 {
      margin-top: -4rem !important;
    }
    .mt-sm-n7 {
      margin-top: -5rem !important;
    }
    .mt-sm-n8 {
      margin-top: -7.5rem !important;
    }
    .mt-sm-n9 {
      margin-top: -10rem !important;
    }
    .mt-sm-n10 {
      margin-top: -12.5rem !important;
    }
    .mt-sm-n11 {
      margin-top: -15rem !important;
    }
    .me-sm-n1 {
      margin-right: -0.25rem !important;
    }
    .me-sm-n2 {
      margin-right: -0.5rem !important;
    }
    .me-sm-n3 {
      margin-right: -1rem !important;
    }
    .me-sm-n4 {
      margin-right: -1.8rem !important;
    }
    .me-sm-n5 {
      margin-right: -3rem !important;
    }
    .me-sm-n6 {
      margin-right: -4rem !important;
    }
    .me-sm-n7 {
      margin-right: -5rem !important;
    }
    .me-sm-n8 {
      margin-right: -7.5rem !important;
    }
    .me-sm-n9 {
      margin-right: -10rem !important;
    }
    .me-sm-n10 {
      margin-right: -12.5rem !important;
    }
    .me-sm-n11 {
      margin-right: -15rem !important;
    }
    .mb-sm-n1 {
      margin-bottom: -0.25rem !important;
    }
    .mb-sm-n2 {
      margin-bottom: -0.5rem !important;
    }
    .mb-sm-n3 {
      margin-bottom: -1rem !important;
    }
    .mb-sm-n4 {
      margin-bottom: -1.8rem !important;
    }
    .mb-sm-n5 {
      margin-bottom: -3rem !important;
    }
    .mb-sm-n6 {
      margin-bottom: -4rem !important;
    }
    .mb-sm-n7 {
      margin-bottom: -5rem !important;
    }
    .mb-sm-n8 {
      margin-bottom: -7.5rem !important;
    }
    .mb-sm-n9 {
      margin-bottom: -10rem !important;
    }
    .mb-sm-n10 {
      margin-bottom: -12.5rem !important;
    }
    .mb-sm-n11 {
      margin-bottom: -15rem !important;
    }
    .ms-sm-n1 {
      margin-left: -0.25rem !important;
    }
    .ms-sm-n2 {
      margin-left: -0.5rem !important;
    }
    .ms-sm-n3 {
      margin-left: -1rem !important;
    }
    .ms-sm-n4 {
      margin-left: -1.8rem !important;
    }
    .ms-sm-n5 {
      margin-left: -3rem !important;
    }
    .ms-sm-n6 {
      margin-left: -4rem !important;
    }
    .ms-sm-n7 {
      margin-left: -5rem !important;
    }
    .ms-sm-n8 {
      margin-left: -7.5rem !important;
    }
    .ms-sm-n9 {
      margin-left: -10rem !important;
    }
    .ms-sm-n10 {
      margin-left: -12.5rem !important;
    }
    .ms-sm-n11 {
      margin-left: -15rem !important;
    }
    .p-sm-0 {
      padding: 0 !important;
    }
    .p-sm-1 {
      padding: 0.25rem !important;
    }
    .p-sm-2 {
      padding: 0.5rem !important;
    }
    .p-sm-3 {
      padding: 1rem !important;
    }
    .p-sm-4 {
      padding: 1.8rem !important;
    }
    .p-sm-5 {
      padding: 3rem !important;
    }
    .p-sm-6 {
      padding: 4rem !important;
    }
    .p-sm-7 {
      padding: 5rem !important;
    }
    .p-sm-8 {
      padding: 7.5rem !important;
    }
    .p-sm-9 {
      padding: 10rem !important;
    }
    .p-sm-10 {
      padding: 12.5rem !important;
    }
    .p-sm-11 {
      padding: 15rem !important;
    }
    .px-sm-0 {
      padding-right: 0 !important;
      padding-left: 0 !important;
    }
    .px-sm-1 {
      padding-right: 0.25rem !important;
      padding-left: 0.25rem !important;
    }
    .px-sm-2 {
      padding-right: 0.5rem !important;
      padding-left: 0.5rem !important;
    }
    .px-sm-3 {
      padding-right: 1rem !important;
      padding-left: 1rem !important;
    }
    .px-sm-4 {
      padding-right: 1.8rem !important;
      padding-left: 1.8rem !important;
    }
    .px-sm-5 {
      padding-right: 3rem !important;
      padding-left: 3rem !important;
    }
    .px-sm-6 {
      padding-right: 4rem !important;
      padding-left: 4rem !important;
    }
    .px-sm-7 {
      padding-right: 5rem !important;
      padding-left: 5rem !important;
    }
    .px-sm-8 {
      padding-right: 7.5rem !important;
      padding-left: 7.5rem !important;
    }
    .px-sm-9 {
      padding-right: 10rem !important;
      padding-left: 10rem !important;
    }
    .px-sm-10 {
      padding-right: 12.5rem !important;
      padding-left: 12.5rem !important;
    }
    .px-sm-11 {
      padding-right: 15rem !important;
      padding-left: 15rem !important;
    }
    .py-sm-0 {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
    .py-sm-1 {
      padding-top: 0.25rem !important;
      padding-bottom: 0.25rem !important;
    }
    .py-sm-2 {
      padding-top: 0.5rem !important;
      padding-bottom: 0.5rem !important;
    }
    .py-sm-3 {
      padding-top: 1rem !important;
      padding-bottom: 1rem !important;
    }
    .py-sm-4 {
      padding-top: 1.8rem !important;
      padding-bottom: 1.8rem !important;
    }
    .py-sm-5 {
      padding-top: 3rem !important;
      padding-bottom: 3rem !important;
    }
    .py-sm-6 {
      padding-top: 4rem !important;
      padding-bottom: 4rem !important;
    }
    .py-sm-7 {
      padding-top: 5rem !important;
      padding-bottom: 5rem !important;
    }
    .py-sm-8 {
      padding-top: 7.5rem !important;
      padding-bottom: 7.5rem !important;
    }
    .py-sm-9 {
      padding-top: 10rem !important;
      padding-bottom: 10rem !important;
    }
    .py-sm-10 {
      padding-top: 12.5rem !important;
      padding-bottom: 12.5rem !important;
    }
    .py-sm-11 {
      padding-top: 15rem !important;
      padding-bottom: 15rem !important;
    }
    .pt-sm-0 {
      padding-top: 0 !important;
    }
    .pt-sm-1 {
      padding-top: 0.25rem !important;
    }
    .pt-sm-2 {
      padding-top: 0.5rem !important;
    }
    .pt-sm-3 {
      padding-top: 1rem !important;
    }
    .pt-sm-4 {
      padding-top: 1.8rem !important;
    }
    .pt-sm-5 {
      padding-top: 3rem !important;
    }
    .pt-sm-6 {
      padding-top: 4rem !important;
    }
    .pt-sm-7 {
      padding-top: 5rem !important;
    }
    .pt-sm-8 {
      padding-top: 7.5rem !important;
    }
    .pt-sm-9 {
      padding-top: 10rem !important;
    }
    .pt-sm-10 {
      padding-top: 12.5rem !important;
    }
    .pt-sm-11 {
      padding-top: 15rem !important;
    }
    .pe-sm-0 {
      padding-right: 0 !important;
    }
    .pe-sm-1 {
      padding-right: 0.25rem !important;
    }
    .pe-sm-2 {
      padding-right: 0.5rem !important;
    }
    .pe-sm-3 {
      padding-right: 1rem !important;
    }
    .pe-sm-4 {
      padding-right: 1.8rem !important;
    }
    .pe-sm-5 {
      padding-right: 3rem !important;
    }
    .pe-sm-6 {
      padding-right: 4rem !important;
    }
    .pe-sm-7 {
      padding-right: 5rem !important;
    }
    .pe-sm-8 {
      padding-right: 7.5rem !important;
    }
    .pe-sm-9 {
      padding-right: 10rem !important;
    }
    .pe-sm-10 {
      padding-right: 12.5rem !important;
    }
    .pe-sm-11 {
      padding-right: 15rem !important;
    }
    .pb-sm-0 {
      padding-bottom: 0 !important;
    }
    .pb-sm-1 {
      padding-bottom: 0.25rem !important;
    }
    .pb-sm-2 {
      padding-bottom: 0.5rem !important;
    }
    .pb-sm-3 {
      padding-bottom: 1rem !important;
    }
    .pb-sm-4 {
      padding-bottom: 1.8rem !important;
    }
    .pb-sm-5 {
      padding-bottom: 3rem !important;
    }
    .pb-sm-6 {
      padding-bottom: 4rem !important;
    }
    .pb-sm-7 {
      padding-bottom: 5rem !important;
    }
    .pb-sm-8 {
      padding-bottom: 7.5rem !important;
    }
    .pb-sm-9 {
      padding-bottom: 10rem !important;
    }
    .pb-sm-10 {
      padding-bottom: 12.5rem !important;
    }
    .pb-sm-11 {
      padding-bottom: 15rem !important;
    }
    .ps-sm-0 {
      padding-left: 0 !important;
    }
    .ps-sm-1 {
      padding-left: 0.25rem !important;
    }
    .ps-sm-2 {
      padding-left: 0.5rem !important;
    }
    .ps-sm-3 {
      padding-left: 1rem !important;
    }
    .ps-sm-4 {
      padding-left: 1.8rem !important;
    }
    .ps-sm-5 {
      padding-left: 3rem !important;
    }
    .ps-sm-6 {
      padding-left: 4rem !important;
    }
    .ps-sm-7 {
      padding-left: 5rem !important;
    }
    .ps-sm-8 {
      padding-left: 7.5rem !important;
    }
    .ps-sm-9 {
      padding-left: 10rem !important;
    }
    .ps-sm-10 {
      padding-left: 12.5rem !important;
    }
    .ps-sm-11 {
      padding-left: 15rem !important;
    }
    .fs-sm--2 {
      font-size: 0.69444rem !important;
    }
    .fs-sm--1 {
      font-size: 0.83333rem !important;
    }
    .fs-sm-0 {
      font-size: 1rem !important;
    }
    .fs-sm-1 {
      font-size: 1.2rem !important;
    }
    .fs-sm-2 {
      font-size: 1.44rem !important;
    }
    .fs-sm-3 {
      font-size: 1.728rem !important;
    }
    .fs-sm-4 {
      font-size: 2.0736rem !important;
    }
    .fs-sm-5 {
      font-size: 2.48832rem !important;
    }
    .fs-sm-6 {
      font-size: 2.98598rem !important;
    }
    .fs-sm-7 {
      font-size: 3.58318rem !important;
    }
    .fs-sm-8 {
      font-size: 4.29982rem !important;
    }
    .text-sm-start {
      text-align: left !important;
    }
    .text-sm-end {
      text-align: right !important;
    }
    .text-sm-center {
      text-align: center !important;
    }
    .rounded-sm-top {
      border-top-left-radius: 0.5rem !important;
      border-top-right-radius: 0.5rem !important;
    }
    .rounded-sm-top-lg {
      border-top-left-radius: 1rem !important;
      border-top-right-radius: 1rem !important;
    }
    .rounded-sm-top-0 {
      border-top-left-radius: 0 !important;
      border-top-right-radius: 0 !important;
    }
    .rounded-sm-end {
      border-top-right-radius: 0.5rem !important;
      border-bottom-right-radius: 0.5rem !important;
    }
    .rounded-sm-end-lg {
      border-top-right-radius: 1rem !important;
      border-bottom-right-radius: 1rem !important;
    }
    .rounded-sm-end-0 {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
    .rounded-sm-bottom {
      border-bottom-right-radius: 0.5rem !important;
      border-bottom-left-radius: 0.5rem !important;
    }
    .rounded-sm-bottom-lg {
      border-bottom-right-radius: 1rem !important;
      border-bottom-left-radius: 1rem !important;
    }
    .rounded-sm-bottom-0 {
      border-bottom-right-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }
    .rounded-sm-start {
      border-bottom-left-radius: 0.5rem !important;
      border-top-left-radius: 0.5rem !important;
    }
    .rounded-sm-start-lg {
      border-bottom-left-radius: 1rem !important;
      border-top-left-radius: 1rem !important;
    }
    .rounded-sm-start-0 {
      border-bottom-left-radius: 0 !important;
      border-top-left-radius: 0 !important;
    }
    .opacity-sm-0 {
      opacity: 0 !important;
    }
    .opacity-sm-25 {
      opacity: 0.25 !important;
    }
    .opacity-sm-50 {
      opacity: 0.5 !important;
    }
    .opacity-sm-75 {
      opacity: 0.75 !important;
    }
    .opacity-sm-85 {
      opacity: 0.85 !important;
    }
    .opacity-sm-100 {
      opacity: 1 !important;
    }
    .max-vh-sm-25 {
      max-height: 25vh !important;
    }
    .max-vh-sm-50 {
      max-height: 50vh !important;
    }
    .max-vh-sm-75 {
      max-height: 75vh !important;
    }
    .max-vh-sm-100 {
      max-height: 100vh !important;
    }
    .border-sm-x {
      border-left: 1px solid #EEEEEE !important;
      border-right: 1px solid #EEEEEE !important;
    }
    .border-sm-x-0 {
      border-left: 0 !important;
      border-right: 0 !important;
    }
    .border-sm-y {
      border-top: 1px solid #EEEEEE !important;
      border-bottom: 1px solid #EEEEEE !important;
    }
    .border-sm-y-0 {
      border-top: 0 !important;
      border-bottom: 0 !important;
    }
    .border-sm-dashed {
      border: 1px dashed #EEEEEE !important;
    }
    .border-sm-dashed-top {
      border-top: 1px dashed #EEEEEE !important;
    }
    .border-sm-dashed-end {
      border-right: 1px dashed #EEEEEE !important;
    }
    .border-sm-dashed-start {
      border-left: 1px dashed #EEEEEE !important;
    }
    .border-sm-dashed-bottom {
      border-bottom: 1px dashed #EEEEEE !important;
    }
    .border-sm-dashed-x {
      border-left: 1px dashed #EEEEEE !important;
      border-right: 1px dashed #EEEEEE !important;
    }
    .border-sm-dashed-y {
      border-top: 1px dashed #EEEEEE !important;
      border-bottom: 1px dashed #EEEEEE !important;
    }
    .rounded-sm-0 {
      border-radius: 0 !important;
    }
  }
  
  @media (min-width: 768px) {
    .float-md-start {
      float: left !important;
    }
    .float-md-end {
      float: right !important;
    }
    .float-md-none {
      float: none !important;
    }
    .d-md-inline {
      display: inline !important;
    }
    .d-md-inline-block {
      display: inline-block !important;
    }
    .d-md-block {
      display: block !important;
    }
    .d-md-grid {
      display: grid !important;
    }
    .d-md-table {
      display: table !important;
    }
    .d-md-table-row {
      display: table-row !important;
    }
    .d-md-table-cell {
      display: table-cell !important;
    }
    .d-md-flex {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
    }
    .d-md-inline-flex {
      display: -webkit-inline-box !important;
      display: -ms-inline-flexbox !important;
      display: inline-flex !important;
    }
    .d-md-none {
      display: none !important;
    }
    .position-md-static {
      position: static !important;
    }
    .position-md-absolute {
      position: absolute !important;
    }
    .position-md-relative {
      position: relative !important;
    }
    .position-md-fixed {
      position: fixed !important;
    }
    .position-md-sticky {
      position: -webkit-sticky !important;
      position: sticky !important;
    }
    .translate-md-middle {
      -webkit-transform: translateX(-50%) translateY(-50%) !important;
      -ms-transform: translateX(-50%) translateY(-50%) !important;
      transform: translateX(-50%) translateY(-50%) !important;
    }
    .translate-md-middle-x {
      -webkit-transform: translateX(-50%) !important;
      -ms-transform: translateX(-50%) !important;
      transform: translateX(-50%) !important;
    }
    .translate-md-middle-y {
      -webkit-transform: translateY(-50%) !important;
      -ms-transform: translateY(-50%) !important;
      transform: translateY(-50%) !important;
    }
    .border-md {
      border: 1px solid #EEEEEE !important;
    }
    .border-md-0 {
      border: 0 !important;
    }
    .border-md-top {
      border-top: 1px solid #EEEEEE !important;
    }
    .border-md-top-0 {
      border-top: 0 !important;
    }
    .border-md-end {
      border-right: 1px solid #EEEEEE !important;
    }
    .border-md-end-0 {
      border-right: 0 !important;
    }
    .border-md-bottom {
      border-bottom: 1px solid #EEEEEE !important;
    }
    .border-md-bottom-0 {
      border-bottom: 0 !important;
    }
    .border-md-start {
      border-left: 1px solid #EEEEEE !important;
    }
    .border-md-start-0 {
      border-left: 0 !important;
    }
    .border-md-facebook {
      border-color: #3c5a99 !important;
    }
    .border-md-google-plus {
      border-color: #dd4b39 !important;
    }
    .border-md-twitter {
      border-color: #1da1f2 !important;
    }
    .border-md-linkedin {
      border-color: #0077b5 !important;
    }
    .border-md-youtube {
      border-color: #ff0000 !important;
    }
    .border-md-github {
      border-color: #333 !important;
    }
    .border-md-black {
      border-color: #000 !important;
    }
    .border-md-100 {
      border-color: #FCFCFC !important;
    }
    .border-md-200 {
      border-color: #F5F5F5 !important;
    }
    .border-md-300 {
      border-color: #EEEEEE !important;
    }
    .border-md-400 {
      border-color: #E0E0E0 !important;
    }
    .border-md-500 {
      border-color: #BDBDBD !important;
    }
    .border-md-600 {
      border-color: #9E9E9E !important;
    }
    .border-md-700 {
      border-color: #757575 !important;
    }
    .border-md-800 {
      border-color: #616161 !important;
    }
    .border-md-900 {
      border-color: #424242 !important;
    }
    .border-md-1000 {
      border-color: #212121 !important;
    }
    .border-md-white {
      border-color: #FFFEFE !important;
    }
    .border-md-primary {
      border-color: #FFB30E !important;
    }
    .border-md-secondary {
      border-color: #2780ED !important;
    }
    .border-md-success {
      border-color: #79B93C !important;
    }
    .border-md-info {
      border-color: #24FFEA !important;
    }
    .border-md-warning {
      border-color: #FFB30E !important;
    }
    .border-md-danger {
      border-color: #F17228 !important;
    }
    .border-md-light {
      border-color: #F9FAFD !important;
    }
    .border-md-dark {
      border-color: #212121 !important;
    }
    .w-md-25 {
      width: 25% !important;
    }
    .w-md-50 {
      width: 50% !important;
    }
    .w-md-75 {
      width: 75% !important;
    }
    .w-md-100 {
      width: 100% !important;
    }
    .w-md-auto {
      width: auto !important;
    }
    .vw-md-25 {
      width: 25vw !important;
    }
    .vw-md-50 {
      width: 50vw !important;
    }
    .vw-md-75 {
      width: 75vw !important;
    }
    .vw-md-100 {
      width: 100vw !important;
    }
    .h-md-25 {
      height: 25% !important;
    }
    .h-md-50 {
      height: 50% !important;
    }
    .h-md-75 {
      height: 75% !important;
    }
    .h-md-100 {
      height: 100% !important;
    }
    .h-md-auto {
      height: auto !important;
    }
    .vh-md-25 {
      height: 25vh !important;
    }
    .vh-md-50 {
      height: 50vh !important;
    }
    .vh-md-75 {
      height: 75vh !important;
    }
    .vh-md-100 {
      height: 100vh !important;
    }
    .min-vh-md-25 {
      min-height: 25vh !important;
    }
    .min-vh-md-50 {
      min-height: 50vh !important;
    }
    .min-vh-md-75 {
      min-height: 75vh !important;
    }
    .min-vh-md-100 {
      min-height: 100vh !important;
    }
    .flex-md-fill {
      -webkit-box-flex: 1 !important;
      -ms-flex: 1 1 auto !important;
      flex: 1 1 auto !important;
    }
    .flex-md-row {
      -webkit-box-orient: horizontal !important;
      -webkit-box-direction: normal !important;
      -ms-flex-direction: row !important;
      flex-direction: row !important;
    }
    .flex-md-column {
      -webkit-box-orient: vertical !important;
      -webkit-box-direction: normal !important;
      -ms-flex-direction: column !important;
      flex-direction: column !important;
    }
    .flex-md-row-reverse {
      -webkit-box-orient: horizontal !important;
      -webkit-box-direction: reverse !important;
      -ms-flex-direction: row-reverse !important;
      flex-direction: row-reverse !important;
    }
    .flex-md-column-reverse {
      -webkit-box-orient: vertical !important;
      -webkit-box-direction: reverse !important;
      -ms-flex-direction: column-reverse !important;
      flex-direction: column-reverse !important;
    }
    .flex-md-grow-0 {
      -webkit-box-flex: 0 !important;
      -ms-flex-positive: 0 !important;
      flex-grow: 0 !important;
    }
    .flex-md-grow-1 {
      -webkit-box-flex: 1 !important;
      -ms-flex-positive: 1 !important;
      flex-grow: 1 !important;
    }
    .flex-md-shrink-0 {
      -ms-flex-negative: 0 !important;
      flex-shrink: 0 !important;
    }
    .flex-md-shrink-1 {
      -ms-flex-negative: 1 !important;
      flex-shrink: 1 !important;
    }
    .flex-md-wrap {
      -ms-flex-wrap: wrap !important;
      flex-wrap: wrap !important;
    }
    .flex-md-nowrap {
      -ms-flex-wrap: nowrap !important;
      flex-wrap: nowrap !important;
    }
    .flex-md-wrap-reverse {
      -ms-flex-wrap: wrap-reverse !important;
      flex-wrap: wrap-reverse !important;
    }
    .gap-md-0 {
      gap: 0 !important;
    }
    .gap-md-1 {
      gap: 0.25rem !important;
    }
    .gap-md-2 {
      gap: 0.5rem !important;
    }
    .gap-md-3 {
      gap: 1rem !important;
    }
    .gap-md-4 {
      gap: 1.8rem !important;
    }
    .gap-md-5 {
      gap: 3rem !important;
    }
    .gap-md-6 {
      gap: 4rem !important;
    }
    .gap-md-7 {
      gap: 5rem !important;
    }
    .gap-md-8 {
      gap: 7.5rem !important;
    }
    .gap-md-9 {
      gap: 10rem !important;
    }
    .gap-md-10 {
      gap: 12.5rem !important;
    }
    .gap-md-11 {
      gap: 15rem !important;
    }
    .justify-content-md-start {
      -webkit-box-pack: start !important;
      -ms-flex-pack: start !important;
      justify-content: flex-start !important;
    }
    .justify-content-md-end {
      -webkit-box-pack: end !important;
      -ms-flex-pack: end !important;
      justify-content: flex-end !important;
    }
    .justify-content-md-center {
      -webkit-box-pack: center !important;
      -ms-flex-pack: center !important;
      justify-content: center !important;
    }
    .justify-content-md-between {
      -webkit-box-pack: justify !important;
      -ms-flex-pack: justify !important;
      justify-content: space-between !important;
    }
    .justify-content-md-around {
      -ms-flex-pack: distribute !important;
      justify-content: space-around !important;
    }
    .justify-content-md-evenly {
      -webkit-box-pack: space-evenly !important;
      -ms-flex-pack: space-evenly !important;
      justify-content: space-evenly !important;
    }
    .align-items-md-start {
      -webkit-box-align: start !important;
      -ms-flex-align: start !important;
      align-items: flex-start !important;
    }
    .align-items-md-end {
      -webkit-box-align: end !important;
      -ms-flex-align: end !important;
      align-items: flex-end !important;
    }
    .align-items-md-center {
      -webkit-box-align: center !important;
      -ms-flex-align: center !important;
      align-items: center !important;
    }
    .align-items-md-baseline {
      -webkit-box-align: baseline !important;
      -ms-flex-align: baseline !important;
      align-items: baseline !important;
    }
    .align-items-md-stretch {
      -webkit-box-align: stretch !important;
      -ms-flex-align: stretch !important;
      align-items: stretch !important;
    }
    .align-content-md-start {
      -ms-flex-line-pack: start !important;
      align-content: flex-start !important;
    }
    .align-content-md-end {
      -ms-flex-line-pack: end !important;
      align-content: flex-end !important;
    }
    .align-content-md-center {
      -ms-flex-line-pack: center !important;
      align-content: center !important;
    }
    .align-content-md-between {
      -ms-flex-line-pack: justify !important;
      align-content: space-between !important;
    }
    .align-content-md-around {
      -ms-flex-line-pack: distribute !important;
      align-content: space-around !important;
    }
    .align-content-md-stretch {
      -ms-flex-line-pack: stretch !important;
      align-content: stretch !important;
    }
    .align-self-md-auto {
      -ms-flex-item-align: auto !important;
      align-self: auto !important;
    }
    .align-self-md-start {
      -ms-flex-item-align: start !important;
      align-self: flex-start !important;
    }
    .align-self-md-end {
      -ms-flex-item-align: end !important;
      align-self: flex-end !important;
    }
    .align-self-md-center {
      -ms-flex-item-align: center !important;
      align-self: center !important;
    }
    .align-self-md-baseline {
      -ms-flex-item-align: baseline !important;
      align-self: baseline !important;
    }
    .align-self-md-stretch {
      -ms-flex-item-align: stretch !important;
      align-self: stretch !important;
    }
    .order-md-first {
      -webkit-box-ordinal-group: 0 !important;
      -ms-flex-order: -1 !important;
      order: -1 !important;
    }
    .order-md-0 {
      -webkit-box-ordinal-group: 1 !important;
      -ms-flex-order: 0 !important;
      order: 0 !important;
    }
    .order-md-1 {
      -webkit-box-ordinal-group: 2 !important;
      -ms-flex-order: 1 !important;
      order: 1 !important;
    }
    .order-md-2 {
      -webkit-box-ordinal-group: 3 !important;
      -ms-flex-order: 2 !important;
      order: 2 !important;
    }
    .order-md-3 {
      -webkit-box-ordinal-group: 4 !important;
      -ms-flex-order: 3 !important;
      order: 3 !important;
    }
    .order-md-4 {
      -webkit-box-ordinal-group: 5 !important;
      -ms-flex-order: 4 !important;
      order: 4 !important;
    }
    .order-md-5 {
      -webkit-box-ordinal-group: 6 !important;
      -ms-flex-order: 5 !important;
      order: 5 !important;
    }
    .order-md-last {
      -webkit-box-ordinal-group: 7 !important;
      -ms-flex-order: 6 !important;
      order: 6 !important;
    }
    .m-md-0 {
      margin: 0 !important;
    }
    .m-md-1 {
      margin: 0.25rem !important;
    }
    .m-md-2 {
      margin: 0.5rem !important;
    }
    .m-md-3 {
      margin: 1rem !important;
    }
    .m-md-4 {
      margin: 1.8rem !important;
    }
    .m-md-5 {
      margin: 3rem !important;
    }
    .m-md-6 {
      margin: 4rem !important;
    }
    .m-md-7 {
      margin: 5rem !important;
    }
    .m-md-8 {
      margin: 7.5rem !important;
    }
    .m-md-9 {
      margin: 10rem !important;
    }
    .m-md-10 {
      margin: 12.5rem !important;
    }
    .m-md-11 {
      margin: 15rem !important;
    }
    .m-md-auto {
      margin: auto !important;
    }
    .mx-md-0 {
      margin-right: 0 !important;
      margin-left: 0 !important;
    }
    .mx-md-1 {
      margin-right: 0.25rem !important;
      margin-left: 0.25rem !important;
    }
    .mx-md-2 {
      margin-right: 0.5rem !important;
      margin-left: 0.5rem !important;
    }
    .mx-md-3 {
      margin-right: 1rem !important;
      margin-left: 1rem !important;
    }
    .mx-md-4 {
      margin-right: 1.8rem !important;
      margin-left: 1.8rem !important;
    }
    .mx-md-5 {
      margin-right: 3rem !important;
      margin-left: 3rem !important;
    }
    .mx-md-6 {
      margin-right: 4rem !important;
      margin-left: 4rem !important;
    }
    .mx-md-7 {
      margin-right: 5rem !important;
      margin-left: 5rem !important;
    }
    .mx-md-8 {
      margin-right: 7.5rem !important;
      margin-left: 7.5rem !important;
    }
    .mx-md-9 {
      margin-right: 10rem !important;
      margin-left: 10rem !important;
    }
    .mx-md-10 {
      margin-right: 12.5rem !important;
      margin-left: 12.5rem !important;
    }
    .mx-md-11 {
      margin-right: 15rem !important;
      margin-left: 15rem !important;
    }
    .mx-md-auto {
      margin-right: auto !important;
      margin-left: auto !important;
    }
    .my-md-0 {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }
    .my-md-1 {
      margin-top: 0.25rem !important;
      margin-bottom: 0.25rem !important;
    }
    .my-md-2 {
      margin-top: 0.5rem !important;
      margin-bottom: 0.5rem !important;
    }
    .my-md-3 {
      margin-top: 1rem !important;
      margin-bottom: 1rem !important;
    }
    .my-md-4 {
      margin-top: 1.8rem !important;
      margin-bottom: 1.8rem !important;
    }
    .my-md-5 {
      margin-top: 3rem !important;
      margin-bottom: 3rem !important;
    }
    .my-md-6 {
      margin-top: 4rem !important;
      margin-bottom: 4rem !important;
    }
    .my-md-7 {
      margin-top: 5rem !important;
      margin-bottom: 5rem !important;
    }
    .my-md-8 {
      margin-top: 7.5rem !important;
      margin-bottom: 7.5rem !important;
    }
    .my-md-9 {
      margin-top: 10rem !important;
      margin-bottom: 10rem !important;
    }
    .my-md-10 {
      margin-top: 12.5rem !important;
      margin-bottom: 12.5rem !important;
    }
    .my-md-11 {
      margin-top: 15rem !important;
      margin-bottom: 15rem !important;
    }
    .my-md-auto {
      margin-top: auto !important;
      margin-bottom: auto !important;
    }
    .mt-md-0 {
      margin-top: 0 !important;
    }
    .mt-md-1 {
      margin-top: 0.25rem !important;
    }
    .mt-md-2 {
      margin-top: 0.5rem !important;
    }
    .mt-md-3 {
      margin-top: 1rem !important;
    }
    .mt-md-4 {
      margin-top: 1.8rem !important;
    }
    .mt-md-5 {
      margin-top: 3rem !important;
    }
    .mt-md-6 {
      margin-top: 4rem !important;
    }
    .mt-md-7 {
      margin-top: 5rem !important;
    }
    .mt-md-8 {
      margin-top: 7.5rem !important;
    }
    .mt-md-9 {
      margin-top: 10rem !important;
    }
    .mt-md-10 {
      margin-top: 12.5rem !important;
    }
    .mt-md-11 {
      margin-top: 15rem !important;
    }
    .mt-md-auto {
      margin-top: auto !important;
    }
    .me-md-0 {
      margin-right: 0 !important;
    }
    .me-md-1 {
      margin-right: 0.25rem !important;
    }
    .me-md-2 {
      margin-right: 0.5rem !important;
    }
    .me-md-3 {
      margin-right: 1rem !important;
    }
    .me-md-4 {
      margin-right: 1.8rem !important;
    }
    .me-md-5 {
      margin-right: 3rem !important;
    }
    .me-md-6 {
      margin-right: 4rem !important;
    }
    .me-md-7 {
      margin-right: 5rem !important;
    }
    .me-md-8 {
      margin-right: 7.5rem !important;
    }
    .me-md-9 {
      margin-right: 10rem !important;
    }
    .me-md-10 {
      margin-right: 12.5rem !important;
    }
    .me-md-11 {
      margin-right: 15rem !important;
    }
    .me-md-auto {
      margin-right: auto !important;
    }
    .mb-md-0 {
      margin-bottom: 0 !important;
    }
    .mb-md-1 {
      margin-bottom: 0.25rem !important;
    }
    .mb-md-2 {
      margin-bottom: 0.5rem !important;
    }
    .mb-md-3 {
      margin-bottom: 1rem !important;
    }
    .mb-md-4 {
      margin-bottom: 1.8rem !important;
    }
    .mb-md-5 {
      margin-bottom: 3rem !important;
    }
    .mb-md-6 {
      margin-bottom: 4rem !important;
    }
    .mb-md-7 {
      margin-bottom: 5rem !important;
    }
    .mb-md-8 {
      margin-bottom: 7.5rem !important;
    }
    .mb-md-9 {
      margin-bottom: 10rem !important;
    }
    .mb-md-10 {
      margin-bottom: 12.5rem !important;
    }
    .mb-md-11 {
      margin-bottom: 15rem !important;
    }
    .mb-md-auto {
      margin-bottom: auto !important;
    }
    .ms-md-0 {
      margin-left: 0 !important;
    }
    .ms-md-1 {
      margin-left: 0.25rem !important;
    }
    .ms-md-2 {
      margin-left: 0.5rem !important;
    }
    .ms-md-3 {
      margin-left: 1rem !important;
    }
    .ms-md-4 {
      margin-left: 1.8rem !important;
    }
    .ms-md-5 {
      margin-left: 3rem !important;
    }
    .ms-md-6 {
      margin-left: 4rem !important;
    }
    .ms-md-7 {
      margin-left: 5rem !important;
    }
    .ms-md-8 {
      margin-left: 7.5rem !important;
    }
    .ms-md-9 {
      margin-left: 10rem !important;
    }
    .ms-md-10 {
      margin-left: 12.5rem !important;
    }
    .ms-md-11 {
      margin-left: 15rem !important;
    }
    .ms-md-auto {
      margin-left: auto !important;
    }
    .m-md-n1 {
      margin: -0.25rem !important;
    }
    .m-md-n2 {
      margin: -0.5rem !important;
    }
    .m-md-n3 {
      margin: -1rem !important;
    }
    .m-md-n4 {
      margin: -1.8rem !important;
    }
    .m-md-n5 {
      margin: -3rem !important;
    }
    .m-md-n6 {
      margin: -4rem !important;
    }
    .m-md-n7 {
      margin: -5rem !important;
    }
    .m-md-n8 {
      margin: -7.5rem !important;
    }
    .m-md-n9 {
      margin: -10rem !important;
    }
    .m-md-n10 {
      margin: -12.5rem !important;
    }
    .m-md-n11 {
      margin: -15rem !important;
    }
    .mx-md-n1 {
      margin-right: -0.25rem !important;
      margin-left: -0.25rem !important;
    }
    .mx-md-n2 {
      margin-right: -0.5rem !important;
      margin-left: -0.5rem !important;
    }
    .mx-md-n3 {
      margin-right: -1rem !important;
      margin-left: -1rem !important;
    }
    .mx-md-n4 {
      margin-right: -1.8rem !important;
      margin-left: -1.8rem !important;
    }
    .mx-md-n5 {
      margin-right: -3rem !important;
      margin-left: -3rem !important;
    }
    .mx-md-n6 {
      margin-right: -4rem !important;
      margin-left: -4rem !important;
    }
    .mx-md-n7 {
      margin-right: -5rem !important;
      margin-left: -5rem !important;
    }
    .mx-md-n8 {
      margin-right: -7.5rem !important;
      margin-left: -7.5rem !important;
    }
    .mx-md-n9 {
      margin-right: -10rem !important;
      margin-left: -10rem !important;
    }
    .mx-md-n10 {
      margin-right: -12.5rem !important;
      margin-left: -12.5rem !important;
    }
    .mx-md-n11 {
      margin-right: -15rem !important;
      margin-left: -15rem !important;
    }
    .my-md-n1 {
      margin-top: -0.25rem !important;
      margin-bottom: -0.25rem !important;
    }
    .my-md-n2 {
      margin-top: -0.5rem !important;
      margin-bottom: -0.5rem !important;
    }
    .my-md-n3 {
      margin-top: -1rem !important;
      margin-bottom: -1rem !important;
    }
    .my-md-n4 {
      margin-top: -1.8rem !important;
      margin-bottom: -1.8rem !important;
    }
    .my-md-n5 {
      margin-top: -3rem !important;
      margin-bottom: -3rem !important;
    }
    .my-md-n6 {
      margin-top: -4rem !important;
      margin-bottom: -4rem !important;
    }
    .my-md-n7 {
      margin-top: -5rem !important;
      margin-bottom: -5rem !important;
    }
    .my-md-n8 {
      margin-top: -7.5rem !important;
      margin-bottom: -7.5rem !important;
    }
    .my-md-n9 {
      margin-top: -10rem !important;
      margin-bottom: -10rem !important;
    }
    .my-md-n10 {
      margin-top: -12.5rem !important;
      margin-bottom: -12.5rem !important;
    }
    .my-md-n11 {
      margin-top: -15rem !important;
      margin-bottom: -15rem !important;
    }
    .mt-md-n1 {
      margin-top: -0.25rem !important;
    }
    .mt-md-n2 {
      margin-top: -0.5rem !important;
    }
    .mt-md-n3 {
      margin-top: -1rem !important;
    }
    .mt-md-n4 {
      margin-top: -1.8rem !important;
    }
    .mt-md-n5 {
      margin-top: -3rem !important;
    }
    .mt-md-n6 {
      margin-top: -4rem !important;
    }
    .mt-md-n7 {
      margin-top: -5rem !important;
    }
    .mt-md-n8 {
      margin-top: -7.5rem !important;
    }
    .mt-md-n9 {
      margin-top: -10rem !important;
    }
    .mt-md-n10 {
      margin-top: -12.5rem !important;
    }
    .mt-md-n11 {
      margin-top: -15rem !important;
    }
    .me-md-n1 {
      margin-right: -0.25rem !important;
    }
    .me-md-n2 {
      margin-right: -0.5rem !important;
    }
    .me-md-n3 {
      margin-right: -1rem !important;
    }
    .me-md-n4 {
      margin-right: -1.8rem !important;
    }
    .me-md-n5 {
      margin-right: -3rem !important;
    }
    .me-md-n6 {
      margin-right: -4rem !important;
    }
    .me-md-n7 {
      margin-right: -5rem !important;
    }
    .me-md-n8 {
      margin-right: -7.5rem !important;
    }
    .me-md-n9 {
      margin-right: -10rem !important;
    }
    .me-md-n10 {
      margin-right: -12.5rem !important;
    }
    .me-md-n11 {
      margin-right: -15rem !important;
    }
    .mb-md-n1 {
      margin-bottom: -0.25rem !important;
    }
    .mb-md-n2 {
      margin-bottom: -0.5rem !important;
    }
    .mb-md-n3 {
      margin-bottom: -1rem !important;
    }
    .mb-md-n4 {
      margin-bottom: -1.8rem !important;
    }
    .mb-md-n5 {
      margin-bottom: -3rem !important;
    }
    .mb-md-n6 {
      margin-bottom: -4rem !important;
    }
    .mb-md-n7 {
      margin-bottom: -5rem !important;
    }
    .mb-md-n8 {
      margin-bottom: -7.5rem !important;
    }
    .mb-md-n9 {
      margin-bottom: -10rem !important;
    }
    .mb-md-n10 {
      margin-bottom: -12.5rem !important;
    }
    .mb-md-n11 {
      margin-bottom: -15rem !important;
    }
    .ms-md-n1 {
      margin-left: -0.25rem !important;
    }
    .ms-md-n2 {
      margin-left: -0.5rem !important;
    }
    .ms-md-n3 {
      margin-left: -1rem !important;
    }
    .ms-md-n4 {
      margin-left: -1.8rem !important;
    }
    .ms-md-n5 {
      margin-left: -3rem !important;
    }
    .ms-md-n6 {
      margin-left: -4rem !important;
    }
    .ms-md-n7 {
      margin-left: -5rem !important;
    }
    .ms-md-n8 {
      margin-left: -7.5rem !important;
    }
    .ms-md-n9 {
      margin-left: -10rem !important;
    }
    .ms-md-n10 {
      margin-left: -12.5rem !important;
    }
    .ms-md-n11 {
      margin-left: -15rem !important;
    }
    .p-md-0 {
      padding: 0 !important;
    }
    .p-md-1 {
      padding: 0.25rem !important;
    }
    .p-md-2 {
      padding: 0.5rem !important;
    }
    .p-md-3 {
      padding: 1rem !important;
    }
    .p-md-4 {
      padding: 1.8rem !important;
    }
    .p-md-5 {
      padding: 3rem !important;
    }
    .p-md-6 {
      padding: 4rem !important;
    }
    .p-md-7 {
      padding: 5rem !important;
    }
    .p-md-8 {
      padding: 7.5rem !important;
    }
    .p-md-9 {
      padding: 10rem !important;
    }
    .p-md-10 {
      padding: 12.5rem !important;
    }
    .p-md-11 {
      padding: 15rem !important;
    }
    .px-md-0 {
      padding-right: 0 !important;
      padding-left: 0 !important;
    }
    .px-md-1 {
      padding-right: 0.25rem !important;
      padding-left: 0.25rem !important;
    }
    .px-md-2 {
      padding-right: 0.5rem !important;
      padding-left: 0.5rem !important;
    }
    .px-md-3 {
      padding-right: 1rem !important;
      padding-left: 1rem !important;
    }
    .px-md-4 {
      padding-right: 1.8rem !important;
      padding-left: 1.8rem !important;
    }
    .px-md-5 {
      padding-right: 3rem !important;
      padding-left: 3rem !important;
    }
    .px-md-6 {
      padding-right: 4rem !important;
      padding-left: 4rem !important;
    }
    .px-md-7 {
      padding-right: 5rem !important;
      padding-left: 5rem !important;
    }
    .px-md-8 {
      padding-right: 7.5rem !important;
      padding-left: 7.5rem !important;
    }
    .px-md-9 {
      padding-right: 10rem !important;
      padding-left: 10rem !important;
    }
    .px-md-10 {
      padding-right: 12.5rem !important;
      padding-left: 12.5rem !important;
    }
    .px-md-11 {
      padding-right: 15rem !important;
      padding-left: 15rem !important;
    }
    .py-md-0 {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
    .py-md-1 {
      padding-top: 0.25rem !important;
      padding-bottom: 0.25rem !important;
    }
    .py-md-2 {
      padding-top: 0.5rem !important;
      padding-bottom: 0.5rem !important;
    }
    .py-md-3 {
      padding-top: 1rem !important;
      padding-bottom: 1rem !important;
    }
    .py-md-4 {
      padding-top: 1.8rem !important;
      padding-bottom: 1.8rem !important;
    }
    .py-md-5 {
      padding-top: 3rem !important;
      padding-bottom: 3rem !important;
    }
    .py-md-6 {
      padding-top: 4rem !important;
      padding-bottom: 4rem !important;
    }
    .py-md-7 {
      padding-top: 5rem !important;
      padding-bottom: 5rem !important;
    }
    .py-md-8 {
      padding-top: 7.5rem !important;
      padding-bottom: 7.5rem !important;
    }
    .py-md-9 {
      padding-top: 10rem !important;
      padding-bottom: 10rem !important;
    }
    .py-md-10 {
      padding-top: 12.5rem !important;
      padding-bottom: 12.5rem !important;
    }
    .py-md-11 {
      padding-top: 15rem !important;
      padding-bottom: 15rem !important;
    }
    .pt-md-0 {
      padding-top: 0 !important;
    }
    .pt-md-1 {
      padding-top: 0.25rem !important;
    }
    .pt-md-2 {
      padding-top: 0.5rem !important;
    }
    .pt-md-3 {
      padding-top: 1rem !important;
    }
    .pt-md-4 {
      padding-top: 1.8rem !important;
    }
    .pt-md-5 {
      padding-top: 3rem !important;
    }
    .pt-md-6 {
      padding-top: 4rem !important;
    }
    .pt-md-7 {
      padding-top: 5rem !important;
    }
    .pt-md-8 {
      padding-top: 7.5rem !important;
    }
    .pt-md-9 {
      padding-top: 10rem !important;
    }
    .pt-md-10 {
      padding-top: 12.5rem !important;
    }
    .pt-md-11 {
      padding-top: 15rem !important;
    }
    .pe-md-0 {
      padding-right: 0 !important;
    }
    .pe-md-1 {
      padding-right: 0.25rem !important;
    }
    .pe-md-2 {
      padding-right: 0.5rem !important;
    }
    .pe-md-3 {
      padding-right: 1rem !important;
    }
    .pe-md-4 {
      padding-right: 1.8rem !important;
    }
    .pe-md-5 {
      padding-right: 3rem !important;
    }
    .pe-md-6 {
      padding-right: 4rem !important;
    }
    .pe-md-7 {
      padding-right: 5rem !important;
    }
    .pe-md-8 {
      padding-right: 7.5rem !important;
    }
    .pe-md-9 {
      padding-right: 10rem !important;
    }
    .pe-md-10 {
      padding-right: 12.5rem !important;
    }
    .pe-md-11 {
      padding-right: 15rem !important;
    }
    .pb-md-0 {
      padding-bottom: 0 !important;
    }
    .pb-md-1 {
      padding-bottom: 0.25rem !important;
    }
    .pb-md-2 {
      padding-bottom: 0.5rem !important;
    }
    .pb-md-3 {
      padding-bottom: 1rem !important;
    }
    .pb-md-4 {
      padding-bottom: 1.8rem !important;
    }
    .pb-md-5 {
      padding-bottom: 3rem !important;
    }
    .pb-md-6 {
      padding-bottom: 4rem !important;
    }
    .pb-md-7 {
      padding-bottom: 5rem !important;
    }
    .pb-md-8 {
      padding-bottom: 7.5rem !important;
    }
    .pb-md-9 {
      padding-bottom: 10rem !important;
    }
    .pb-md-10 {
      padding-bottom: 12.5rem !important;
    }
    .pb-md-11 {
      padding-bottom: 15rem !important;
    }
    .ps-md-0 {
      padding-left: 0 !important;
    }
    .ps-md-1 {
      padding-left: 0.25rem !important;
    }
    .ps-md-2 {
      padding-left: 0.5rem !important;
    }
    .ps-md-3 {
      padding-left: 1rem !important;
    }
    .ps-md-4 {
      padding-left: 1.8rem !important;
    }
    .ps-md-5 {
      padding-left: 3rem !important;
    }
    .ps-md-6 {
      padding-left: 4rem !important;
    }
    .ps-md-7 {
      padding-left: 5rem !important;
    }
    .ps-md-8 {
      padding-left: 7.5rem !important;
    }
    .ps-md-9 {
      padding-left: 10rem !important;
    }
    .ps-md-10 {
      padding-left: 12.5rem !important;
    }
    .ps-md-11 {
      padding-left: 15rem !important;
    }
    .fs-md--2 {
      font-size: 0.69444rem !important;
    }
    .fs-md--1 {
      font-size: 0.83333rem !important;
    }
    .fs-md-0 {
      font-size: 1rem !important;
    }
    .fs-md-1 {
      font-size: 1.2rem !important;
    }
    .fs-md-2 {
      font-size: 1.44rem !important;
    }
    .fs-md-3 {
      font-size: 1.728rem !important;
    }
    .fs-md-4 {
      font-size: 2.0736rem !important;
    }
    .fs-md-5 {
      font-size: 2.48832rem !important;
    }
    .fs-md-6 {
      font-size: 2.98598rem !important;
    }
    .fs-md-7 {
      font-size: 3.58318rem !important;
    }
    .fs-md-8 {
      font-size: 4.29982rem !important;
    }
    .text-md-start {
      text-align: left !important;
    }
    .text-md-end {
      text-align: right !important;
    }
    .text-md-center {
      text-align: center !important;
    }
    .rounded-md-top {
      border-top-left-radius: 0.5rem !important;
      border-top-right-radius: 0.5rem !important;
    }
    .rounded-md-top-lg {
      border-top-left-radius: 1rem !important;
      border-top-right-radius: 1rem !important;
    }
    .rounded-md-top-0 {
      border-top-left-radius: 0 !important;
      border-top-right-radius: 0 !important;
    }
    .rounded-md-end {
      border-top-right-radius: 0.5rem !important;
      border-bottom-right-radius: 0.5rem !important;
    }
    .rounded-md-end-lg {
      border-top-right-radius: 1rem !important;
      border-bottom-right-radius: 1rem !important;
    }
    .rounded-md-end-0 {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
    .rounded-md-bottom {
      border-bottom-right-radius: 0.5rem !important;
      border-bottom-left-radius: 0.5rem !important;
    }
    .rounded-md-bottom-lg {
      border-bottom-right-radius: 1rem !important;
      border-bottom-left-radius: 1rem !important;
    }
    .rounded-md-bottom-0 {
      border-bottom-right-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }
    .rounded-md-start {
      border-bottom-left-radius: 0.5rem !important;
      border-top-left-radius: 0.5rem !important;
    }
    .rounded-md-start-lg {
      border-bottom-left-radius: 1rem !important;
      border-top-left-radius: 1rem !important;
    }
    .rounded-md-start-0 {
      border-bottom-left-radius: 0 !important;
      border-top-left-radius: 0 !important;
    }
    .opacity-md-0 {
      opacity: 0 !important;
    }
    .opacity-md-25 {
      opacity: 0.25 !important;
    }
    .opacity-md-50 {
      opacity: 0.5 !important;
    }
    .opacity-md-75 {
      opacity: 0.75 !important;
    }
    .opacity-md-85 {
      opacity: 0.85 !important;
    }
    .opacity-md-100 {
      opacity: 1 !important;
    }
    .max-vh-md-25 {
      max-height: 25vh !important;
    }
    .max-vh-md-50 {
      max-height: 50vh !important;
    }
    .max-vh-md-75 {
      max-height: 75vh !important;
    }
    .max-vh-md-100 {
      max-height: 100vh !important;
    }
    .border-md-x {
      border-left: 1px solid #EEEEEE !important;
      border-right: 1px solid #EEEEEE !important;
    }
    .border-md-x-0 {
      border-left: 0 !important;
      border-right: 0 !important;
    }
    .border-md-y {
      border-top: 1px solid #EEEEEE !important;
      border-bottom: 1px solid #EEEEEE !important;
    }
    .border-md-y-0 {
      border-top: 0 !important;
      border-bottom: 0 !important;
    }
    .border-md-dashed {
      border: 1px dashed #EEEEEE !important;
    }
    .border-md-dashed-top {
      border-top: 1px dashed #EEEEEE !important;
    }
    .border-md-dashed-end {
      border-right: 1px dashed #EEEEEE !important;
    }
    .border-md-dashed-start {
      border-left: 1px dashed #EEEEEE !important;
    }
    .border-md-dashed-bottom {
      border-bottom: 1px dashed #EEEEEE !important;
    }
    .border-md-dashed-x {
      border-left: 1px dashed #EEEEEE !important;
      border-right: 1px dashed #EEEEEE !important;
    }
    .border-md-dashed-y {
      border-top: 1px dashed #EEEEEE !important;
      border-bottom: 1px dashed #EEEEEE !important;
    }
    .rounded-md-0 {
      border-radius: 0 !important;
    }
  }
  
  @media (min-width: 992px) {
    .float-lg-start {
      float: left !important;
    }
    .float-lg-end {
      float: right !important;
    }
    .float-lg-none {
      float: none !important;
    }
    .d-lg-inline {
      display: inline !important;
    }
    .d-lg-inline-block {
      display: inline-block !important;
    }
    .d-lg-block {
      display: block !important;
    }
    .d-lg-grid {
      display: grid !important;
    }
    .d-lg-table {
      display: table !important;
    }
    .d-lg-table-row {
      display: table-row !important;
    }
    .d-lg-table-cell {
      display: table-cell !important;
    }
    .d-lg-flex {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
    }
    .d-lg-inline-flex {
      display: -webkit-inline-box !important;
      display: -ms-inline-flexbox !important;
      display: inline-flex !important;
    }
    .d-lg-none {
      display: none !important;
    }
    .position-lg-static {
      position: static !important;
    }
    .position-lg-absolute {
      position: absolute !important;
    }
    .position-lg-relative {
      position: relative !important;
    }
    .position-lg-fixed {
      position: fixed !important;
    }
    .position-lg-sticky {
      position: -webkit-sticky !important;
      position: sticky !important;
    }
    .translate-lg-middle {
      -webkit-transform: translateX(-50%) translateY(-50%) !important;
      -ms-transform: translateX(-50%) translateY(-50%) !important;
      transform: translateX(-50%) translateY(-50%) !important;
    }
    .translate-lg-middle-x {
      -webkit-transform: translateX(-50%) !important;
      -ms-transform: translateX(-50%) !important;
      transform: translateX(-50%) !important;
    }
    .translate-lg-middle-y {
      -webkit-transform: translateY(-50%) !important;
      -ms-transform: translateY(-50%) !important;
      transform: translateY(-50%) !important;
    }
    .border-lg {
      border: 1px solid #EEEEEE !important;
    }
    .border-lg-0 {
      border: 0 !important;
    }
    .border-lg-top {
      border-top: 1px solid #EEEEEE !important;
    }
    .border-lg-top-0 {
      border-top: 0 !important;
    }
    .border-lg-end {
      border-right: 1px solid #EEEEEE !important;
    }
    .border-lg-end-0 {
      border-right: 0 !important;
    }
    .border-lg-bottom {
      border-bottom: 1px solid #EEEEEE !important;
    }
    .border-lg-bottom-0 {
      border-bottom: 0 !important;
    }
    .border-lg-start {
      border-left: 1px solid #EEEEEE !important;
    }
    .border-lg-start-0 {
      border-left: 0 !important;
    }
    .border-lg-facebook {
      border-color: #3c5a99 !important;
    }
    .border-lg-google-plus {
      border-color: #dd4b39 !important;
    }
    .border-lg-twitter {
      border-color: #1da1f2 !important;
    }
    .border-lg-linkedin {
      border-color: #0077b5 !important;
    }
    .border-lg-youtube {
      border-color: #ff0000 !important;
    }
    .border-lg-github {
      border-color: #333 !important;
    }
    .border-lg-black {
      border-color: #000 !important;
    }
    .border-lg-100 {
      border-color: #FCFCFC !important;
    }
    .border-lg-200 {
      border-color: #F5F5F5 !important;
    }
    .border-lg-300 {
      border-color: #EEEEEE !important;
    }
    .border-lg-400 {
      border-color: #E0E0E0 !important;
    }
    .border-lg-500 {
      border-color: #BDBDBD !important;
    }
    .border-lg-600 {
      border-color: #9E9E9E !important;
    }
    .border-lg-700 {
      border-color: #757575 !important;
    }
    .border-lg-800 {
      border-color: #616161 !important;
    }
    .border-lg-900 {
      border-color: #424242 !important;
    }
    .border-lg-1000 {
      border-color: #212121 !important;
    }
    .border-lg-white {
      border-color: #FFFEFE !important;
    }
    .border-lg-primary {
      border-color: #FFB30E !important;
    }
    .border-lg-secondary {
      border-color: #2780ED !important;
    }
    .border-lg-success {
      border-color: #79B93C !important;
    }
    .border-lg-info {
      border-color: #24FFEA !important;
    }
    .border-lg-warning {
      border-color: #FFB30E !important;
    }
    .border-lg-danger {
      border-color: #F17228 !important;
    }
    .border-lg-light {
      border-color: #F9FAFD !important;
    }
    .border-lg-dark {
      border-color: #212121 !important;
    }
    .w-lg-25 {
      width: 25% !important;
    }
    .w-lg-50 {
      width: 50% !important;
    }
    .w-lg-75 {
      width: 75% !important;
    }
    .w-lg-100 {
      width: 100% !important;
    }
    .w-lg-auto {
      width: auto !important;
    }
    .vw-lg-25 {
      width: 25vw !important;
    }
    .vw-lg-50 {
      width: 50vw !important;
    }
    .vw-lg-75 {
      width: 75vw !important;
    }
    .vw-lg-100 {
      width: 100vw !important;
    }
    .h-lg-25 {
      height: 25% !important;
    }
    .h-lg-50 {
      height: 50% !important;
    }
    .h-lg-75 {
      height: 75% !important;
    }
    .h-lg-100 {
      height: 100% !important;
    }
    .h-lg-auto {
      height: auto !important;
    }
    .vh-lg-25 {
      height: 25vh !important;
    }
    .vh-lg-50 {
      height: 50vh !important;
    }
    .vh-lg-75 {
      height: 75vh !important;
    }
    .vh-lg-100 {
      height: 100vh !important;
    }
    .min-vh-lg-25 {
      min-height: 25vh !important;
    }
    .min-vh-lg-50 {
      min-height: 50vh !important;
    }
    .min-vh-lg-75 {
      min-height: 75vh !important;
    }
    .min-vh-lg-100 {
      min-height: 100vh !important;
    }
    .flex-lg-fill {
      -webkit-box-flex: 1 !important;
      -ms-flex: 1 1 auto !important;
      flex: 1 1 auto !important;
    }
    .flex-lg-row {
      -webkit-box-orient: horizontal !important;
      -webkit-box-direction: normal !important;
      -ms-flex-direction: row !important;
      flex-direction: row !important;
    }
    .flex-lg-column {
      -webkit-box-orient: vertical !important;
      -webkit-box-direction: normal !important;
      -ms-flex-direction: column !important;
      flex-direction: column !important;
    }
    .flex-lg-row-reverse {
      -webkit-box-orient: horizontal !important;
      -webkit-box-direction: reverse !important;
      -ms-flex-direction: row-reverse !important;
      flex-direction: row-reverse !important;
    }
    .flex-lg-column-reverse {
      -webkit-box-orient: vertical !important;
      -webkit-box-direction: reverse !important;
      -ms-flex-direction: column-reverse !important;
      flex-direction: column-reverse !important;
    }
    .flex-lg-grow-0 {
      -webkit-box-flex: 0 !important;
      -ms-flex-positive: 0 !important;
      flex-grow: 0 !important;
    }
    .flex-lg-grow-1 {
      -webkit-box-flex: 1 !important;
      -ms-flex-positive: 1 !important;
      flex-grow: 1 !important;
    }
    .flex-lg-shrink-0 {
      -ms-flex-negative: 0 !important;
      flex-shrink: 0 !important;
    }
    .flex-lg-shrink-1 {
      -ms-flex-negative: 1 !important;
      flex-shrink: 1 !important;
    }
    .flex-lg-wrap {
      -ms-flex-wrap: wrap !important;
      flex-wrap: wrap !important;
    }
    .flex-lg-nowrap {
      -ms-flex-wrap: nowrap !important;
      flex-wrap: nowrap !important;
    }
    .flex-lg-wrap-reverse {
      -ms-flex-wrap: wrap-reverse !important;
      flex-wrap: wrap-reverse !important;
    }
    .gap-lg-0 {
      gap: 0 !important;
    }
    .gap-lg-1 {
      gap: 0.25rem !important;
    }
    .gap-lg-2 {
      gap: 0.5rem !important;
    }
    .gap-lg-3 {
      gap: 1rem !important;
    }
    .gap-lg-4 {
      gap: 1.8rem !important;
    }
    .gap-lg-5 {
      gap: 3rem !important;
    }
    .gap-lg-6 {
      gap: 4rem !important;
    }
    .gap-lg-7 {
      gap: 5rem !important;
    }
    .gap-lg-8 {
      gap: 7.5rem !important;
    }
    .gap-lg-9 {
      gap: 10rem !important;
    }
    .gap-lg-10 {
      gap: 12.5rem !important;
    }
    .gap-lg-11 {
      gap: 15rem !important;
    }
    .justify-content-lg-start {
      -webkit-box-pack: start !important;
      -ms-flex-pack: start !important;
      justify-content: flex-start !important;
    }
    .justify-content-lg-end {
      -webkit-box-pack: end !important;
      -ms-flex-pack: end !important;
      justify-content: flex-end !important;
    }
    .justify-content-lg-center {
      -webkit-box-pack: center !important;
      -ms-flex-pack: center !important;
      justify-content: center !important;
    }
    .justify-content-lg-between {
      -webkit-box-pack: justify !important;
      -ms-flex-pack: justify !important;
      justify-content: space-between !important;
    }
    .justify-content-lg-around {
      -ms-flex-pack: distribute !important;
      justify-content: space-around !important;
    }
    .justify-content-lg-evenly {
      -webkit-box-pack: space-evenly !important;
      -ms-flex-pack: space-evenly !important;
      justify-content: space-evenly !important;
    }
    .align-items-lg-start {
      -webkit-box-align: start !important;
      -ms-flex-align: start !important;
      align-items: flex-start !important;
    }
    .align-items-lg-end {
      -webkit-box-align: end !important;
      -ms-flex-align: end !important;
      align-items: flex-end !important;
    }
    .align-items-lg-center {
      -webkit-box-align: center !important;
      -ms-flex-align: center !important;
      align-items: center !important;
    }
    .align-items-lg-baseline {
      -webkit-box-align: baseline !important;
      -ms-flex-align: baseline !important;
      align-items: baseline !important;
    }
    .align-items-lg-stretch {
      -webkit-box-align: stretch !important;
      -ms-flex-align: stretch !important;
      align-items: stretch !important;
    }
    .align-content-lg-start {
      -ms-flex-line-pack: start !important;
      align-content: flex-start !important;
    }
    .align-content-lg-end {
      -ms-flex-line-pack: end !important;
      align-content: flex-end !important;
    }
    .align-content-lg-center {
      -ms-flex-line-pack: center !important;
      align-content: center !important;
    }
    .align-content-lg-between {
      -ms-flex-line-pack: justify !important;
      align-content: space-between !important;
    }
    .align-content-lg-around {
      -ms-flex-line-pack: distribute !important;
      align-content: space-around !important;
    }
    .align-content-lg-stretch {
      -ms-flex-line-pack: stretch !important;
      align-content: stretch !important;
    }
    .align-self-lg-auto {
      -ms-flex-item-align: auto !important;
      align-self: auto !important;
    }
    .align-self-lg-start {
      -ms-flex-item-align: start !important;
      align-self: flex-start !important;
    }
    .align-self-lg-end {
      -ms-flex-item-align: end !important;
      align-self: flex-end !important;
    }
    .align-self-lg-center {
      -ms-flex-item-align: center !important;
      align-self: center !important;
    }
    .align-self-lg-baseline {
      -ms-flex-item-align: baseline !important;
      align-self: baseline !important;
    }
    .align-self-lg-stretch {
      -ms-flex-item-align: stretch !important;
      align-self: stretch !important;
    }
    .order-lg-first {
      -webkit-box-ordinal-group: 0 !important;
      -ms-flex-order: -1 !important;
      order: -1 !important;
    }
    .order-lg-0 {
      -webkit-box-ordinal-group: 1 !important;
      -ms-flex-order: 0 !important;
      order: 0 !important;
    }
    .order-lg-1 {
      -webkit-box-ordinal-group: 2 !important;
      -ms-flex-order: 1 !important;
      order: 1 !important;
    }
    .order-lg-2 {
      -webkit-box-ordinal-group: 3 !important;
      -ms-flex-order: 2 !important;
      order: 2 !important;
    }
    .order-lg-3 {
      -webkit-box-ordinal-group: 4 !important;
      -ms-flex-order: 3 !important;
      order: 3 !important;
    }
    .order-lg-4 {
      -webkit-box-ordinal-group: 5 !important;
      -ms-flex-order: 4 !important;
      order: 4 !important;
    }
    .order-lg-5 {
      -webkit-box-ordinal-group: 6 !important;
      -ms-flex-order: 5 !important;
      order: 5 !important;
    }
    .order-lg-last {
      -webkit-box-ordinal-group: 7 !important;
      -ms-flex-order: 6 !important;
      order: 6 !important;
    }
    .m-lg-0 {
      margin: 0 !important;
    }
    .m-lg-1 {
      margin: 0.25rem !important;
    }
    .m-lg-2 {
      margin: 0.5rem !important;
    }
    .m-lg-3 {
      margin: 1rem !important;
    }
    .m-lg-4 {
      margin: 1.8rem !important;
    }
    .m-lg-5 {
      margin: 3rem !important;
    }
    .m-lg-6 {
      margin: 4rem !important;
    }
    .m-lg-7 {
      margin: 5rem !important;
    }
    .m-lg-8 {
      margin: 7.5rem !important;
    }
    .m-lg-9 {
      margin: 10rem !important;
    }
    .m-lg-10 {
      margin: 12.5rem !important;
    }
    .m-lg-11 {
      margin: 15rem !important;
    }
    .m-lg-auto {
      margin: auto !important;
    }
    .mx-lg-0 {
      margin-right: 0 !important;
      margin-left: 0 !important;
    }
    .mx-lg-1 {
      margin-right: 0.25rem !important;
      margin-left: 0.25rem !important;
    }
    .mx-lg-2 {
      margin-right: 0.5rem !important;
      margin-left: 0.5rem !important;
    }
    .mx-lg-3 {
      margin-right: 1rem !important;
      margin-left: 1rem !important;
    }
    .mx-lg-4 {
      margin-right: 1.8rem !important;
      margin-left: 1.8rem !important;
    }
    .mx-lg-5 {
      margin-right: 3rem !important;
      margin-left: 3rem !important;
    }
    .mx-lg-6 {
      margin-right: 4rem !important;
      margin-left: 4rem !important;
    }
    .mx-lg-7 {
      margin-right: 5rem !important;
      margin-left: 5rem !important;
    }
    .mx-lg-8 {
      margin-right: 7.5rem !important;
      margin-left: 7.5rem !important;
    }
    .mx-lg-9 {
      margin-right: 10rem !important;
      margin-left: 10rem !important;
    }
    .mx-lg-10 {
      margin-right: 12.5rem !important;
      margin-left: 12.5rem !important;
    }
    .mx-lg-11 {
      margin-right: 15rem !important;
      margin-left: 15rem !important;
    }
    .mx-lg-auto {
      margin-right: auto !important;
      margin-left: auto !important;
    }
    .my-lg-0 {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }
    .my-lg-1 {
      margin-top: 0.25rem !important;
      margin-bottom: 0.25rem !important;
    }
    .my-lg-2 {
      margin-top: 0.5rem !important;
      margin-bottom: 0.5rem !important;
    }
    .my-lg-3 {
      margin-top: 1rem !important;
      margin-bottom: 1rem !important;
    }
    .my-lg-4 {
      margin-top: 1.8rem !important;
      margin-bottom: 1.8rem !important;
    }
    .my-lg-5 {
      margin-top: 3rem !important;
      margin-bottom: 3rem !important;
    }
    .my-lg-6 {
      margin-top: 4rem !important;
      margin-bottom: 4rem !important;
    }
    .my-lg-7 {
      margin-top: 5rem !important;
      margin-bottom: 5rem !important;
    }
    .my-lg-8 {
      margin-top: 7.5rem !important;
      margin-bottom: 7.5rem !important;
    }
    .my-lg-9 {
      margin-top: 10rem !important;
      margin-bottom: 10rem !important;
    }
    .my-lg-10 {
      margin-top: 12.5rem !important;
      margin-bottom: 12.5rem !important;
    }
    .my-lg-11 {
      margin-top: 15rem !important;
      margin-bottom: 15rem !important;
    }
    .my-lg-auto {
      margin-top: auto !important;
      margin-bottom: auto !important;
    }
    .mt-lg-0 {
      margin-top: 0 !important;
    }
    .mt-lg-1 {
      margin-top: 0.25rem !important;
    }
    .mt-lg-2 {
      margin-top: 0.5rem !important;
    }
    .mt-lg-3 {
      margin-top: 1rem !important;
    }
    .mt-lg-4 {
      margin-top: 1.8rem !important;
    }
    .mt-lg-5 {
      margin-top: 3rem !important;
    }
    .mt-lg-6 {
      margin-top: 4rem !important;
    }
    .mt-lg-7 {
      margin-top: 5rem !important;
    }
    .mt-lg-8 {
      margin-top: 7.5rem !important;
    }
    .mt-lg-9 {
      margin-top: 10rem !important;
    }
    .mt-lg-10 {
      margin-top: 12.5rem !important;
    }
    .mt-lg-11 {
      margin-top: 15rem !important;
    }
    .mt-lg-auto {
      margin-top: auto !important;
    }
    .me-lg-0 {
      margin-right: 0 !important;
    }
    .me-lg-1 {
      margin-right: 0.25rem !important;
    }
    .me-lg-2 {
      margin-right: 0.5rem !important;
    }
    .me-lg-3 {
      margin-right: 1rem !important;
    }
    .me-lg-4 {
      margin-right: 1.8rem !important;
    }
    .me-lg-5 {
      margin-right: 3rem !important;
    }
    .me-lg-6 {
      margin-right: 4rem !important;
    }
    .me-lg-7 {
      margin-right: 5rem !important;
    }
    .me-lg-8 {
      margin-right: 7.5rem !important;
    }
    .me-lg-9 {
      margin-right: 10rem !important;
    }
    .me-lg-10 {
      margin-right: 12.5rem !important;
    }
    .me-lg-11 {
      margin-right: 15rem !important;
    }
    .me-lg-auto {
      margin-right: auto !important;
    }
    .mb-lg-0 {
      margin-bottom: 0 !important;
    }
    .mb-lg-1 {
      margin-bottom: 0.25rem !important;
    }
    .mb-lg-2 {
      margin-bottom: 0.5rem !important;
    }
    .mb-lg-3 {
      margin-bottom: 1rem !important;
    }
    .mb-lg-4 {
      margin-bottom: 1.8rem !important;
    }
    .mb-lg-5 {
      margin-bottom: 3rem !important;
    }
    .mb-lg-6 {
      margin-bottom: 4rem !important;
    }
    .mb-lg-7 {
      margin-bottom: 5rem !important;
    }
    .mb-lg-8 {
      margin-bottom: 7.5rem !important;
    }
    .mb-lg-9 {
      margin-bottom: 10rem !important;
    }
    .mb-lg-10 {
      margin-bottom: 12.5rem !important;
    }
    .mb-lg-11 {
      margin-bottom: 15rem !important;
    }
    .mb-lg-auto {
      margin-bottom: auto !important;
    }
    .ms-lg-0 {
      margin-left: 0 !important;
    }
    .ms-lg-1 {
      margin-left: 0.25rem !important;
    }
    .ms-lg-2 {
      margin-left: 0.5rem !important;
    }
    .ms-lg-3 {
      margin-left: 1rem !important;
    }
    .ms-lg-4 {
      margin-left: 1.8rem !important;
    }
    .ms-lg-5 {
      margin-left: 3rem !important;
    }
    .ms-lg-6 {
      margin-left: 4rem !important;
    }
    .ms-lg-7 {
      margin-left: 5rem !important;
    }
    .ms-lg-8 {
      margin-left: 7.5rem !important;
    }
    .ms-lg-9 {
      margin-left: 10rem !important;
    }
    .ms-lg-10 {
      margin-left: 12.5rem !important;
    }
    .ms-lg-11 {
      margin-left: 15rem !important;
    }
    .ms-lg-auto {
      margin-left: auto !important;
    }
    .m-lg-n1 {
      margin: -0.25rem !important;
    }
    .m-lg-n2 {
      margin: -0.5rem !important;
    }
    .m-lg-n3 {
      margin: -1rem !important;
    }
    .m-lg-n4 {
      margin: -1.8rem !important;
    }
    .m-lg-n5 {
      margin: -3rem !important;
    }
    .m-lg-n6 {
      margin: -4rem !important;
    }
    .m-lg-n7 {
      margin: -5rem !important;
    }
    .m-lg-n8 {
      margin: -7.5rem !important;
    }
    .m-lg-n9 {
      margin: -10rem !important;
    }
    .m-lg-n10 {
      margin: -12.5rem !important;
    }
    .m-lg-n11 {
      margin: -15rem !important;
    }
    .mx-lg-n1 {
      margin-right: -0.25rem !important;
      margin-left: -0.25rem !important;
    }
    .mx-lg-n2 {
      margin-right: -0.5rem !important;
      margin-left: -0.5rem !important;
    }
    .mx-lg-n3 {
      margin-right: -1rem !important;
      margin-left: -1rem !important;
    }
    .mx-lg-n4 {
      margin-right: -1.8rem !important;
      margin-left: -1.8rem !important;
    }
    .mx-lg-n5 {
      margin-right: -3rem !important;
      margin-left: -3rem !important;
    }
    .mx-lg-n6 {
      margin-right: -4rem !important;
      margin-left: -4rem !important;
    }
    .mx-lg-n7 {
      margin-right: -5rem !important;
      margin-left: -5rem !important;
    }
    .mx-lg-n8 {
      margin-right: -7.5rem !important;
      margin-left: -7.5rem !important;
    }
    .mx-lg-n9 {
      margin-right: -10rem !important;
      margin-left: -10rem !important;
    }
    .mx-lg-n10 {
      margin-right: -12.5rem !important;
      margin-left: -12.5rem !important;
    }
    .mx-lg-n11 {
      margin-right: -15rem !important;
      margin-left: -15rem !important;
    }
    .my-lg-n1 {
      margin-top: -0.25rem !important;
      margin-bottom: -0.25rem !important;
    }
    .my-lg-n2 {
      margin-top: -0.5rem !important;
      margin-bottom: -0.5rem !important;
    }
    .my-lg-n3 {
      margin-top: -1rem !important;
      margin-bottom: -1rem !important;
    }
    .my-lg-n4 {
      margin-top: -1.8rem !important;
      margin-bottom: -1.8rem !important;
    }
    .my-lg-n5 {
      margin-top: -3rem !important;
      margin-bottom: -3rem !important;
    }
    .my-lg-n6 {
      margin-top: -4rem !important;
      margin-bottom: -4rem !important;
    }
    .my-lg-n7 {
      margin-top: -5rem !important;
      margin-bottom: -5rem !important;
    }
    .my-lg-n8 {
      margin-top: -7.5rem !important;
      margin-bottom: -7.5rem !important;
    }
    .my-lg-n9 {
      margin-top: -10rem !important;
      margin-bottom: -10rem !important;
    }
    .my-lg-n10 {
      margin-top: -12.5rem !important;
      margin-bottom: -12.5rem !important;
    }
    .my-lg-n11 {
      margin-top: -15rem !important;
      margin-bottom: -15rem !important;
    }
    .mt-lg-n1 {
      margin-top: -0.25rem !important;
    }
    .mt-lg-n2 {
      margin-top: -0.5rem !important;
    }
    .mt-lg-n3 {
      margin-top: -1rem !important;
    }
    .mt-lg-n4 {
      margin-top: -1.8rem !important;
    }
    .mt-lg-n5 {
      margin-top: -3rem !important;
    }
    .mt-lg-n6 {
      margin-top: -4rem !important;
    }
    .mt-lg-n7 {
      margin-top: -5rem !important;
    }
    .mt-lg-n8 {
      margin-top: -7.5rem !important;
    }
    .mt-lg-n9 {
      margin-top: -10rem !important;
    }
    .mt-lg-n10 {
      margin-top: -12.5rem !important;
    }
    .mt-lg-n11 {
      margin-top: -15rem !important;
    }
    .me-lg-n1 {
      margin-right: -0.25rem !important;
    }
    .me-lg-n2 {
      margin-right: -0.5rem !important;
    }
    .me-lg-n3 {
      margin-right: -1rem !important;
    }
    .me-lg-n4 {
      margin-right: -1.8rem !important;
    }
    .me-lg-n5 {
      margin-right: -3rem !important;
    }
    .me-lg-n6 {
      margin-right: -4rem !important;
    }
    .me-lg-n7 {
      margin-right: -5rem !important;
    }
    .me-lg-n8 {
      margin-right: -7.5rem !important;
    }
    .me-lg-n9 {
      margin-right: -10rem !important;
    }
    .me-lg-n10 {
      margin-right: -12.5rem !important;
    }
    .me-lg-n11 {
      margin-right: -15rem !important;
    }
    .mb-lg-n1 {
      margin-bottom: -0.25rem !important;
    }
    .mb-lg-n2 {
      margin-bottom: -0.5rem !important;
    }
    .mb-lg-n3 {
      margin-bottom: -1rem !important;
    }
    .mb-lg-n4 {
      margin-bottom: -1.8rem !important;
    }
    .mb-lg-n5 {
      margin-bottom: -3rem !important;
    }
    .mb-lg-n6 {
      margin-bottom: -4rem !important;
    }
    .mb-lg-n7 {
      margin-bottom: -5rem !important;
    }
    .mb-lg-n8 {
      margin-bottom: -7.5rem !important;
    }
    .mb-lg-n9 {
      margin-bottom: -10rem !important;
    }
    .mb-lg-n10 {
      margin-bottom: -12.5rem !important;
    }
    .mb-lg-n11 {
      margin-bottom: -15rem !important;
    }
    .ms-lg-n1 {
      margin-left: -0.25rem !important;
    }
    .ms-lg-n2 {
      margin-left: -0.5rem !important;
    }
    .ms-lg-n3 {
      margin-left: -1rem !important;
    }
    .ms-lg-n4 {
      margin-left: -1.8rem !important;
    }
    .ms-lg-n5 {
      margin-left: -3rem !important;
    }
    .ms-lg-n6 {
      margin-left: -4rem !important;
    }
    .ms-lg-n7 {
      margin-left: -5rem !important;
    }
    .ms-lg-n8 {
      margin-left: -7.5rem !important;
    }
    .ms-lg-n9 {
      margin-left: -10rem !important;
    }
    .ms-lg-n10 {
      margin-left: -12.5rem !important;
    }
    .ms-lg-n11 {
      margin-left: -15rem !important;
    }
    .p-lg-0 {
      padding: 0 !important;
    }
    .p-lg-1 {
      padding: 0.25rem !important;
    }
    .p-lg-2 {
      padding: 0.5rem !important;
    }
    .p-lg-3 {
      padding: 1rem !important;
    }
    .p-lg-4 {
      padding: 1.8rem !important;
    }
    .p-lg-5 {
      padding: 3rem !important;
    }
    .p-lg-6 {
      padding: 4rem !important;
    }
    .p-lg-7 {
      padding: 5rem !important;
    }
    .p-lg-8 {
      padding: 7.5rem !important;
    }
    .p-lg-9 {
      padding: 10rem !important;
    }
    .p-lg-10 {
      padding: 12.5rem !important;
    }
    .p-lg-11 {
      padding: 15rem !important;
    }
    .px-lg-0 {
      padding-right: 0 !important;
      padding-left: 0 !important;
    }
    .px-lg-1 {
      padding-right: 0.25rem !important;
      padding-left: 0.25rem !important;
    }
    .px-lg-2 {
      padding-right: 0.5rem !important;
      padding-left: 0.5rem !important;
    }
    .px-lg-3 {
      padding-right: 1rem !important;
      padding-left: 1rem !important;
    }
    .px-lg-4 {
      padding-right: 1.8rem !important;
      padding-left: 1.8rem !important;
    }
    .px-lg-5 {
      padding-right: 3rem !important;
      padding-left: 3rem !important;
    }
    .px-lg-6 {
      padding-right: 4rem !important;
      padding-left: 4rem !important;
    }
    .px-lg-7 {
      padding-right: 5rem !important;
      padding-left: 5rem !important;
    }
    .px-lg-8 {
      padding-right: 7.5rem !important;
      padding-left: 7.5rem !important;
    }
    .px-lg-9 {
      padding-right: 10rem !important;
      padding-left: 10rem !important;
    }
    .px-lg-10 {
      padding-right: 12.5rem !important;
      padding-left: 12.5rem !important;
    }
    .px-lg-11 {
      padding-right: 15rem !important;
      padding-left: 15rem !important;
    }
    .py-lg-0 {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
    .py-lg-1 {
      padding-top: 0.25rem !important;
      padding-bottom: 0.25rem !important;
    }
    .py-lg-2 {
      padding-top: 0.5rem !important;
      padding-bottom: 0.5rem !important;
    }
    .py-lg-3 {
      padding-top: 1rem !important;
      padding-bottom: 1rem !important;
    }
    .py-lg-4 {
      padding-top: 1.8rem !important;
      padding-bottom: 1.8rem !important;
    }
    .py-lg-5 {
      padding-top: 3rem !important;
      padding-bottom: 3rem !important;
    }
    .py-lg-6 {
      padding-top: 4rem !important;
      padding-bottom: 4rem !important;
    }
    .py-lg-7 {
      padding-top: 5rem !important;
      padding-bottom: 5rem !important;
    }
    .py-lg-8 {
      padding-top: 7.5rem !important;
      padding-bottom: 7.5rem !important;
    }
    .py-lg-9 {
      padding-top: 10rem !important;
      padding-bottom: 10rem !important;
    }
    .py-lg-10 {
      padding-top: 12.5rem !important;
      padding-bottom: 12.5rem !important;
    }
    .py-lg-11 {
      padding-top: 15rem !important;
      padding-bottom: 15rem !important;
    }
    .pt-lg-0 {
      padding-top: 0 !important;
    }
    .pt-lg-1 {
      padding-top: 0.25rem !important;
    }
    .pt-lg-2 {
      padding-top: 0.5rem !important;
    }
    .pt-lg-3 {
      padding-top: 1rem !important;
    }
    .pt-lg-4 {
      padding-top: 1.8rem !important;
    }
    .pt-lg-5 {
      padding-top: 3rem !important;
    }
    .pt-lg-6 {
      padding-top: 4rem !important;
    }
    .pt-lg-7 {
      padding-top: 5rem !important;
    }
    .pt-lg-8 {
      padding-top: 7.5rem !important;
    }
    .pt-lg-9 {
      padding-top: 10rem !important;
    }
    .pt-lg-10 {
      padding-top: 12.5rem !important;
    }
    .pt-lg-11 {
      padding-top: 15rem !important;
    }
    .pe-lg-0 {
      padding-right: 0 !important;
    }
    .pe-lg-1 {
      padding-right: 0.25rem !important;
    }
    .pe-lg-2 {
      padding-right: 0.5rem !important;
    }
    .pe-lg-3 {
      padding-right: 1rem !important;
    }
    .pe-lg-4 {
      padding-right: 1.8rem !important;
    }
    .pe-lg-5 {
      padding-right: 3rem !important;
    }
    .pe-lg-6 {
      padding-right: 4rem !important;
    }
    .pe-lg-7 {
      padding-right: 5rem !important;
    }
    .pe-lg-8 {
      padding-right: 7.5rem !important;
    }
    .pe-lg-9 {
      padding-right: 10rem !important;
    }
    .pe-lg-10 {
      padding-right: 12.5rem !important;
    }
    .pe-lg-11 {
      padding-right: 15rem !important;
    }
    .pb-lg-0 {
      padding-bottom: 0 !important;
    }
    .pb-lg-1 {
      padding-bottom: 0.25rem !important;
    }
    .pb-lg-2 {
      padding-bottom: 0.5rem !important;
    }
    .pb-lg-3 {
      padding-bottom: 1rem !important;
    }
    .pb-lg-4 {
      padding-bottom: 1.8rem !important;
    }
    .pb-lg-5 {
      padding-bottom: 3rem !important;
    }
    .pb-lg-6 {
      padding-bottom: 4rem !important;
    }
    .pb-lg-7 {
      padding-bottom: 5rem !important;
    }
    .pb-lg-8 {
      padding-bottom: 7.5rem !important;
    }
    .pb-lg-9 {
      padding-bottom: 10rem !important;
    }
    .pb-lg-10 {
      padding-bottom: 12.5rem !important;
    }
    .pb-lg-11 {
      padding-bottom: 15rem !important;
    }
    .ps-lg-0 {
      padding-left: 0 !important;
    }
    .ps-lg-1 {
      padding-left: 0.25rem !important;
    }
    .ps-lg-2 {
      padding-left: 0.5rem !important;
    }
    .ps-lg-3 {
      padding-left: 1rem !important;
    }
    .ps-lg-4 {
      padding-left: 1.8rem !important;
    }
    .ps-lg-5 {
      padding-left: 3rem !important;
    }
    .ps-lg-6 {
      padding-left: 4rem !important;
    }
    .ps-lg-7 {
      padding-left: 5rem !important;
    }
    .ps-lg-8 {
      padding-left: 7.5rem !important;
    }
    .ps-lg-9 {
      padding-left: 10rem !important;
    }
    .ps-lg-10 {
      padding-left: 12.5rem !important;
    }
    .ps-lg-11 {
      padding-left: 15rem !important;
    }
    .fs-lg--2 {
      font-size: 0.69444rem !important;
    }
    .fs-lg--1 {
      font-size: 0.83333rem !important;
    }
    .fs-lg-0 {
      font-size: 1rem !important;
    }
    .fs-lg-1 {
      font-size: 1.2rem !important;
    }
    .fs-lg-2 {
      font-size: 1.44rem !important;
    }
    .fs-lg-3 {
      font-size: 1.728rem !important;
    }
    .fs-lg-4 {
      font-size: 2.0736rem !important;
    }
    .fs-lg-5 {
      font-size: 2.48832rem !important;
    }
    .fs-lg-6 {
      font-size: 2.98598rem !important;
    }
    .fs-lg-7 {
      font-size: 3.58318rem !important;
    }
    .fs-lg-8 {
      font-size: 4.29982rem !important;
    }
    .text-lg-start {
      text-align: left !important;
    }
    .text-lg-end {
      text-align: right !important;
    }
    .text-lg-center {
      text-align: center !important;
    }
    .rounded-lg-top {
      border-top-left-radius: 0.5rem !important;
      border-top-right-radius: 0.5rem !important;
    }
    .rounded-lg-top-lg {
      border-top-left-radius: 1rem !important;
      border-top-right-radius: 1rem !important;
    }
    .rounded-lg-top-0 {
      border-top-left-radius: 0 !important;
      border-top-right-radius: 0 !important;
    }
    .rounded-lg-end {
      border-top-right-radius: 0.5rem !important;
      border-bottom-right-radius: 0.5rem !important;
    }
    .rounded-lg-end-lg {
      border-top-right-radius: 1rem !important;
      border-bottom-right-radius: 1rem !important;
    }
    .rounded-lg-end-0 {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
    .rounded-lg-bottom {
      border-bottom-right-radius: 0.5rem !important;
      border-bottom-left-radius: 0.5rem !important;
    }
    .rounded-lg-bottom-lg {
      border-bottom-right-radius: 1rem !important;
      border-bottom-left-radius: 1rem !important;
    }
    .rounded-lg-bottom-0 {
      border-bottom-right-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }
    .rounded-lg-start {
      border-bottom-left-radius: 0.5rem !important;
      border-top-left-radius: 0.5rem !important;
    }
    .rounded-lg-start-lg {
      border-bottom-left-radius: 1rem !important;
      border-top-left-radius: 1rem !important;
    }
    .rounded-lg-start-0 {
      border-bottom-left-radius: 0 !important;
      border-top-left-radius: 0 !important;
    }
    .opacity-lg-0 {
      opacity: 0 !important;
    }
    .opacity-lg-25 {
      opacity: 0.25 !important;
    }
    .opacity-lg-50 {
      opacity: 0.5 !important;
    }
    .opacity-lg-75 {
      opacity: 0.75 !important;
    }
    .opacity-lg-85 {
      opacity: 0.85 !important;
    }
    .opacity-lg-100 {
      opacity: 1 !important;
    }
    .max-vh-lg-25 {
      max-height: 25vh !important;
    }
    .max-vh-lg-50 {
      max-height: 50vh !important;
    }
    .max-vh-lg-75 {
      max-height: 75vh !important;
    }
    .max-vh-lg-100 {
      max-height: 100vh !important;
    }
    .border-lg-x {
      border-left: 1px solid #EEEEEE !important;
      border-right: 1px solid #EEEEEE !important;
    }
    .border-lg-x-0 {
      border-left: 0 !important;
      border-right: 0 !important;
    }
    .border-lg-y {
      border-top: 1px solid #EEEEEE !important;
      border-bottom: 1px solid #EEEEEE !important;
    }
    .border-lg-y-0 {
      border-top: 0 !important;
      border-bottom: 0 !important;
    }
    .border-lg-dashed {
      border: 1px dashed #EEEEEE !important;
    }
    .border-lg-dashed-top {
      border-top: 1px dashed #EEEEEE !important;
    }
    .border-lg-dashed-end {
      border-right: 1px dashed #EEEEEE !important;
    }
    .border-lg-dashed-start {
      border-left: 1px dashed #EEEEEE !important;
    }
    .border-lg-dashed-bottom {
      border-bottom: 1px dashed #EEEEEE !important;
    }
    .border-lg-dashed-x {
      border-left: 1px dashed #EEEEEE !important;
      border-right: 1px dashed #EEEEEE !important;
    }
    .border-lg-dashed-y {
      border-top: 1px dashed #EEEEEE !important;
      border-bottom: 1px dashed #EEEEEE !important;
    }
    .rounded-lg-0 {
      border-radius: 0 !important;
    }
  }
  
  @media (min-width: 1200px) {
    .float-xl-start {
      float: left !important;
    }
    .float-xl-end {
      float: right !important;
    }
    .float-xl-none {
      float: none !important;
    }
    .d-xl-inline {
      display: inline !important;
    }
    .d-xl-inline-block {
      display: inline-block !important;
    }
    .d-xl-block {
      display: block !important;
    }
    .d-xl-grid {
      display: grid !important;
    }
    .d-xl-table {
      display: table !important;
    }
    .d-xl-table-row {
      display: table-row !important;
    }
    .d-xl-table-cell {
      display: table-cell !important;
    }
    .d-xl-flex {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
    }
    .d-xl-inline-flex {
      display: -webkit-inline-box !important;
      display: -ms-inline-flexbox !important;
      display: inline-flex !important;
    }
    .d-xl-none {
      display: none !important;
    }
    .position-xl-static {
      position: static !important;
    }
    .position-xl-absolute {
      position: absolute !important;
    }
    .position-xl-relative {
      position: relative !important;
    }
    .position-xl-fixed {
      position: fixed !important;
    }
    .position-xl-sticky {
      position: -webkit-sticky !important;
      position: sticky !important;
    }
    .translate-xl-middle {
      -webkit-transform: translateX(-50%) translateY(-50%) !important;
      -ms-transform: translateX(-50%) translateY(-50%) !important;
      transform: translateX(-50%) translateY(-50%) !important;
    }
    .translate-xl-middle-x {
      -webkit-transform: translateX(-50%) !important;
      -ms-transform: translateX(-50%) !important;
      transform: translateX(-50%) !important;
    }
    .translate-xl-middle-y {
      -webkit-transform: translateY(-50%) !important;
      -ms-transform: translateY(-50%) !important;
      transform: translateY(-50%) !important;
    }
    .border-xl {
      border: 1px solid #EEEEEE !important;
    }
    .border-xl-0 {
      border: 0 !important;
    }
    .border-xl-top {
      border-top: 1px solid #EEEEEE !important;
    }
    .border-xl-top-0 {
      border-top: 0 !important;
    }
    .border-xl-end {
      border-right: 1px solid #EEEEEE !important;
    }
    .border-xl-end-0 {
      border-right: 0 !important;
    }
    .border-xl-bottom {
      border-bottom: 1px solid #EEEEEE !important;
    }
    .border-xl-bottom-0 {
      border-bottom: 0 !important;
    }
    .border-xl-start {
      border-left: 1px solid #EEEEEE !important;
    }
    .border-xl-start-0 {
      border-left: 0 !important;
    }
    .border-xl-facebook {
      border-color: #3c5a99 !important;
    }
    .border-xl-google-plus {
      border-color: #dd4b39 !important;
    }
    .border-xl-twitter {
      border-color: #1da1f2 !important;
    }
    .border-xl-linkedin {
      border-color: #0077b5 !important;
    }
    .border-xl-youtube {
      border-color: #ff0000 !important;
    }
    .border-xl-github {
      border-color: #333 !important;
    }
    .border-xl-black {
      border-color: #000 !important;
    }
    .border-xl-100 {
      border-color: #FCFCFC !important;
    }
    .border-xl-200 {
      border-color: #F5F5F5 !important;
    }
    .border-xl-300 {
      border-color: #EEEEEE !important;
    }
    .border-xl-400 {
      border-color: #E0E0E0 !important;
    }
    .border-xl-500 {
      border-color: #BDBDBD !important;
    }
    .border-xl-600 {
      border-color: #9E9E9E !important;
    }
    .border-xl-700 {
      border-color: #757575 !important;
    }
    .border-xl-800 {
      border-color: #616161 !important;
    }
    .border-xl-900 {
      border-color: #424242 !important;
    }
    .border-xl-1000 {
      border-color: #212121 !important;
    }
    .border-xl-white {
      border-color: #FFFEFE !important;
    }
    .border-xl-primary {
      border-color: #FFB30E !important;
    }
    .border-xl-secondary {
      border-color: #2780ED !important;
    }
    .border-xl-success {
      border-color: #79B93C !important;
    }
    .border-xl-info {
      border-color: #24FFEA !important;
    }
    .border-xl-warning {
      border-color: #FFB30E !important;
    }
    .border-xl-danger {
      border-color: #F17228 !important;
    }
    .border-xl-light {
      border-color: #F9FAFD !important;
    }
    .border-xl-dark {
      border-color: #212121 !important;
    }
    .w-xl-25 {
      width: 25% !important;
    }
    .w-xl-50 {
      width: 50% !important;
    }
    .w-xl-75 {
      width: 75% !important;
    }
    .w-xl-100 {
      width: 100% !important;
    }
    .w-xl-auto {
      width: auto !important;
    }
    .vw-xl-25 {
      width: 25vw !important;
    }
    .vw-xl-50 {
      width: 50vw !important;
    }
    .vw-xl-75 {
      width: 75vw !important;
    }
    .vw-xl-100 {
      width: 100vw !important;
    }
    .h-xl-25 {
      height: 25% !important;
    }
    .h-xl-50 {
      height: 50% !important;
    }
    .h-xl-75 {
      height: 75% !important;
    }
    .h-xl-100 {
      height: 100% !important;
    }
    .h-xl-auto {
      height: auto !important;
    }
    .vh-xl-25 {
      height: 25vh !important;
    }
    .vh-xl-50 {
      height: 50vh !important;
    }
    .vh-xl-75 {
      height: 75vh !important;
    }
    .vh-xl-100 {
      height: 100vh !important;
    }
    .min-vh-xl-25 {
      min-height: 25vh !important;
    }
    .min-vh-xl-50 {
      min-height: 50vh !important;
    }
    .min-vh-xl-75 {
      min-height: 75vh !important;
    }
    .min-vh-xl-100 {
      min-height: 100vh !important;
    }
    .flex-xl-fill {
      -webkit-box-flex: 1 !important;
      -ms-flex: 1 1 auto !important;
      flex: 1 1 auto !important;
    }
    .flex-xl-row {
      -webkit-box-orient: horizontal !important;
      -webkit-box-direction: normal !important;
      -ms-flex-direction: row !important;
      flex-direction: row !important;
    }
    .flex-xl-column {
      -webkit-box-orient: vertical !important;
      -webkit-box-direction: normal !important;
      -ms-flex-direction: column !important;
      flex-direction: column !important;
    }
    .flex-xl-row-reverse {
      -webkit-box-orient: horizontal !important;
      -webkit-box-direction: reverse !important;
      -ms-flex-direction: row-reverse !important;
      flex-direction: row-reverse !important;
    }
    .flex-xl-column-reverse {
      -webkit-box-orient: vertical !important;
      -webkit-box-direction: reverse !important;
      -ms-flex-direction: column-reverse !important;
      flex-direction: column-reverse !important;
    }
    .flex-xl-grow-0 {
      -webkit-box-flex: 0 !important;
      -ms-flex-positive: 0 !important;
      flex-grow: 0 !important;
    }
    .flex-xl-grow-1 {
      -webkit-box-flex: 1 !important;
      -ms-flex-positive: 1 !important;
      flex-grow: 1 !important;
    }
    .flex-xl-shrink-0 {
      -ms-flex-negative: 0 !important;
      flex-shrink: 0 !important;
    }
    .flex-xl-shrink-1 {
      -ms-flex-negative: 1 !important;
      flex-shrink: 1 !important;
    }
    .flex-xl-wrap {
      -ms-flex-wrap: wrap !important;
      flex-wrap: wrap !important;
    }
    .flex-xl-nowrap {
      -ms-flex-wrap: nowrap !important;
      flex-wrap: nowrap !important;
    }
    .flex-xl-wrap-reverse {
      -ms-flex-wrap: wrap-reverse !important;
      flex-wrap: wrap-reverse !important;
    }
    .gap-xl-0 {
      gap: 0 !important;
    }
    .gap-xl-1 {
      gap: 0.25rem !important;
    }
    .gap-xl-2 {
      gap: 0.5rem !important;
    }
    .gap-xl-3 {
      gap: 1rem !important;
    }
    .gap-xl-4 {
      gap: 1.8rem !important;
    }
    .gap-xl-5 {
      gap: 3rem !important;
    }
    .gap-xl-6 {
      gap: 4rem !important;
    }
    .gap-xl-7 {
      gap: 5rem !important;
    }
    .gap-xl-8 {
      gap: 7.5rem !important;
    }
    .gap-xl-9 {
      gap: 10rem !important;
    }
    .gap-xl-10 {
      gap: 12.5rem !important;
    }
    .gap-xl-11 {
      gap: 15rem !important;
    }
    .justify-content-xl-start {
      -webkit-box-pack: start !important;
      -ms-flex-pack: start !important;
      justify-content: flex-start !important;
    }
    .justify-content-xl-end {
      -webkit-box-pack: end !important;
      -ms-flex-pack: end !important;
      justify-content: flex-end !important;
    }
    .justify-content-xl-center {
      -webkit-box-pack: center !important;
      -ms-flex-pack: center !important;
      justify-content: center !important;
    }
    .justify-content-xl-between {
      -webkit-box-pack: justify !important;
      -ms-flex-pack: justify !important;
      justify-content: space-between !important;
    }
    .justify-content-xl-around {
      -ms-flex-pack: distribute !important;
      justify-content: space-around !important;
    }
    .justify-content-xl-evenly {
      -webkit-box-pack: space-evenly !important;
      -ms-flex-pack: space-evenly !important;
      justify-content: space-evenly !important;
    }
    .align-items-xl-start {
      -webkit-box-align: start !important;
      -ms-flex-align: start !important;
      align-items: flex-start !important;
    }
    .align-items-xl-end {
      -webkit-box-align: end !important;
      -ms-flex-align: end !important;
      align-items: flex-end !important;
    }
    .align-items-xl-center {
      -webkit-box-align: center !important;
      -ms-flex-align: center !important;
      align-items: center !important;
    }
    .align-items-xl-baseline {
      -webkit-box-align: baseline !important;
      -ms-flex-align: baseline !important;
      align-items: baseline !important;
    }
    .align-items-xl-stretch {
      -webkit-box-align: stretch !important;
      -ms-flex-align: stretch !important;
      align-items: stretch !important;
    }
    .align-content-xl-start {
      -ms-flex-line-pack: start !important;
      align-content: flex-start !important;
    }
    .align-content-xl-end {
      -ms-flex-line-pack: end !important;
      align-content: flex-end !important;
    }
    .align-content-xl-center {
      -ms-flex-line-pack: center !important;
      align-content: center !important;
    }
    .align-content-xl-between {
      -ms-flex-line-pack: justify !important;
      align-content: space-between !important;
    }
    .align-content-xl-around {
      -ms-flex-line-pack: distribute !important;
      align-content: space-around !important;
    }
    .align-content-xl-stretch {
      -ms-flex-line-pack: stretch !important;
      align-content: stretch !important;
    }
    .align-self-xl-auto {
      -ms-flex-item-align: auto !important;
      align-self: auto !important;
    }
    .align-self-xl-start {
      -ms-flex-item-align: start !important;
      align-self: flex-start !important;
    }
    .align-self-xl-end {
      -ms-flex-item-align: end !important;
      align-self: flex-end !important;
    }
    .align-self-xl-center {
      -ms-flex-item-align: center !important;
      align-self: center !important;
    }
    .align-self-xl-baseline {
      -ms-flex-item-align: baseline !important;
      align-self: baseline !important;
    }
    .align-self-xl-stretch {
      -ms-flex-item-align: stretch !important;
      align-self: stretch !important;
    }
    .order-xl-first {
      -webkit-box-ordinal-group: 0 !important;
      -ms-flex-order: -1 !important;
      order: -1 !important;
    }
    .order-xl-0 {
      -webkit-box-ordinal-group: 1 !important;
      -ms-flex-order: 0 !important;
      order: 0 !important;
    }
    .order-xl-1 {
      -webkit-box-ordinal-group: 2 !important;
      -ms-flex-order: 1 !important;
      order: 1 !important;
    }
    .order-xl-2 {
      -webkit-box-ordinal-group: 3 !important;
      -ms-flex-order: 2 !important;
      order: 2 !important;
    }
    .order-xl-3 {
      -webkit-box-ordinal-group: 4 !important;
      -ms-flex-order: 3 !important;
      order: 3 !important;
    }
    .order-xl-4 {
      -webkit-box-ordinal-group: 5 !important;
      -ms-flex-order: 4 !important;
      order: 4 !important;
    }
    .order-xl-5 {
      -webkit-box-ordinal-group: 6 !important;
      -ms-flex-order: 5 !important;
      order: 5 !important;
    }
    .order-xl-last {
      -webkit-box-ordinal-group: 7 !important;
      -ms-flex-order: 6 !important;
      order: 6 !important;
    }
    .m-xl-0 {
      margin: 0 !important;
    }
    .m-xl-1 {
      margin: 0.25rem !important;
    }
    .m-xl-2 {
      margin: 0.5rem !important;
    }
    .m-xl-3 {
      margin: 1rem !important;
    }
    .m-xl-4 {
      margin: 1.8rem !important;
    }
    .m-xl-5 {
      margin: 3rem !important;
    }
    .m-xl-6 {
      margin: 4rem !important;
    }
    .m-xl-7 {
      margin: 5rem !important;
    }
    .m-xl-8 {
      margin: 7.5rem !important;
    }
    .m-xl-9 {
      margin: 10rem !important;
    }
    .m-xl-10 {
      margin: 12.5rem !important;
    }
    .m-xl-11 {
      margin: 15rem !important;
    }
    .m-xl-auto {
      margin: auto !important;
    }
    .mx-xl-0 {
      margin-right: 0 !important;
      margin-left: 0 !important;
    }
    .mx-xl-1 {
      margin-right: 0.25rem !important;
      margin-left: 0.25rem !important;
    }
    .mx-xl-2 {
      margin-right: 0.5rem !important;
      margin-left: 0.5rem !important;
    }
    .mx-xl-3 {
      margin-right: 1rem !important;
      margin-left: 1rem !important;
    }
    .mx-xl-4 {
      margin-right: 1.8rem !important;
      margin-left: 1.8rem !important;
    }
    .mx-xl-5 {
      margin-right: 3rem !important;
      margin-left: 3rem !important;
    }
    .mx-xl-6 {
      margin-right: 4rem !important;
      margin-left: 4rem !important;
    }
    .mx-xl-7 {
      margin-right: 5rem !important;
      margin-left: 5rem !important;
    }
    .mx-xl-8 {
      margin-right: 7.5rem !important;
      margin-left: 7.5rem !important;
    }
    .mx-xl-9 {
      margin-right: 10rem !important;
      margin-left: 10rem !important;
    }
    .mx-xl-10 {
      margin-right: 12.5rem !important;
      margin-left: 12.5rem !important;
    }
    .mx-xl-11 {
      margin-right: 15rem !important;
      margin-left: 15rem !important;
    }
    .mx-xl-auto {
      margin-right: auto !important;
      margin-left: auto !important;
    }
    .my-xl-0 {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }
    .my-xl-1 {
      margin-top: 0.25rem !important;
      margin-bottom: 0.25rem !important;
    }
    .my-xl-2 {
      margin-top: 0.5rem !important;
      margin-bottom: 0.5rem !important;
    }
    .my-xl-3 {
      margin-top: 1rem !important;
      margin-bottom: 1rem !important;
    }
    .my-xl-4 {
      margin-top: 1.8rem !important;
      margin-bottom: 1.8rem !important;
    }
    .my-xl-5 {
      margin-top: 3rem !important;
      margin-bottom: 3rem !important;
    }
    .my-xl-6 {
      margin-top: 4rem !important;
      margin-bottom: 4rem !important;
    }
    .my-xl-7 {
      margin-top: 5rem !important;
      margin-bottom: 5rem !important;
    }
    .my-xl-8 {
      margin-top: 7.5rem !important;
      margin-bottom: 7.5rem !important;
    }
    .my-xl-9 {
      margin-top: 10rem !important;
      margin-bottom: 10rem !important;
    }
    .my-xl-10 {
      margin-top: 12.5rem !important;
      margin-bottom: 12.5rem !important;
    }
    .my-xl-11 {
      margin-top: 15rem !important;
      margin-bottom: 15rem !important;
    }
    .my-xl-auto {
      margin-top: auto !important;
      margin-bottom: auto !important;
    }
    .mt-xl-0 {
      margin-top: 0 !important;
    }
    .mt-xl-1 {
      margin-top: 0.25rem !important;
    }
    .mt-xl-2 {
      margin-top: 0.5rem !important;
    }
    .mt-xl-3 {
      margin-top: 1rem !important;
    }
    .mt-xl-4 {
      margin-top: 1.8rem !important;
    }
    .mt-xl-5 {
      margin-top: 3rem !important;
    }
    .mt-xl-6 {
      margin-top: 4rem !important;
    }
    .mt-xl-7 {
      margin-top: 5rem !important;
    }
    .mt-xl-8 {
      margin-top: 7.5rem !important;
    }
    .mt-xl-9 {
      margin-top: 10rem !important;
    }
    .mt-xl-10 {
      margin-top: 12.5rem !important;
    }
    .mt-xl-11 {
      margin-top: 15rem !important;
    }
    .mt-xl-auto {
      margin-top: auto !important;
    }
    .me-xl-0 {
      margin-right: 0 !important;
    }
    .me-xl-1 {
      margin-right: 0.25rem !important;
    }
    .me-xl-2 {
      margin-right: 0.5rem !important;
    }
    .me-xl-3 {
      margin-right: 1rem !important;
    }
    .me-xl-4 {
      margin-right: 1.8rem !important;
    }
    .me-xl-5 {
      margin-right: 3rem !important;
    }
    .me-xl-6 {
      margin-right: 4rem !important;
    }
    .me-xl-7 {
      margin-right: 5rem !important;
    }
    .me-xl-8 {
      margin-right: 7.5rem !important;
    }
    .me-xl-9 {
      margin-right: 10rem !important;
    }
    .me-xl-10 {
      margin-right: 12.5rem !important;
    }
    .me-xl-11 {
      margin-right: 15rem !important;
    }
    .me-xl-auto {
      margin-right: auto !important;
    }
    .mb-xl-0 {
      margin-bottom: 0 !important;
    }
    .mb-xl-1 {
      margin-bottom: 0.25rem !important;
    }
    .mb-xl-2 {
      margin-bottom: 0.5rem !important;
    }
    .mb-xl-3 {
      margin-bottom: 1rem !important;
    }
    .mb-xl-4 {
      margin-bottom: 1.8rem !important;
    }
    .mb-xl-5 {
      margin-bottom: 3rem !important;
    }
    .mb-xl-6 {
      margin-bottom: 4rem !important;
    }
    .mb-xl-7 {
      margin-bottom: 5rem !important;
    }
    .mb-xl-8 {
      margin-bottom: 7.5rem !important;
    }
    .mb-xl-9 {
      margin-bottom: 10rem !important;
    }
    .mb-xl-10 {
      margin-bottom: 12.5rem !important;
    }
    .mb-xl-11 {
      margin-bottom: 15rem !important;
    }
    .mb-xl-auto {
      margin-bottom: auto !important;
    }
    .ms-xl-0 {
      margin-left: 0 !important;
    }
    .ms-xl-1 {
      margin-left: 0.25rem !important;
    }
    .ms-xl-2 {
      margin-left: 0.5rem !important;
    }
    .ms-xl-3 {
      margin-left: 1rem !important;
    }
    .ms-xl-4 {
      margin-left: 1.8rem !important;
    }
    .ms-xl-5 {
      margin-left: 3rem !important;
    }
    .ms-xl-6 {
      margin-left: 4rem !important;
    }
    .ms-xl-7 {
      margin-left: 5rem !important;
    }
    .ms-xl-8 {
      margin-left: 7.5rem !important;
    }
    .ms-xl-9 {
      margin-left: 10rem !important;
    }
    .ms-xl-10 {
      margin-left: 12.5rem !important;
    }
    .ms-xl-11 {
      margin-left: 15rem !important;
    }
    .ms-xl-auto {
      margin-left: auto !important;
    }
    .m-xl-n1 {
      margin: -0.25rem !important;
    }
    .m-xl-n2 {
      margin: -0.5rem !important;
    }
    .m-xl-n3 {
      margin: -1rem !important;
    }
    .m-xl-n4 {
      margin: -1.8rem !important;
    }
    .m-xl-n5 {
      margin: -3rem !important;
    }
    .m-xl-n6 {
      margin: -4rem !important;
    }
    .m-xl-n7 {
      margin: -5rem !important;
    }
    .m-xl-n8 {
      margin: -7.5rem !important;
    }
    .m-xl-n9 {
      margin: -10rem !important;
    }
    .m-xl-n10 {
      margin: -12.5rem !important;
    }
    .m-xl-n11 {
      margin: -15rem !important;
    }
    .mx-xl-n1 {
      margin-right: -0.25rem !important;
      margin-left: -0.25rem !important;
    }
    .mx-xl-n2 {
      margin-right: -0.5rem !important;
      margin-left: -0.5rem !important;
    }
    .mx-xl-n3 {
      margin-right: -1rem !important;
      margin-left: -1rem !important;
    }
    .mx-xl-n4 {
      margin-right: -1.8rem !important;
      margin-left: -1.8rem !important;
    }
    .mx-xl-n5 {
      margin-right: -3rem !important;
      margin-left: -3rem !important;
    }
    .mx-xl-n6 {
      margin-right: -4rem !important;
      margin-left: -4rem !important;
    }
    .mx-xl-n7 {
      margin-right: -5rem !important;
      margin-left: -5rem !important;
    }
    .mx-xl-n8 {
      margin-right: -7.5rem !important;
      margin-left: -7.5rem !important;
    }
    .mx-xl-n9 {
      margin-right: -10rem !important;
      margin-left: -10rem !important;
    }
    .mx-xl-n10 {
      margin-right: -12.5rem !important;
      margin-left: -12.5rem !important;
    }
    .mx-xl-n11 {
      margin-right: -15rem !important;
      margin-left: -15rem !important;
    }
    .my-xl-n1 {
      margin-top: -0.25rem !important;
      margin-bottom: -0.25rem !important;
    }
    .my-xl-n2 {
      margin-top: -0.5rem !important;
      margin-bottom: -0.5rem !important;
    }
    .my-xl-n3 {
      margin-top: -1rem !important;
      margin-bottom: -1rem !important;
    }
    .my-xl-n4 {
      margin-top: -1.8rem !important;
      margin-bottom: -1.8rem !important;
    }
    .my-xl-n5 {
      margin-top: -3rem !important;
      margin-bottom: -3rem !important;
    }
    .my-xl-n6 {
      margin-top: -4rem !important;
      margin-bottom: -4rem !important;
    }
    .my-xl-n7 {
      margin-top: -5rem !important;
      margin-bottom: -5rem !important;
    }
    .my-xl-n8 {
      margin-top: -7.5rem !important;
      margin-bottom: -7.5rem !important;
    }
    .my-xl-n9 {
      margin-top: -10rem !important;
      margin-bottom: -10rem !important;
    }
    .my-xl-n10 {
      margin-top: -12.5rem !important;
      margin-bottom: -12.5rem !important;
    }
    .my-xl-n11 {
      margin-top: -15rem !important;
      margin-bottom: -15rem !important;
    }
    .mt-xl-n1 {
      margin-top: -0.25rem !important;
    }
    .mt-xl-n2 {
      margin-top: -0.5rem !important;
    }
    .mt-xl-n3 {
      margin-top: -1rem !important;
    }
    .mt-xl-n4 {
      margin-top: -1.8rem !important;
    }
    .mt-xl-n5 {
      margin-top: -3rem !important;
    }
    .mt-xl-n6 {
      margin-top: -4rem !important;
    }
    .mt-xl-n7 {
      margin-top: -5rem !important;
    }
    .mt-xl-n8 {
      margin-top: -7.5rem !important;
    }
    .mt-xl-n9 {
      margin-top: -10rem !important;
    }
    .mt-xl-n10 {
      margin-top: -12.5rem !important;
    }
    .mt-xl-n11 {
      margin-top: -15rem !important;
    }
    .me-xl-n1 {
      margin-right: -0.25rem !important;
    }
    .me-xl-n2 {
      margin-right: -0.5rem !important;
    }
    .me-xl-n3 {
      margin-right: -1rem !important;
    }
    .me-xl-n4 {
      margin-right: -1.8rem !important;
    }
    .me-xl-n5 {
      margin-right: -3rem !important;
    }
    .me-xl-n6 {
      margin-right: -4rem !important;
    }
    .me-xl-n7 {
      margin-right: -5rem !important;
    }
    .me-xl-n8 {
      margin-right: -7.5rem !important;
    }
    .me-xl-n9 {
      margin-right: -10rem !important;
    }
    .me-xl-n10 {
      margin-right: -12.5rem !important;
    }
    .me-xl-n11 {
      margin-right: -15rem !important;
    }
    .mb-xl-n1 {
      margin-bottom: -0.25rem !important;
    }
    .mb-xl-n2 {
      margin-bottom: -0.5rem !important;
    }
    .mb-xl-n3 {
      margin-bottom: -1rem !important;
    }
    .mb-xl-n4 {
      margin-bottom: -1.8rem !important;
    }
    .mb-xl-n5 {
      margin-bottom: -3rem !important;
    }
    .mb-xl-n6 {
      margin-bottom: -4rem !important;
    }
    .mb-xl-n7 {
      margin-bottom: -5rem !important;
    }
    .mb-xl-n8 {
      margin-bottom: -7.5rem !important;
    }
    .mb-xl-n9 {
      margin-bottom: -10rem !important;
    }
    .mb-xl-n10 {
      margin-bottom: -12.5rem !important;
    }
    .mb-xl-n11 {
      margin-bottom: -15rem !important;
    }
    .ms-xl-n1 {
      margin-left: -0.25rem !important;
    }
    .ms-xl-n2 {
      margin-left: -0.5rem !important;
    }
    .ms-xl-n3 {
      margin-left: -1rem !important;
    }
    .ms-xl-n4 {
      margin-left: -1.8rem !important;
    }
    .ms-xl-n5 {
      margin-left: -3rem !important;
    }
    .ms-xl-n6 {
      margin-left: -4rem !important;
    }
    .ms-xl-n7 {
      margin-left: -5rem !important;
    }
    .ms-xl-n8 {
      margin-left: -7.5rem !important;
    }
    .ms-xl-n9 {
      margin-left: -10rem !important;
    }
    .ms-xl-n10 {
      margin-left: -12.5rem !important;
    }
    .ms-xl-n11 {
      margin-left: -15rem !important;
    }
    .p-xl-0 {
      padding: 0 !important;
    }
    .p-xl-1 {
      padding: 0.25rem !important;
    }
    .p-xl-2 {
      padding: 0.5rem !important;
    }
    .p-xl-3 {
      padding: 1rem !important;
    }
    .p-xl-4 {
      padding: 1.8rem !important;
    }
    .p-xl-5 {
      padding: 3rem !important;
    }
    .p-xl-6 {
      padding: 4rem !important;
    }
    .p-xl-7 {
      padding: 5rem !important;
    }
    .p-xl-8 {
      padding: 7.5rem !important;
    }
    .p-xl-9 {
      padding: 10rem !important;
    }
    .p-xl-10 {
      padding: 12.5rem !important;
    }
    .p-xl-11 {
      padding: 15rem !important;
    }
    .px-xl-0 {
      padding-right: 0 !important;
      padding-left: 0 !important;
    }
    .px-xl-1 {
      padding-right: 0.25rem !important;
      padding-left: 0.25rem !important;
    }
    .px-xl-2 {
      padding-right: 0.5rem !important;
      padding-left: 0.5rem !important;
    }
    .px-xl-3 {
      padding-right: 1rem !important;
      padding-left: 1rem !important;
    }
    .px-xl-4 {
      padding-right: 1.8rem !important;
      padding-left: 1.8rem !important;
    }
    .px-xl-5 {
      padding-right: 3rem !important;
      padding-left: 3rem !important;
    }
    .px-xl-6 {
      padding-right: 4rem !important;
      padding-left: 4rem !important;
    }
    .px-xl-7 {
      padding-right: 5rem !important;
      padding-left: 5rem !important;
    }
    .px-xl-8 {
      padding-right: 7.5rem !important;
      padding-left: 7.5rem !important;
    }
    .px-xl-9 {
      padding-right: 10rem !important;
      padding-left: 10rem !important;
    }
    .px-xl-10 {
      padding-right: 12.5rem !important;
      padding-left: 12.5rem !important;
    }
    .px-xl-11 {
      padding-right: 15rem !important;
      padding-left: 15rem !important;
    }
    .py-xl-0 {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
    .py-xl-1 {
      padding-top: 0.25rem !important;
      padding-bottom: 0.25rem !important;
    }
    .py-xl-2 {
      padding-top: 0.5rem !important;
      padding-bottom: 0.5rem !important;
    }
    .py-xl-3 {
      padding-top: 1rem !important;
      padding-bottom: 1rem !important;
    }
    .py-xl-4 {
      padding-top: 1.8rem !important;
      padding-bottom: 1.8rem !important;
    }
    .py-xl-5 {
      padding-top: 3rem !important;
      padding-bottom: 3rem !important;
    }
    .py-xl-6 {
      padding-top: 4rem !important;
      padding-bottom: 4rem !important;
    }
    .py-xl-7 {
      padding-top: 5rem !important;
      padding-bottom: 5rem !important;
    }
    .py-xl-8 {
      padding-top: 7.5rem !important;
      padding-bottom: 7.5rem !important;
    }
    .py-xl-9 {
      padding-top: 10rem !important;
      padding-bottom: 10rem !important;
    }
    .py-xl-10 {
      padding-top: 12.5rem !important;
      padding-bottom: 12.5rem !important;
    }
    .py-xl-11 {
      padding-top: 15rem !important;
      padding-bottom: 15rem !important;
    }
    .pt-xl-0 {
      padding-top: 0 !important;
    }
    .pt-xl-1 {
      padding-top: 0.25rem !important;
    }
    .pt-xl-2 {
      padding-top: 0.5rem !important;
    }
    .pt-xl-3 {
      padding-top: 1rem !important;
    }
    .pt-xl-4 {
      padding-top: 1.8rem !important;
    }
    .pt-xl-5 {
      padding-top: 3rem !important;
    }
    .pt-xl-6 {
      padding-top: 4rem !important;
    }
    .pt-xl-7 {
      padding-top: 5rem !important;
    }
    .pt-xl-8 {
      padding-top: 7.5rem !important;
    }
    .pt-xl-9 {
      padding-top: 10rem !important;
    }
    .pt-xl-10 {
      padding-top: 12.5rem !important;
    }
    .pt-xl-11 {
      padding-top: 15rem !important;
    }
    .pe-xl-0 {
      padding-right: 0 !important;
    }
    .pe-xl-1 {
      padding-right: 0.25rem !important;
    }
    .pe-xl-2 {
      padding-right: 0.5rem !important;
    }
    .pe-xl-3 {
      padding-right: 1rem !important;
    }
    .pe-xl-4 {
      padding-right: 1.8rem !important;
    }
    .pe-xl-5 {
      padding-right: 3rem !important;
    }
    .pe-xl-6 {
      padding-right: 4rem !important;
    }
    .pe-xl-7 {
      padding-right: 5rem !important;
    }
    .pe-xl-8 {
      padding-right: 7.5rem !important;
    }
    .pe-xl-9 {
      padding-right: 10rem !important;
    }
    .pe-xl-10 {
      padding-right: 12.5rem !important;
    }
    .pe-xl-11 {
      padding-right: 15rem !important;
    }
    .pb-xl-0 {
      padding-bottom: 0 !important;
    }
    .pb-xl-1 {
      padding-bottom: 0.25rem !important;
    }
    .pb-xl-2 {
      padding-bottom: 0.5rem !important;
    }
    .pb-xl-3 {
      padding-bottom: 1rem !important;
    }
    .pb-xl-4 {
      padding-bottom: 1.8rem !important;
    }
    .pb-xl-5 {
      padding-bottom: 3rem !important;
    }
    .pb-xl-6 {
      padding-bottom: 4rem !important;
    }
    .pb-xl-7 {
      padding-bottom: 5rem !important;
    }
    .pb-xl-8 {
      padding-bottom: 7.5rem !important;
    }
    .pb-xl-9 {
      padding-bottom: 10rem !important;
    }
    .pb-xl-10 {
      padding-bottom: 12.5rem !important;
    }
    .pb-xl-11 {
      padding-bottom: 15rem !important;
    }
    .ps-xl-0 {
      padding-left: 0 !important;
    }
    .ps-xl-1 {
      padding-left: 0.25rem !important;
    }
    .ps-xl-2 {
      padding-left: 0.5rem !important;
    }
    .ps-xl-3 {
      padding-left: 1rem !important;
    }
    .ps-xl-4 {
      padding-left: 1.8rem !important;
    }
    .ps-xl-5 {
      padding-left: 3rem !important;
    }
    .ps-xl-6 {
      padding-left: 4rem !important;
    }
    .ps-xl-7 {
      padding-left: 5rem !important;
    }
    .ps-xl-8 {
      padding-left: 7.5rem !important;
    }
    .ps-xl-9 {
      padding-left: 10rem !important;
    }
    .ps-xl-10 {
      padding-left: 12.5rem !important;
    }
    .ps-xl-11 {
      padding-left: 15rem !important;
    }
    .fs-xl--2 {
      font-size: 0.69444rem !important;
    }
    .fs-xl--1 {
      font-size: 0.83333rem !important;
    }
    .fs-xl-0 {
      font-size: 1rem !important;
    }
    .fs-xl-1 {
      font-size: 1.2rem !important;
    }
    .fs-xl-2 {
      font-size: 1.44rem !important;
    }
    .fs-xl-3 {
      font-size: 1.728rem !important;
    }
    .fs-xl-4 {
      font-size: 2.0736rem !important;
    }
    .fs-xl-5 {
      font-size: 2.48832rem !important;
    }
    .fs-xl-6 {
      font-size: 2.98598rem !important;
    }
    .fs-xl-7 {
      font-size: 3.58318rem !important;
    }
    .fs-xl-8 {
      font-size: 4.29982rem !important;
    }
    .text-xl-start {
      text-align: left !important;
    }
    .text-xl-end {
      text-align: right !important;
    }
    .text-xl-center {
      text-align: center !important;
    }
    .rounded-xl-top {
      border-top-left-radius: 0.5rem !important;
      border-top-right-radius: 0.5rem !important;
    }
    .rounded-xl-top-lg {
      border-top-left-radius: 1rem !important;
      border-top-right-radius: 1rem !important;
    }
    .rounded-xl-top-0 {
      border-top-left-radius: 0 !important;
      border-top-right-radius: 0 !important;
    }
    .rounded-xl-end {
      border-top-right-radius: 0.5rem !important;
      border-bottom-right-radius: 0.5rem !important;
    }
    .rounded-xl-end-lg {
      border-top-right-radius: 1rem !important;
      border-bottom-right-radius: 1rem !important;
    }
    .rounded-xl-end-0 {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
    .rounded-xl-bottom {
      border-bottom-right-radius: 0.5rem !important;
      border-bottom-left-radius: 0.5rem !important;
    }
    .rounded-xl-bottom-lg {
      border-bottom-right-radius: 1rem !important;
      border-bottom-left-radius: 1rem !important;
    }
    .rounded-xl-bottom-0 {
      border-bottom-right-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }
    .rounded-xl-start {
      border-bottom-left-radius: 0.5rem !important;
      border-top-left-radius: 0.5rem !important;
    }
    .rounded-xl-start-lg {
      border-bottom-left-radius: 1rem !important;
      border-top-left-radius: 1rem !important;
    }
    .rounded-xl-start-0 {
      border-bottom-left-radius: 0 !important;
      border-top-left-radius: 0 !important;
    }
    .opacity-xl-0 {
      opacity: 0 !important;
    }
    .opacity-xl-25 {
      opacity: 0.25 !important;
    }
    .opacity-xl-50 {
      opacity: 0.5 !important;
    }
    .opacity-xl-75 {
      opacity: 0.75 !important;
    }
    .opacity-xl-85 {
      opacity: 0.85 !important;
    }
    .opacity-xl-100 {
      opacity: 1 !important;
    }
    .max-vh-xl-25 {
      max-height: 25vh !important;
    }
    .max-vh-xl-50 {
      max-height: 50vh !important;
    }
    .max-vh-xl-75 {
      max-height: 75vh !important;
    }
    .max-vh-xl-100 {
      max-height: 100vh !important;
    }
    .border-xl-x {
      border-left: 1px solid #EEEEEE !important;
      border-right: 1px solid #EEEEEE !important;
    }
    .border-xl-x-0 {
      border-left: 0 !important;
      border-right: 0 !important;
    }
    .border-xl-y {
      border-top: 1px solid #EEEEEE !important;
      border-bottom: 1px solid #EEEEEE !important;
    }
    .border-xl-y-0 {
      border-top: 0 !important;
      border-bottom: 0 !important;
    }
    .border-xl-dashed {
      border: 1px dashed #EEEEEE !important;
    }
    .border-xl-dashed-top {
      border-top: 1px dashed #EEEEEE !important;
    }
    .border-xl-dashed-end {
      border-right: 1px dashed #EEEEEE !important;
    }
    .border-xl-dashed-start {
      border-left: 1px dashed #EEEEEE !important;
    }
    .border-xl-dashed-bottom {
      border-bottom: 1px dashed #EEEEEE !important;
    }
    .border-xl-dashed-x {
      border-left: 1px dashed #EEEEEE !important;
      border-right: 1px dashed #EEEEEE !important;
    }
    .border-xl-dashed-y {
      border-top: 1px dashed #EEEEEE !important;
      border-bottom: 1px dashed #EEEEEE !important;
    }
    .rounded-xl-0 {
      border-radius: 0 !important;
    }
  }
  
  @media (min-width: 1540px) {
    .float-xxl-start {
      float: left !important;
    }
    .float-xxl-end {
      float: right !important;
    }
    .float-xxl-none {
      float: none !important;
    }
    .d-xxl-inline {
      display: inline !important;
    }
    .d-xxl-inline-block {
      display: inline-block !important;
    }
    .d-xxl-block {
      display: block !important;
    }
    .d-xxl-grid {
      display: grid !important;
    }
    .d-xxl-table {
      display: table !important;
    }
    .d-xxl-table-row {
      display: table-row !important;
    }
    .d-xxl-table-cell {
      display: table-cell !important;
    }
    .d-xxl-flex {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
    }
    .d-xxl-inline-flex {
      display: -webkit-inline-box !important;
      display: -ms-inline-flexbox !important;
      display: inline-flex !important;
    }
    .d-xxl-none {
      display: none !important;
    }
    .position-xxl-static {
      position: static !important;
    }
    .position-xxl-absolute {
      position: absolute !important;
    }
    .position-xxl-relative {
      position: relative !important;
    }
    .position-xxl-fixed {
      position: fixed !important;
    }
    .position-xxl-sticky {
      position: -webkit-sticky !important;
      position: sticky !important;
    }
    .translate-xxl-middle {
      -webkit-transform: translateX(-50%) translateY(-50%) !important;
      -ms-transform: translateX(-50%) translateY(-50%) !important;
      transform: translateX(-50%) translateY(-50%) !important;
    }
    .translate-xxl-middle-x {
      -webkit-transform: translateX(-50%) !important;
      -ms-transform: translateX(-50%) !important;
      transform: translateX(-50%) !important;
    }
    .translate-xxl-middle-y {
      -webkit-transform: translateY(-50%) !important;
      -ms-transform: translateY(-50%) !important;
      transform: translateY(-50%) !important;
    }
    .border-xxl {
      border: 1px solid #EEEEEE !important;
    }
    .border-xxl-0 {
      border: 0 !important;
    }
    .border-xxl-top {
      border-top: 1px solid #EEEEEE !important;
    }
    .border-xxl-top-0 {
      border-top: 0 !important;
    }
    .border-xxl-end {
      border-right: 1px solid #EEEEEE !important;
    }
    .border-xxl-end-0 {
      border-right: 0 !important;
    }
    .border-xxl-bottom {
      border-bottom: 1px solid #EEEEEE !important;
    }
    .border-xxl-bottom-0 {
      border-bottom: 0 !important;
    }
    .border-xxl-start {
      border-left: 1px solid #EEEEEE !important;
    }
    .border-xxl-start-0 {
      border-left: 0 !important;
    }
    .border-xxl-facebook {
      border-color: #3c5a99 !important;
    }
    .border-xxl-google-plus {
      border-color: #dd4b39 !important;
    }
    .border-xxl-twitter {
      border-color: #1da1f2 !important;
    }
    .border-xxl-linkedin {
      border-color: #0077b5 !important;
    }
    .border-xxl-youtube {
      border-color: #ff0000 !important;
    }
    .border-xxl-github {
      border-color: #333 !important;
    }
    .border-xxl-black {
      border-color: #000 !important;
    }
    .border-xxl-100 {
      border-color: #FCFCFC !important;
    }
    .border-xxl-200 {
      border-color: #F5F5F5 !important;
    }
    .border-xxl-300 {
      border-color: #EEEEEE !important;
    }
    .border-xxl-400 {
      border-color: #E0E0E0 !important;
    }
    .border-xxl-500 {
      border-color: #BDBDBD !important;
    }
    .border-xxl-600 {
      border-color: #9E9E9E !important;
    }
    .border-xxl-700 {
      border-color: #757575 !important;
    }
    .border-xxl-800 {
      border-color: #616161 !important;
    }
    .border-xxl-900 {
      border-color: #424242 !important;
    }
    .border-xxl-1000 {
      border-color: #212121 !important;
    }
    .border-xxl-white {
      border-color: #FFFEFE !important;
    }
    .border-xxl-primary {
      border-color: #FFB30E !important;
    }
    .border-xxl-secondary {
      border-color: #2780ED !important;
    }
    .border-xxl-success {
      border-color: #79B93C !important;
    }
    .border-xxl-info {
      border-color: #24FFEA !important;
    }
    .border-xxl-warning {
      border-color: #FFB30E !important;
    }
    .border-xxl-danger {
      border-color: #F17228 !important;
    }
    .border-xxl-light {
      border-color: #F9FAFD !important;
    }
    .border-xxl-dark {
      border-color: #212121 !important;
    }
    .w-xxl-25 {
      width: 25% !important;
    }
    .w-xxl-50 {
      width: 50% !important;
    }
    .w-xxl-75 {
      width: 75% !important;
    }
    .w-xxl-100 {
      width: 100% !important;
    }
    .w-xxl-auto {
      width: auto !important;
    }
    .vw-xxl-25 {
      width: 25vw !important;
    }
    .vw-xxl-50 {
      width: 50vw !important;
    }
    .vw-xxl-75 {
      width: 75vw !important;
    }
    .vw-xxl-100 {
      width: 100vw !important;
    }
    .h-xxl-25 {
      height: 25% !important;
    }
    .h-xxl-50 {
      height: 50% !important;
    }
    .h-xxl-75 {
      height: 75% !important;
    }
    .h-xxl-100 {
      height: 100% !important;
    }
    .h-xxl-auto {
      height: auto !important;
    }
    .vh-xxl-25 {
      height: 25vh !important;
    }
    .vh-xxl-50 {
      height: 50vh !important;
    }
    .vh-xxl-75 {
      height: 75vh !important;
    }
    .vh-xxl-100 {
      height: 100vh !important;
    }
    .min-vh-xxl-25 {
      min-height: 25vh !important;
    }
    .min-vh-xxl-50 {
      min-height: 50vh !important;
    }
    .min-vh-xxl-75 {
      min-height: 75vh !important;
    }
    .min-vh-xxl-100 {
      min-height: 100vh !important;
    }
    .flex-xxl-fill {
      -webkit-box-flex: 1 !important;
      -ms-flex: 1 1 auto !important;
      flex: 1 1 auto !important;
    }
    .flex-xxl-row {
      -webkit-box-orient: horizontal !important;
      -webkit-box-direction: normal !important;
      -ms-flex-direction: row !important;
      flex-direction: row !important;
    }
    .flex-xxl-column {
      -webkit-box-orient: vertical !important;
      -webkit-box-direction: normal !important;
      -ms-flex-direction: column !important;
      flex-direction: column !important;
    }
    .flex-xxl-row-reverse {
      -webkit-box-orient: horizontal !important;
      -webkit-box-direction: reverse !important;
      -ms-flex-direction: row-reverse !important;
      flex-direction: row-reverse !important;
    }
    .flex-xxl-column-reverse {
      -webkit-box-orient: vertical !important;
      -webkit-box-direction: reverse !important;
      -ms-flex-direction: column-reverse !important;
      flex-direction: column-reverse !important;
    }
    .flex-xxl-grow-0 {
      -webkit-box-flex: 0 !important;
      -ms-flex-positive: 0 !important;
      flex-grow: 0 !important;
    }
    .flex-xxl-grow-1 {
      -webkit-box-flex: 1 !important;
      -ms-flex-positive: 1 !important;
      flex-grow: 1 !important;
    }
    .flex-xxl-shrink-0 {
      -ms-flex-negative: 0 !important;
      flex-shrink: 0 !important;
    }
    .flex-xxl-shrink-1 {
      -ms-flex-negative: 1 !important;
      flex-shrink: 1 !important;
    }
    .flex-xxl-wrap {
      -ms-flex-wrap: wrap !important;
      flex-wrap: wrap !important;
    }
    .flex-xxl-nowrap {
      -ms-flex-wrap: nowrap !important;
      flex-wrap: nowrap !important;
    }
    .flex-xxl-wrap-reverse {
      -ms-flex-wrap: wrap-reverse !important;
      flex-wrap: wrap-reverse !important;
    }
    .gap-xxl-0 {
      gap: 0 !important;
    }
    .gap-xxl-1 {
      gap: 0.25rem !important;
    }
    .gap-xxl-2 {
      gap: 0.5rem !important;
    }
    .gap-xxl-3 {
      gap: 1rem !important;
    }
    .gap-xxl-4 {
      gap: 1.8rem !important;
    }
    .gap-xxl-5 {
      gap: 3rem !important;
    }
    .gap-xxl-6 {
      gap: 4rem !important;
    }
    .gap-xxl-7 {
      gap: 5rem !important;
    }
    .gap-xxl-8 {
      gap: 7.5rem !important;
    }
    .gap-xxl-9 {
      gap: 10rem !important;
    }
    .gap-xxl-10 {
      gap: 12.5rem !important;
    }
    .gap-xxl-11 {
      gap: 15rem !important;
    }
    .justify-content-xxl-start {
      -webkit-box-pack: start !important;
      -ms-flex-pack: start !important;
      justify-content: flex-start !important;
    }
    .justify-content-xxl-end {
      -webkit-box-pack: end !important;
      -ms-flex-pack: end !important;
      justify-content: flex-end !important;
    }
    .justify-content-xxl-center {
      -webkit-box-pack: center !important;
      -ms-flex-pack: center !important;
      justify-content: center !important;
    }
    .justify-content-xxl-between {
      -webkit-box-pack: justify !important;
      -ms-flex-pack: justify !important;
      justify-content: space-between !important;
    }
    .justify-content-xxl-around {
      -ms-flex-pack: distribute !important;
      justify-content: space-around !important;
    }
    .justify-content-xxl-evenly {
      -webkit-box-pack: space-evenly !important;
      -ms-flex-pack: space-evenly !important;
      justify-content: space-evenly !important;
    }
    .align-items-xxl-start {
      -webkit-box-align: start !important;
      -ms-flex-align: start !important;
      align-items: flex-start !important;
    }
    .align-items-xxl-end {
      -webkit-box-align: end !important;
      -ms-flex-align: end !important;
      align-items: flex-end !important;
    }
    .align-items-xxl-center {
      -webkit-box-align: center !important;
      -ms-flex-align: center !important;
      align-items: center !important;
    }
    .align-items-xxl-baseline {
      -webkit-box-align: baseline !important;
      -ms-flex-align: baseline !important;
      align-items: baseline !important;
    }
    .align-items-xxl-stretch {
      -webkit-box-align: stretch !important;
      -ms-flex-align: stretch !important;
      align-items: stretch !important;
    }
    .align-content-xxl-start {
      -ms-flex-line-pack: start !important;
      align-content: flex-start !important;
    }
    .align-content-xxl-end {
      -ms-flex-line-pack: end !important;
      align-content: flex-end !important;
    }
    .align-content-xxl-center {
      -ms-flex-line-pack: center !important;
      align-content: center !important;
    }
    .align-content-xxl-between {
      -ms-flex-line-pack: justify !important;
      align-content: space-between !important;
    }
    .align-content-xxl-around {
      -ms-flex-line-pack: distribute !important;
      align-content: space-around !important;
    }
    .align-content-xxl-stretch {
      -ms-flex-line-pack: stretch !important;
      align-content: stretch !important;
    }
    .align-self-xxl-auto {
      -ms-flex-item-align: auto !important;
      align-self: auto !important;
    }
    .align-self-xxl-start {
      -ms-flex-item-align: start !important;
      align-self: flex-start !important;
    }
    .align-self-xxl-end {
      -ms-flex-item-align: end !important;
      align-self: flex-end !important;
    }
    .align-self-xxl-center {
      -ms-flex-item-align: center !important;
      align-self: center !important;
    }
    .align-self-xxl-baseline {
      -ms-flex-item-align: baseline !important;
      align-self: baseline !important;
    }
    .align-self-xxl-stretch {
      -ms-flex-item-align: stretch !important;
      align-self: stretch !important;
    }
    .order-xxl-first {
      -webkit-box-ordinal-group: 0 !important;
      -ms-flex-order: -1 !important;
      order: -1 !important;
    }
    .order-xxl-0 {
      -webkit-box-ordinal-group: 1 !important;
      -ms-flex-order: 0 !important;
      order: 0 !important;
    }
    .order-xxl-1 {
      -webkit-box-ordinal-group: 2 !important;
      -ms-flex-order: 1 !important;
      order: 1 !important;
    }
    .order-xxl-2 {
      -webkit-box-ordinal-group: 3 !important;
      -ms-flex-order: 2 !important;
      order: 2 !important;
    }
    .order-xxl-3 {
      -webkit-box-ordinal-group: 4 !important;
      -ms-flex-order: 3 !important;
      order: 3 !important;
    }
    .order-xxl-4 {
      -webkit-box-ordinal-group: 5 !important;
      -ms-flex-order: 4 !important;
      order: 4 !important;
    }
    .order-xxl-5 {
      -webkit-box-ordinal-group: 6 !important;
      -ms-flex-order: 5 !important;
      order: 5 !important;
    }
    .order-xxl-last {
      -webkit-box-ordinal-group: 7 !important;
      -ms-flex-order: 6 !important;
      order: 6 !important;
    }
    .m-xxl-0 {
      margin: 0 !important;
    }
    .m-xxl-1 {
      margin: 0.25rem !important;
    }
    .m-xxl-2 {
      margin: 0.5rem !important;
    }
    .m-xxl-3 {
      margin: 1rem !important;
    }
    .m-xxl-4 {
      margin: 1.8rem !important;
    }
    .m-xxl-5 {
      margin: 3rem !important;
    }
    .m-xxl-6 {
      margin: 4rem !important;
    }
    .m-xxl-7 {
      margin: 5rem !important;
    }
    .m-xxl-8 {
      margin: 7.5rem !important;
    }
    .m-xxl-9 {
      margin: 10rem !important;
    }
    .m-xxl-10 {
      margin: 12.5rem !important;
    }
    .m-xxl-11 {
      margin: 15rem !important;
    }
    .m-xxl-auto {
      margin: auto !important;
    }
    .mx-xxl-0 {
      margin-right: 0 !important;
      margin-left: 0 !important;
    }
    .mx-xxl-1 {
      margin-right: 0.25rem !important;
      margin-left: 0.25rem !important;
    }
    .mx-xxl-2 {
      margin-right: 0.5rem !important;
      margin-left: 0.5rem !important;
    }
    .mx-xxl-3 {
      margin-right: 1rem !important;
      margin-left: 1rem !important;
    }
    .mx-xxl-4 {
      margin-right: 1.8rem !important;
      margin-left: 1.8rem !important;
    }
    .mx-xxl-5 {
      margin-right: 3rem !important;
      margin-left: 3rem !important;
    }
    .mx-xxl-6 {
      margin-right: 4rem !important;
      margin-left: 4rem !important;
    }
    .mx-xxl-7 {
      margin-right: 5rem !important;
      margin-left: 5rem !important;
    }
    .mx-xxl-8 {
      margin-right: 7.5rem !important;
      margin-left: 7.5rem !important;
    }
    .mx-xxl-9 {
      margin-right: 10rem !important;
      margin-left: 10rem !important;
    }
    .mx-xxl-10 {
      margin-right: 12.5rem !important;
      margin-left: 12.5rem !important;
    }
    .mx-xxl-11 {
      margin-right: 15rem !important;
      margin-left: 15rem !important;
    }
    .mx-xxl-auto {
      margin-right: auto !important;
      margin-left: auto !important;
    }
    .my-xxl-0 {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }
    .my-xxl-1 {
      margin-top: 0.25rem !important;
      margin-bottom: 0.25rem !important;
    }
    .my-xxl-2 {
      margin-top: 0.5rem !important;
      margin-bottom: 0.5rem !important;
    }
    .my-xxl-3 {
      margin-top: 1rem !important;
      margin-bottom: 1rem !important;
    }
    .my-xxl-4 {
      margin-top: 1.8rem !important;
      margin-bottom: 1.8rem !important;
    }
    .my-xxl-5 {
      margin-top: 3rem !important;
      margin-bottom: 3rem !important;
    }
    .my-xxl-6 {
      margin-top: 4rem !important;
      margin-bottom: 4rem !important;
    }
    .my-xxl-7 {
      margin-top: 5rem !important;
      margin-bottom: 5rem !important;
    }
    .my-xxl-8 {
      margin-top: 7.5rem !important;
      margin-bottom: 7.5rem !important;
    }
    .my-xxl-9 {
      margin-top: 10rem !important;
      margin-bottom: 10rem !important;
    }
    .my-xxl-10 {
      margin-top: 12.5rem !important;
      margin-bottom: 12.5rem !important;
    }
    .my-xxl-11 {
      margin-top: 15rem !important;
      margin-bottom: 15rem !important;
    }
    .my-xxl-auto {
      margin-top: auto !important;
      margin-bottom: auto !important;
    }
    .mt-xxl-0 {
      margin-top: 0 !important;
    }
    .mt-xxl-1 {
      margin-top: 0.25rem !important;
    }
    .mt-xxl-2 {
      margin-top: 0.5rem !important;
    }
    .mt-xxl-3 {
      margin-top: 1rem !important;
    }
    .mt-xxl-4 {
      margin-top: 1.8rem !important;
    }
    .mt-xxl-5 {
      margin-top: 3rem !important;
    }
    .mt-xxl-6 {
      margin-top: 4rem !important;
    }
    .mt-xxl-7 {
      margin-top: 5rem !important;
    }
    .mt-xxl-8 {
      margin-top: 7.5rem !important;
    }
    .mt-xxl-9 {
      margin-top: 10rem !important;
    }
    .mt-xxl-10 {
      margin-top: 12.5rem !important;
    }
    .mt-xxl-11 {
      margin-top: 15rem !important;
    }
    .mt-xxl-auto {
      margin-top: auto !important;
    }
    .me-xxl-0 {
      margin-right: 0 !important;
    }
    .me-xxl-1 {
      margin-right: 0.25rem !important;
    }
    .me-xxl-2 {
      margin-right: 0.5rem !important;
    }
    .me-xxl-3 {
      margin-right: 1rem !important;
    }
    .me-xxl-4 {
      margin-right: 1.8rem !important;
    }
    .me-xxl-5 {
      margin-right: 3rem !important;
    }
    .me-xxl-6 {
      margin-right: 4rem !important;
    }
    .me-xxl-7 {
      margin-right: 5rem !important;
    }
    .me-xxl-8 {
      margin-right: 7.5rem !important;
    }
    .me-xxl-9 {
      margin-right: 10rem !important;
    }
    .me-xxl-10 {
      margin-right: 12.5rem !important;
    }
    .me-xxl-11 {
      margin-right: 15rem !important;
    }
    .me-xxl-auto {
      margin-right: auto !important;
    }
    .mb-xxl-0 {
      margin-bottom: 0 !important;
    }
    .mb-xxl-1 {
      margin-bottom: 0.25rem !important;
    }
    .mb-xxl-2 {
      margin-bottom: 0.5rem !important;
    }
    .mb-xxl-3 {
      margin-bottom: 1rem !important;
    }
    .mb-xxl-4 {
      margin-bottom: 1.8rem !important;
    }
    .mb-xxl-5 {
      margin-bottom: 3rem !important;
    }
    .mb-xxl-6 {
      margin-bottom: 4rem !important;
    }
    .mb-xxl-7 {
      margin-bottom: 5rem !important;
    }
    .mb-xxl-8 {
      margin-bottom: 7.5rem !important;
    }
    .mb-xxl-9 {
      margin-bottom: 10rem !important;
    }
    .mb-xxl-10 {
      margin-bottom: 12.5rem !important;
    }
    .mb-xxl-11 {
      margin-bottom: 15rem !important;
    }
    .mb-xxl-auto {
      margin-bottom: auto !important;
    }
    .ms-xxl-0 {
      margin-left: 0 !important;
    }
    .ms-xxl-1 {
      margin-left: 0.25rem !important;
    }
    .ms-xxl-2 {
      margin-left: 0.5rem !important;
    }
    .ms-xxl-3 {
      margin-left: 1rem !important;
    }
    .ms-xxl-4 {
      margin-left: 1.8rem !important;
    }
    .ms-xxl-5 {
      margin-left: 3rem !important;
    }
    .ms-xxl-6 {
      margin-left: 4rem !important;
    }
    .ms-xxl-7 {
      margin-left: 5rem !important;
    }
    .ms-xxl-8 {
      margin-left: 7.5rem !important;
    }
    .ms-xxl-9 {
      margin-left: 10rem !important;
    }
    .ms-xxl-10 {
      margin-left: 12.5rem !important;
    }
    .ms-xxl-11 {
      margin-left: 15rem !important;
    }
    .ms-xxl-auto {
      margin-left: auto !important;
    }
    .m-xxl-n1 {
      margin: -0.25rem !important;
    }
    .m-xxl-n2 {
      margin: -0.5rem !important;
    }
    .m-xxl-n3 {
      margin: -1rem !important;
    }
    .m-xxl-n4 {
      margin: -1.8rem !important;
    }
    .m-xxl-n5 {
      margin: -3rem !important;
    }
    .m-xxl-n6 {
      margin: -4rem !important;
    }
    .m-xxl-n7 {
      margin: -5rem !important;
    }
    .m-xxl-n8 {
      margin: -7.5rem !important;
    }
    .m-xxl-n9 {
      margin: -10rem !important;
    }
    .m-xxl-n10 {
      margin: -12.5rem !important;
    }
    .m-xxl-n11 {
      margin: -15rem !important;
    }
    .mx-xxl-n1 {
      margin-right: -0.25rem !important;
      margin-left: -0.25rem !important;
    }
    .mx-xxl-n2 {
      margin-right: -0.5rem !important;
      margin-left: -0.5rem !important;
    }
    .mx-xxl-n3 {
      margin-right: -1rem !important;
      margin-left: -1rem !important;
    }
    .mx-xxl-n4 {
      margin-right: -1.8rem !important;
      margin-left: -1.8rem !important;
    }
    .mx-xxl-n5 {
      margin-right: -3rem !important;
      margin-left: -3rem !important;
    }
    .mx-xxl-n6 {
      margin-right: -4rem !important;
      margin-left: -4rem !important;
    }
    .mx-xxl-n7 {
      margin-right: -5rem !important;
      margin-left: -5rem !important;
    }
    .mx-xxl-n8 {
      margin-right: -7.5rem !important;
      margin-left: -7.5rem !important;
    }
    .mx-xxl-n9 {
      margin-right: -10rem !important;
      margin-left: -10rem !important;
    }
    .mx-xxl-n10 {
      margin-right: -12.5rem !important;
      margin-left: -12.5rem !important;
    }
    .mx-xxl-n11 {
      margin-right: -15rem !important;
      margin-left: -15rem !important;
    }
    .my-xxl-n1 {
      margin-top: -0.25rem !important;
      margin-bottom: -0.25rem !important;
    }
    .my-xxl-n2 {
      margin-top: -0.5rem !important;
      margin-bottom: -0.5rem !important;
    }
    .my-xxl-n3 {
      margin-top: -1rem !important;
      margin-bottom: -1rem !important;
    }
    .my-xxl-n4 {
      margin-top: -1.8rem !important;
      margin-bottom: -1.8rem !important;
    }
    .my-xxl-n5 {
      margin-top: -3rem !important;
      margin-bottom: -3rem !important;
    }
    .my-xxl-n6 {
      margin-top: -4rem !important;
      margin-bottom: -4rem !important;
    }
    .my-xxl-n7 {
      margin-top: -5rem !important;
      margin-bottom: -5rem !important;
    }
    .my-xxl-n8 {
      margin-top: -7.5rem !important;
      margin-bottom: -7.5rem !important;
    }
    .my-xxl-n9 {
      margin-top: -10rem !important;
      margin-bottom: -10rem !important;
    }
    .my-xxl-n10 {
      margin-top: -12.5rem !important;
      margin-bottom: -12.5rem !important;
    }
    .my-xxl-n11 {
      margin-top: -15rem !important;
      margin-bottom: -15rem !important;
    }
    .mt-xxl-n1 {
      margin-top: -0.25rem !important;
    }
    .mt-xxl-n2 {
      margin-top: -0.5rem !important;
    }
    .mt-xxl-n3 {
      margin-top: -1rem !important;
    }
    .mt-xxl-n4 {
      margin-top: -1.8rem !important;
    }
    .mt-xxl-n5 {
      margin-top: -3rem !important;
    }
    .mt-xxl-n6 {
      margin-top: -4rem !important;
    }
    .mt-xxl-n7 {
      margin-top: -5rem !important;
    }
    .mt-xxl-n8 {
      margin-top: -7.5rem !important;
    }
    .mt-xxl-n9 {
      margin-top: -10rem !important;
    }
    .mt-xxl-n10 {
      margin-top: -12.5rem !important;
    }
    .mt-xxl-n11 {
      margin-top: -15rem !important;
    }
    .me-xxl-n1 {
      margin-right: -0.25rem !important;
    }
    .me-xxl-n2 {
      margin-right: -0.5rem !important;
    }
    .me-xxl-n3 {
      margin-right: -1rem !important;
    }
    .me-xxl-n4 {
      margin-right: -1.8rem !important;
    }
    .me-xxl-n5 {
      margin-right: -3rem !important;
    }
    .me-xxl-n6 {
      margin-right: -4rem !important;
    }
    .me-xxl-n7 {
      margin-right: -5rem !important;
    }
    .me-xxl-n8 {
      margin-right: -7.5rem !important;
    }
    .me-xxl-n9 {
      margin-right: -10rem !important;
    }
    .me-xxl-n10 {
      margin-right: -12.5rem !important;
    }
    .me-xxl-n11 {
      margin-right: -15rem !important;
    }
    .mb-xxl-n1 {
      margin-bottom: -0.25rem !important;
    }
    .mb-xxl-n2 {
      margin-bottom: -0.5rem !important;
    }
    .mb-xxl-n3 {
      margin-bottom: -1rem !important;
    }
    .mb-xxl-n4 {
      margin-bottom: -1.8rem !important;
    }
    .mb-xxl-n5 {
      margin-bottom: -3rem !important;
    }
    .mb-xxl-n6 {
      margin-bottom: -4rem !important;
    }
    .mb-xxl-n7 {
      margin-bottom: -5rem !important;
    }
    .mb-xxl-n8 {
      margin-bottom: -7.5rem !important;
    }
    .mb-xxl-n9 {
      margin-bottom: -10rem !important;
    }
    .mb-xxl-n10 {
      margin-bottom: -12.5rem !important;
    }
    .mb-xxl-n11 {
      margin-bottom: -15rem !important;
    }
    .ms-xxl-n1 {
      margin-left: -0.25rem !important;
    }
    .ms-xxl-n2 {
      margin-left: -0.5rem !important;
    }
    .ms-xxl-n3 {
      margin-left: -1rem !important;
    }
    .ms-xxl-n4 {
      margin-left: -1.8rem !important;
    }
    .ms-xxl-n5 {
      margin-left: -3rem !important;
    }
    .ms-xxl-n6 {
      margin-left: -4rem !important;
    }
    .ms-xxl-n7 {
      margin-left: -5rem !important;
    }
    .ms-xxl-n8 {
      margin-left: -7.5rem !important;
    }
    .ms-xxl-n9 {
      margin-left: -10rem !important;
    }
    .ms-xxl-n10 {
      margin-left: -12.5rem !important;
    }
    .ms-xxl-n11 {
      margin-left: -15rem !important;
    }
    .p-xxl-0 {
      padding: 0 !important;
    }
    .p-xxl-1 {
      padding: 0.25rem !important;
    }
    .p-xxl-2 {
      padding: 0.5rem !important;
    }
    .p-xxl-3 {
      padding: 1rem !important;
    }
    .p-xxl-4 {
      padding: 1.8rem !important;
    }
    .p-xxl-5 {
      padding: 3rem !important;
    }
    .p-xxl-6 {
      padding: 4rem !important;
    }
    .p-xxl-7 {
      padding: 5rem !important;
    }
    .p-xxl-8 {
      padding: 7.5rem !important;
    }
    .p-xxl-9 {
      padding: 10rem !important;
    }
    .p-xxl-10 {
      padding: 12.5rem !important;
    }
    .p-xxl-11 {
      padding: 15rem !important;
    }
    .px-xxl-0 {
      padding-right: 0 !important;
      padding-left: 0 !important;
    }
    .px-xxl-1 {
      padding-right: 0.25rem !important;
      padding-left: 0.25rem !important;
    }
    .px-xxl-2 {
      padding-right: 0.5rem !important;
      padding-left: 0.5rem !important;
    }
    .px-xxl-3 {
      padding-right: 1rem !important;
      padding-left: 1rem !important;
    }
    .px-xxl-4 {
      padding-right: 1.8rem !important;
      padding-left: 1.8rem !important;
    }
    .px-xxl-5 {
      padding-right: 3rem !important;
      padding-left: 3rem !important;
    }
    .px-xxl-6 {
      padding-right: 4rem !important;
      padding-left: 4rem !important;
    }
    .px-xxl-7 {
      padding-right: 5rem !important;
      padding-left: 5rem !important;
    }
    .px-xxl-8 {
      padding-right: 7.5rem !important;
      padding-left: 7.5rem !important;
    }
    .px-xxl-9 {
      padding-right: 10rem !important;
      padding-left: 10rem !important;
    }
    .px-xxl-10 {
      padding-right: 12.5rem !important;
      padding-left: 12.5rem !important;
    }
    .px-xxl-11 {
      padding-right: 15rem !important;
      padding-left: 15rem !important;
    }
    .py-xxl-0 {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
    .py-xxl-1 {
      padding-top: 0.25rem !important;
      padding-bottom: 0.25rem !important;
    }
    .py-xxl-2 {
      padding-top: 0.5rem !important;
      padding-bottom: 0.5rem !important;
    }
    .py-xxl-3 {
      padding-top: 1rem !important;
      padding-bottom: 1rem !important;
    }
    .py-xxl-4 {
      padding-top: 1.8rem !important;
      padding-bottom: 1.8rem !important;
    }
    .py-xxl-5 {
      padding-top: 3rem !important;
      padding-bottom: 3rem !important;
    }
    .py-xxl-6 {
      padding-top: 4rem !important;
      padding-bottom: 4rem !important;
    }
    .py-xxl-7 {
      padding-top: 5rem !important;
      padding-bottom: 5rem !important;
    }
    .py-xxl-8 {
      padding-top: 7.5rem !important;
      padding-bottom: 7.5rem !important;
    }
    .py-xxl-9 {
      padding-top: 10rem !important;
      padding-bottom: 10rem !important;
    }
    .py-xxl-10 {
      padding-top: 12.5rem !important;
      padding-bottom: 12.5rem !important;
    }
    .py-xxl-11 {
      padding-top: 15rem !important;
      padding-bottom: 15rem !important;
    }
    .pt-xxl-0 {
      padding-top: 0 !important;
    }
    .pt-xxl-1 {
      padding-top: 0.25rem !important;
    }
    .pt-xxl-2 {
      padding-top: 0.5rem !important;
    }
    .pt-xxl-3 {
      padding-top: 1rem !important;
    }
    .pt-xxl-4 {
      padding-top: 1.8rem !important;
    }
    .pt-xxl-5 {
      padding-top: 3rem !important;
    }
    .pt-xxl-6 {
      padding-top: 4rem !important;
    }
    .pt-xxl-7 {
      padding-top: 5rem !important;
    }
    .pt-xxl-8 {
      padding-top: 7.5rem !important;
    }
    .pt-xxl-9 {
      padding-top: 10rem !important;
    }
    .pt-xxl-10 {
      padding-top: 12.5rem !important;
    }
    .pt-xxl-11 {
      padding-top: 15rem !important;
    }
    .pe-xxl-0 {
      padding-right: 0 !important;
    }
    .pe-xxl-1 {
      padding-right: 0.25rem !important;
    }
    .pe-xxl-2 {
      padding-right: 0.5rem !important;
    }
    .pe-xxl-3 {
      padding-right: 1rem !important;
    }
    .pe-xxl-4 {
      padding-right: 1.8rem !important;
    }
    .pe-xxl-5 {
      padding-right: 3rem !important;
    }
    .pe-xxl-6 {
      padding-right: 4rem !important;
    }
    .pe-xxl-7 {
      padding-right: 5rem !important;
    }
    .pe-xxl-8 {
      padding-right: 7.5rem !important;
    }
    .pe-xxl-9 {
      padding-right: 10rem !important;
    }
    .pe-xxl-10 {
      padding-right: 12.5rem !important;
    }
    .pe-xxl-11 {
      padding-right: 15rem !important;
    }
    .pb-xxl-0 {
      padding-bottom: 0 !important;
    }
    .pb-xxl-1 {
      padding-bottom: 0.25rem !important;
    }
    .pb-xxl-2 {
      padding-bottom: 0.5rem !important;
    }
    .pb-xxl-3 {
      padding-bottom: 1rem !important;
    }
    .pb-xxl-4 {
      padding-bottom: 1.8rem !important;
    }
    .pb-xxl-5 {
      padding-bottom: 3rem !important;
    }
    .pb-xxl-6 {
      padding-bottom: 4rem !important;
    }
    .pb-xxl-7 {
      padding-bottom: 5rem !important;
    }
    .pb-xxl-8 {
      padding-bottom: 7.5rem !important;
    }
    .pb-xxl-9 {
      padding-bottom: 10rem !important;
    }
    .pb-xxl-10 {
      padding-bottom: 12.5rem !important;
    }
    .pb-xxl-11 {
      padding-bottom: 15rem !important;
    }
    .ps-xxl-0 {
      padding-left: 0 !important;
    }
    .ps-xxl-1 {
      padding-left: 0.25rem !important;
    }
    .ps-xxl-2 {
      padding-left: 0.5rem !important;
    }
    .ps-xxl-3 {
      padding-left: 1rem !important;
    }
    .ps-xxl-4 {
      padding-left: 1.8rem !important;
    }
    .ps-xxl-5 {
      padding-left: 3rem !important;
    }
    .ps-xxl-6 {
      padding-left: 4rem !important;
    }
    .ps-xxl-7 {
      padding-left: 5rem !important;
    }
    .ps-xxl-8 {
      padding-left: 7.5rem !important;
    }
    .ps-xxl-9 {
      padding-left: 10rem !important;
    }
    .ps-xxl-10 {
      padding-left: 12.5rem !important;
    }
    .ps-xxl-11 {
      padding-left: 15rem !important;
    }
    .fs-xxl--2 {
      font-size: 0.69444rem !important;
    }
    .fs-xxl--1 {
      font-size: 0.83333rem !important;
    }
    .fs-xxl-0 {
      font-size: 1rem !important;
    }
    .fs-xxl-1 {
      font-size: 1.2rem !important;
    }
    .fs-xxl-2 {
      font-size: 1.44rem !important;
    }
    .fs-xxl-3 {
      font-size: 1.728rem !important;
    }
    .fs-xxl-4 {
      font-size: 2.0736rem !important;
    }
    .fs-xxl-5 {
      font-size: 2.48832rem !important;
    }
    .fs-xxl-6 {
      font-size: 2.98598rem !important;
    }
    .fs-xxl-7 {
      font-size: 3.58318rem !important;
    }
    .fs-xxl-8 {
      font-size: 4.29982rem !important;
    }
    .text-xxl-start {
      text-align: left !important;
    }
    .text-xxl-end {
      text-align: right !important;
    }
    .text-xxl-center {
      text-align: center !important;
    }
    .rounded-xxl-top {
      border-top-left-radius: 0.5rem !important;
      border-top-right-radius: 0.5rem !important;
    }
    .rounded-xxl-top-lg {
      border-top-left-radius: 1rem !important;
      border-top-right-radius: 1rem !important;
    }
    .rounded-xxl-top-0 {
      border-top-left-radius: 0 !important;
      border-top-right-radius: 0 !important;
    }
    .rounded-xxl-end {
      border-top-right-radius: 0.5rem !important;
      border-bottom-right-radius: 0.5rem !important;
    }
    .rounded-xxl-end-lg {
      border-top-right-radius: 1rem !important;
      border-bottom-right-radius: 1rem !important;
    }
    .rounded-xxl-end-0 {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
    .rounded-xxl-bottom {
      border-bottom-right-radius: 0.5rem !important;
      border-bottom-left-radius: 0.5rem !important;
    }
    .rounded-xxl-bottom-lg {
      border-bottom-right-radius: 1rem !important;
      border-bottom-left-radius: 1rem !important;
    }
    .rounded-xxl-bottom-0 {
      border-bottom-right-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }
    .rounded-xxl-start {
      border-bottom-left-radius: 0.5rem !important;
      border-top-left-radius: 0.5rem !important;
    }
    .rounded-xxl-start-lg {
      border-bottom-left-radius: 1rem !important;
      border-top-left-radius: 1rem !important;
    }
    .rounded-xxl-start-0 {
      border-bottom-left-radius: 0 !important;
      border-top-left-radius: 0 !important;
    }
    .opacity-xxl-0 {
      opacity: 0 !important;
    }
    .opacity-xxl-25 {
      opacity: 0.25 !important;
    }
    .opacity-xxl-50 {
      opacity: 0.5 !important;
    }
    .opacity-xxl-75 {
      opacity: 0.75 !important;
    }
    .opacity-xxl-85 {
      opacity: 0.85 !important;
    }
    .opacity-xxl-100 {
      opacity: 1 !important;
    }
    .max-vh-xxl-25 {
      max-height: 25vh !important;
    }
    .max-vh-xxl-50 {
      max-height: 50vh !important;
    }
    .max-vh-xxl-75 {
      max-height: 75vh !important;
    }
    .max-vh-xxl-100 {
      max-height: 100vh !important;
    }
    .border-xxl-x {
      border-left: 1px solid #EEEEEE !important;
      border-right: 1px solid #EEEEEE !important;
    }
    .border-xxl-x-0 {
      border-left: 0 !important;
      border-right: 0 !important;
    }
    .border-xxl-y {
      border-top: 1px solid #EEEEEE !important;
      border-bottom: 1px solid #EEEEEE !important;
    }
    .border-xxl-y-0 {
      border-top: 0 !important;
      border-bottom: 0 !important;
    }
    .border-xxl-dashed {
      border: 1px dashed #EEEEEE !important;
    }
    .border-xxl-dashed-top {
      border-top: 1px dashed #EEEEEE !important;
    }
    .border-xxl-dashed-end {
      border-right: 1px dashed #EEEEEE !important;
    }
    .border-xxl-dashed-start {
      border-left: 1px dashed #EEEEEE !important;
    }
    .border-xxl-dashed-bottom {
      border-bottom: 1px dashed #EEEEEE !important;
    }
    .border-xxl-dashed-x {
      border-left: 1px dashed #EEEEEE !important;
      border-right: 1px dashed #EEEEEE !important;
    }
    .border-xxl-dashed-y {
      border-top: 1px dashed #EEEEEE !important;
      border-bottom: 1px dashed #EEEEEE !important;
    }
    .rounded-xxl-0 {
      border-radius: 0 !important;
    }
  }
  
  @media print {
    .d-print-inline {
      display: inline !important;
    }
    .d-print-inline-block {
      display: inline-block !important;
    }
    .d-print-block {
      display: block !important;
    }
    .d-print-grid {
      display: grid !important;
    }
    .d-print-table {
      display: table !important;
    }
    .d-print-table-row {
      display: table-row !important;
    }
    .d-print-table-cell {
      display: table-cell !important;
    }
    .d-print-flex {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
    }
    .d-print-inline-flex {
      display: -webkit-inline-box !important;
      display: -ms-inline-flexbox !important;
      display: inline-flex !important;
    }
    .d-print-none {
      display: none !important;
    }
  }
  
  /*-----------------------------------------------
  |   Theme Styles
  -----------------------------------------------*/
  /*-----------------------------------------------
  |   Reboot
  -----------------------------------------------*/
  ::-webkit-input-placeholder {
    opacity: 1;
    color: #E0E0E0 !important;
  }
  ::-moz-placeholder {
    opacity: 1;
    color: #E0E0E0 !important;
  }
  :-ms-input-placeholder {
    opacity: 1;
    color: #E0E0E0 !important;
  }
  ::-ms-input-placeholder {
    opacity: 1;
    color: #E0E0E0 !important;
  }
  ::placeholder {
    opacity: 1;
    color: #E0E0E0 !important;
  }
  
  html {
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: smooth;
    scroll-padding-top: 6.3125rem;
  }
  
  header {
    position: relative;
    z-index: 1020;
    background: #FFFEFE;
  }
  
  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: relative;
  }
  
  pre, code {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
  }
  
  section {
    position: relative;
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  
  @media (min-width: 992px) {
    section {
      padding-top: 7.5rem;
      padding-bottom: 7.5rem;
    }
  }
  
  input,
  button,
  select,
  optgroup,
  textarea,
  label,
  .alert,
  .badge,
  .blockquote-footer,
  .btn,
  .navbar,
  .pagination,
  .valid-feedback,
  .invalid-feedback {
    font-family: "Source Sans Pro", "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  }
  
  pre {
    margin: 0;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  
  strong {
    font-weight: 600;
  }
  
  label {
    font-weight: 500 !important;
    margin-bottom: 0.5rem;
  }
  
  ol,
  ul {
    padding-left: 1.1rem;
  }
  
  /* -------------------------------------------------------------------------- */
  /*                               Browser Fixing                               */
  /* -------------------------------------------------------------------------- */
  .firefox .dropcap:first-letter {
    margin-top: 0.175em;
  }
  
  .chrome .btn-close {
    background-clip: unset;
  }
  
  /* -------------------------------------------------------------------------- */
  /*                                    Text                                    */
  /* -------------------------------------------------------------------------- */
  .text-smallcaps {
    font-variant: small-caps;
  }
  
  .text-superscript {
    vertical-align: super;
  }
  
  .text-word-break {
    word-break: break-word;
  }
  
  /*-----------------------------------------------
  |   Font family
  -----------------------------------------------*/
  .font-sans-serif {
    font-family: "Source Sans Pro", "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  }
  
  .font-base {
    font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  }
  
  /*-----------------------------------------------
  |   Error Page
  -----------------------------------------------*/
  .fs-error {
    font-size: 7rem;
  }
  
  @media (min-width: 576px) {
    .fs-error {
      font-size: 10rem;
    }
  }
  
  /*-----------------------------------------------
  |   Text alignment
  -----------------------------------------------*/
  .text-justify {
    text-align: justify !important;
  }
  
  .text-gradient {
    background: -o-linear-gradient(40deg, #fb3c00, #ffb800);
    background: linear-gradient(50deg, #fb3c00, #ffb800);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  /*-----------------------------------------------
  |   Backgrounds
  -----------------------------------------------*/
  .bg-holder {
    position: absolute;
    width: 100%;
    min-height: 100%;
    top: 0;
    left: 0;
    background-size: cover;
    background-position: center;
    overflow: hidden;
    will-change: transform, opacity, filter;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background-repeat: no-repeat;
    z-index: 0;
  }
  
  .bg-holder.bg-right {
    left: auto;
    right: 0;
  }
  
  .bg-holder.overlay:before {
    position: absolute;
    content: "";
    background: rgba(0, 0, 0, 0.25);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  
  .bg-holder.overlay-0:before {
    background: rgba(0, 0, 0, 0.7);
  }
  
  .bg-holder.overlay-1:before {
    background: rgba(0, 0, 0, 0.55);
  }
  
  .bg-holder.overlay-2:before {
    background: rgba(0, 0, 0, 0.4);
  }
  
  .bg-holder.overlay-light:before {
    background: rgba(255, 255, 255, 0.8);
  }
  
  .bg-holder .bg-video {
    position: absolute;
    display: block;
    z-index: -1;
    top: 0;
    left: 0;
    -o-object-fit: cover;
    object-fit: cover;
    height: 100%;
    min-width: 100%;
  }
  
  .bg-holder .bg-youtube {
    position: absolute !important;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
  
  .bg-light-gradient {
    background: #ffffff;
    opacity: 0.85;
  }
  
  
  /* -------------------------------------------------------------------------- */
  /*                                   Borders                                  */
  /* -------------------------------------------------------------------------- */
  .overflow-hidden[class*='rounded'] {
    -webkit-mask-image: radial-gradient(#FFFEFE, #000);
    mask-image: radial-gradient(#FFFEFE, #000);
  }
  
  .border-top-2 {
    border-top-width: 0.125rem !important;
  }
  
  .border-end-2 {
    border-right-width: 0.125rem !important;
  }
  
  .border-bottom-2 {
    border-bottom-width: 0.125rem !important;
  }
  
  .border-start-2 {
    border-left-width: 0.125rem !important;
  }
  
  .hr-vertical {
    border-left: 2px solid #CFCFCF;
  }
  
  /* -------------------------------------------------------------------------- */
  /*                                  Position                                  */
  /* -------------------------------------------------------------------------- */
  .container,
  .container-fluid,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl,
  .container-xxl {
    position: relative;
  }
  
  .all-0 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  
  /* -------------------------------------------------------------------------- */
  /*                                    Flex                                    */
  /* -------------------------------------------------------------------------- */
  .flex-center {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  
  .flex-between-center {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .flex-end-center {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .flex-1 {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  
  .link-black {
    color: #000 !important;
  }
  
  .link-black:hover, .link-black:focus {
    color: black !important;
  }
  
  .link-100 {
    color: #FCFCFC !important;
  }
  
  .link-100:hover, .link-100:focus {
    color: #d6d6d6 !important;
  }
  
  .link-200 {
    color: #F5F5F5 !important;
  }
  
  .link-200:hover, .link-200:focus {
    color: #cfcfcf !important;
  }
  
  .link-300 {
    color: #EEEEEE !important;
  }
  
  .link-300:hover, .link-300:focus {
    color: #c8c8c8 !important;
  }
  
  .link-400 {
    color: #E0E0E0 !important;
  }
  
  .link-400:hover, .link-400:focus {
    color: #bababa !important;
  }
  
  .link-500 {
    color: #BDBDBD !important;
  }
  
  .link-500:hover, .link-500:focus {
    color: #979797 !important;
  }
  
  .link-600 {
    color: #9E9E9E !important;
  }
  
  .link-600:hover, .link-600:focus {
    color: #787878 !important;
  }
  
  .link-700 {
    color: #757575 !important;
  }
  
  .link-700:hover, .link-700:focus {
    color: #4f4f4f !important;
  }
  
  .link-800 {
    color: #616161 !important;
  }
  
  .link-800:hover, .link-800:focus {
    color: #3b3b3b !important;
  }
  
  .link-900 {
    color: #424242 !important;
  }
  
  .link-900:hover, .link-900:focus {
    color: #1c1c1c !important;
  }
  
  .link-1000 {
    color: #212121 !important;
  }
  
  .link-1000:hover, .link-1000:focus {
    color: black !important;
  }
  
  .link-white {
    color: #FFFEFE !important;
  }
  
  .link-white:hover, .link-white:focus {
    color: #ffb2b2 !important;
  }
  
  .hover-top-shadow {
    -webkit-transform: translateY(0) translateZ(0);
    transform: translateY(0) translateZ(0);
    -webkit-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
  }
  
  .hover-top-shadow.hover-top-shadow-lg:hover, .hover-top-shadow.hover-top-shadow-lg:focus {
    -webkit-transform: translateY(-0.3125rem) translateZ(0);
    transform: translateY(-0.3125rem) translateZ(0);
  }
  
  .hover-top-shadow:hover, .hover-top-shadow:focus {
    -webkit-transform: translateY(-0.125rem) translateZ(0);
    transform: translateY(-0.125rem) translateZ(0);
    -webkit-box-shadow: 0 0 0.5rem 0 rgba(255, 179, 14, 0.8) !important;
    box-shadow: 0 0 0.5rem 0 rgba(255, 179, 14, 0.8) !important;
  }
  
  .shadow-transition {
    -webkit-box-shadow: 0 1.5625rem 2.8125rem rgba(66, 66, 66, 0.075);
    box-shadow: 0 1.5625rem 2.8125rem rgba(66, 66, 66, 0.075);
    -webkit-transition: -webkit-box-shadow 0.4s ease;
    transition: -webkit-box-shadow 0.4s ease;
    -o-transition: box-shadow 0.4s ease;
    transition: box-shadow 0.4s ease;
    transition: box-shadow 0.4s ease, -webkit-box-shadow 0.4s ease;
  }
  
  .shadow-warning {
    -webkit-box-shadow: 0 0 3rem 0 rgba(255, 179, 14, 0.3) !important;
    box-shadow: 0 0 3rem 0 rgba(255, 179, 14, 0.3) !important;
  }
  
  .shadow-icon {
    -webkit-filter: drop-shadow(5px 5px 5px rgba(255, 179, 14, 0.3));
    filter: drop-shadow(5px 5px 5px rgba(255, 179, 14, 0.3));
  }
  
  /*-----------------------------------------------
  |   Icons group
  -----------------------------------------------*/
  .icon-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  
  .icon-group .icon-item:not(:last-child) {
    margin-right: 0.5rem;
  }
  
  .icon-item {
    border-radius: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #757575;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    height: 2.5rem;
    width: 2.5rem;
    border: 0;
    font-size: 0.875rem;
    -webkit-box-shadow: 0 1.5625rem 2.8125rem rgba(0, 87, 255, 0.15);
    box-shadow: 0 1.5625rem 2.8125rem rgba(0, 87, 255, 0.15);
  }
  
  .icon-item:hover, .icon-item:focus {
    background-color: #F5F5F5;
  }
  
  .icon-item.icon-item-sm {
    height: 1.875rem;
    width: 1.875rem;
  }
  
  .icon-item.icon-item-lg {
    height: 2.75rem;
    width: 2.75rem;
  }
  
  .fontawesome-i2svg-active .icon-indicator {
    opacity: 1;
  }
  
  /*-----------------------------------------------
  |   Object fit and Z-index
  -----------------------------------------------*/
  .fit-cover {
    -o-object-fit: cover;
    object-fit: cover;
  }
  
  .z-index-1 {
    z-index: 1;
  }
  
  .z-index-2 {
    z-index: 2 !important;
  }
  
  .z-index--1 {
    z-index: -1;
  }
  

  /*-----------------------------------------------
  |   Miscellaneous
  -----------------------------------------------*/
  .hover-text-decoration-none:hover, .hover-text-decoration-none:focus {
    text-decoration: none;
  }
  
  .resize-none {
    resize: none;
  }
  
  .collapsed .collapse-icon {
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  
  .collapse-icon {
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  
  [data-dismiss="dropdown"] *,
  [data-offset-top] *,
  [data-toggle="collapse"] *,
  [data-toggle="tooltip"] *,
  [data-toggle="popover"] * {
    pointer-events: none;
  }
  
  /*-----------------------------------------------
  |   Outline
  -----------------------------------------------*/
  .outline-none {
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  
  .outline-none:hover, .outline-none:focus {
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  
  /*-----------------------------------------------
  |   Vertical Line (used in kanban header)
  -----------------------------------------------*/
  .vertical-line:after {
    position: absolute;
    content: "";
    height: 75%;
    width: 1px;
    background: #EEEEEE;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  
  .vertical-line.vertical-line-400:after {
    background-color: #E0E0E0;
  }
  
  /* -------------------------------------------------------------------------- */
  /*                                 Transition                                 */
  /* -------------------------------------------------------------------------- */
  .transition-base {
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  }
  
  .transition-none {
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
  }
  
  .fsp-75 {
    font-size: 75%;
  }
  
  /* -------------------------------------------------------------------------- */
  /*                                    Width                                   */
  /* -------------------------------------------------------------------------- */
  .min-w-0 {
    min-width: 0;
  }
  
  
  /*-----------------------------------------------
  |   LaslesVPN glow buttons
  -----------------------------------------------*/
  .btn-glow {
    position: relative;
  }
  
  .btn-glow::before {
    content: '';
    height: 80%;
    width: 80%;
    position: absolute;
    top: 10%;
    left: 10%;
    display: inline-block;
    background-color: inherit;
    opacity: 0.55;
    -webkit-filter: blur(26px);
    filter: blur(26px);
    -webkit-transform: translateY(45%);
    -ms-transform: translateY(45%);
    transform: translateY(45%);
    z-index: -1;
    border-radius: 10px;
  }
  
  .btn-gradient:hover {
    -webkit-box-shadow: 0 10px 20px #D42DA3, 0 6px 6px #FFB30E;
    box-shadow: 0 10px 20px #D42DA3, 0 6px 6px #FFB30E;
  }
  
  .btn {
    color: #FFFEFE;
  }
  
  
  .badge-foodwagon {
    border-radius: 0 1.25rem 0 1rem;
  }
  
  .card-badge {
    position: absolute;
    top: 13rem;
    z-index: 1;
  }
  
  .card-actions {
    position: absolute;
    bottom: 0;
    z-index: 1;
    width: 100%;
  }
  
  .card-span {
    -webkit-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
    border: 0;
  }
  
  .card-span .card-span-img {
    position: absolute;
    left: 50%;
    -webkit-transform: translate3d(-50%, -50%, 0);
    transform: translate3d(-50%, -50%, 0);
  }
  
  