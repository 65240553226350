.hacc__navbar{
    /* position: absolute; */
    /* position: static; */

    background: rgb(244, 0, 0);
    backdrop-filter: blur(2px);
    background: transparent;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 41.8px 33.4px rgba(0, 0, 0, 0.086);
    padding: 1rem 6rem;
 

}


#logo{
    width: 100px;
    height: 70px;

}

.hacc__navbar-links{
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
 

}

.hacc__navbar-links_logo{
    margin-right: 2rem;
 


}

.hacc__navbar-links_logo img{
    width: 100px;
    height: 70px;
 

}

.hacc__navbar-links_container {
    display: flex;
    flex-direction: row;
 

}
.hacc__navbar-sign{
    display: flex;
    justify-content: flex-end;
    align-items: center;
 

}
.hacc__navbar-links_container  p, 
.hacc__navbar-sign  p,
.hacc__navbar-menu_container  p{
    color: var(--header-link-color);
    font-family: var(--font-family);
    font-weight: bolder;
    font-size: 18px;
    line-height: 25px;
    text-transform: capitalize;

    margin: 0 1rem;
    cursor: pointer;
 

}

.hacc__navbar-sign button,
.hacc__navbar-menu_container button{
    padding: 0.5rem 1rem;
    color: rgb(255, 255, 255);
    background: #FF4820;
    font-family: var(--font-family);
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    border-radius: 5px;
    border: 0;
    outline: none;
    cursor: pointer;
 

}
.hacc__navbar-menu{
    margin-left: 1rem;
    display: none;
    position: relative;
    
 
}

.hacc__navbar-menu svg{
    cursor: pointer;
 

}
.hacc__navbar-links_container div{
  width: 30px;
  height: 3px;
  background: var(--gradient-bar);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-bottom: 0.25rem;
  transition: 0.5s cubic-bezier(0.7, 0.04, 0.66, 1.71);
}
.hacc__navbar-links_container:hover div{
  width: 100%;

}

.hacc__navbar-menu_container{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;

    text-align: end;
    background: var(--color-bg);
    padding: 2rem;
    position: absolute;
    top: 40px;
    right: 0;
    margin-top: 1rem;
    min-width: 210px;
    border-radius: 5px;
    box-shadow: 0 0 5 rgba(0, 0, 0, 0.2);
 

}

.hacc__navbar-menu_container-links p{
    margin: 1rem 0;
 

}

.hacc__navbar-menu_container-links-sign{
    display: none;
 

}

@media screen and ( max-width: 1198px ) {
    .hacc__navbar-links_container{
        display: none;
 

    }
    .hacc__navbar-menu{
        display: flex;
 

    }
     
}


@media screen and ( max-width: 700px ) {

    .hacc__navbar{ 
        padding: 2rem 4rem;
 

    }

}

@media screen and ( max-width: 550px ) {

    .hacc__navbar{
        padding: 2rem;
 

    }

    .hacc__navbar-sign{
        display: none;
 

    }

    .hacc__navbar-menu_container{
        top: 20px;
 

    }

    .hacc__navbar-menu_container-links-sign{
        display: block ; 
 

    }

}



body {
    font-family: 'Poppins', sans-serif;
  }
  
  p {
    font-size: 16px;
    line-height: 24px;
    @apply text-gray-700;
  }
  .container {
    @apply px-5 mx-auto;
  }
  
  .row {
    @apply flex flex-wrap;
  }

  .header-hero::before {
    content: '';
    z-index: -1;
    opacity: .9;
    @apply absolute top-0 left-0 w-full h-full bg-blue-200;
  }
  .navigation {
    @apply absolute top-0 left-0 z-20 w-full py-5 duration-300 bg-transparent;
    
  }
  .sticky {
    @apply fixed py-4 bg-white shadow;
  }
  
  .collapse.show {
    @apply block;
  }
  .toggler-icon {
    @apply relative block duration-300 bg-gray-700;
    height: 2px;
    width: 30px;
    margin: 6px 0;
  }
  .active>.toggler-icon:nth-child(1) {
    @apply transform rotate-45;
    top: 7px;
  }
  .active>.toggler-icon:nth-child(2) {
    @apply opacity-0;
  }
  .active>.toggler-icon:nth-child(3) {
    top: -8px;
    transform: rotate(135deg);
  }
  
  .navbar-nav .nav-item a {
    @apply relative inline-block px-4 py-3 font-bold text-gray-900 uppercase duration-300;
  }
  
  .navbar-nav .nav-item.active a,
  .navbar-nav .nav-item a:hover {
    @apply text-blue-600;
  }
  .navbar-nav .nav-item.active a::before,
  .navbar-nav .nav-item.active a::after,
  .navbar-nav .nav-item a:hover::before,
  .navbar-nav .nav-item a:hover::after {
    @apply opacity-100;
  }
  
  .navbar-nav .nav-item a::after,
  .navbar-nav .nav-item a::before {
    content: '';
    @apply absolute bottom-0 w-2 h-1 duration-300 bg-blue-600 opacity-0;
  }
  .navbar-nav .nav-item a::before {
    left: calc(50% - 15px);
  }
  .navbar-nav .nav-item a::after {
    left: calc(50% + 5px);
  }
  
  .main-btn.video-popup {
    @apply text-blue-600 bg-white;
  }
  .main-btn.video-popup:hover {
    @apply bg-white shadow-xl;
  }
  
  .gradient-btn {
    background-image: linear-gradient(to right, #0067f4 0%, #2bdbdc 50%, #0067f4 100%);
    background-size: 200% auto;
    overflow: hidden;
  }
  .gradient-btn:hover {
    background-position: right center;
    @apply shadow-xl;
  }
  
  .title {
    line-height: 55px;
    @apply mb-4 text-5xl font-semibold text-gray-900;
  }
  
  .services-title {
    @apply mb-2 text-2xl font-medium text-gray-900;
  }
  
  .services-icon i {
    font-size: 64px;
    @apply text-blue-600;
  }
  .services-image {
    @apply absolute top-0 right-0 flex items-center justify-center w-1/3 h-full mr-8;
  }
  @media (max-width: 1024px) {
    .services-image {
      @apply static w-full px-8 mt-10;
    }
    .call-action-image {
      @apply static w-full;
    }
  }
  .single-pricing {
  
    @apply relative z-10 px-5 pt-6 pb-12 mx-4 mb-8 overflow-hidden bg-white rounded shadow-lg;
  }
  
  .sub-title {
    @apply relative pb-2 mb-8 text-xl font-semibold;
  }
  
  .sub-title::after {
    content: '';
    @apply absolute bottom-0 left-0 right-0 w-20 h-1 m-auto bg-teal-300;
  }
  
  .pro .sub-title::after {
    @apply ml-0 bg-blue-600;
  }
  
  .enterprise .sub-title::after {
    @apply ml-auto mr-0 bg-blue-600;
  }
  
  .price {
    @apply text-6xl font-bold text-gray-900;
  }
  .year {
    @apply mb-8 text-xl font-medium text-gray-700;
  }
  
  .pricing-list ul li {
    @apply mb-4 text-gray-900;
  }
  
  .pricing-list ul li i {
    @apply mr-3 text-blue-600;
  }
  
  .main-btn {
    @apply inline-block px-8 py-4 font-bold text-white duration-300 bg-blue-700 rounded-full;
  }
  .main-btn:hover {
    @apply bg-blue-400;
  }
  .bottom-shape {
    z-index: -1;
    @apply absolute bottom-0 left-0 w-full -mb-5;
  }
  
  .call-action-content {
    max-width: 560px;
  }
  .call-action-image img {
    @apply w-full h-full;
    object-position: center center;
    object-fit: cover;
  }
  
  .single-testimonial {
    @apply p-10 mx-4 mt-8 mb-12 bg-white shadow-lg;
  }
  .testimonial-image {
    @apply w-24 h-24 mx-auto mb-8 overflow-hidden rounded-full;
  }
  
  .slick-slide:focus {
    @apply outline-none;
  }
  .slick-dots {
    @apply absolute bottom-0 left-0 right-0 flex justify-center mx-auto -mb-10;
  }
  .slick-dots li {
    @apply leading-none;
  }
  .slick-dots button {
    text-indent: -999999px;
    @apply w-3 h-3 m-1 duration-300 bg-blue-100 border-0 rounded-full;
  }
  .slick-dots .slick-active button,
  .slick-dots button:hover {
    @apply bg-blue-600;
  }
  .slick-dots button:focus {
    @apply outline-none;
  }
  
  .single-form textarea,
  .single-form input {
    @apply w-full px-6 py-3 mb-1 duration-300 bg-white border border-gray-300 rounded;
  }
  .single-form textarea:focus,
  .single-form input:focus {
    @apply border-blue-600 outline-none;
  }
  .form-message.error {
    @apply text-red-500;
  }
  .form-message.success {
    @apply text-green-400;
  }
  
  .footer-social li a {
    @apply inline-block mr-2 text-2xl text-gray-800 duration-300;
  }
  .footer-social li a:hover {
    @apply text-blue-600;
  }
  .footer-title {
    @apply mb-5 text-sm font-bold text-gray-700;
  }
  .footer-link ul li a {
    @apply inline-block mb-3 font-medium text-gray-900 duration-300;
  }
  .footer-link ul li a:hover {
    @apply text-blue-600;
  }
  
  
  .back-to-top {
    @apply fixed bottom-0 right-0 z-20 flex items-center justify-center w-10 h-10 mb-8 mr-8 text-white duration-300 bg-blue-600 rounded;
  }
  
  






