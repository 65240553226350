*{
    padding: 0;
    margin: 0;

}

.hacc__homeAbout{
    min-height: 100vh;
    min-width: 100vw;
    
    /* background-image: url(../../assets/awsome-bg-about.jpg); */

    background-position: center;
    position: relative;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}
