.hacc_ourPartners{

    min-height: 70vh;
    width: 100vw;
    background-size: cover;
    background-position: center;
    background-image: url(/public/assets/images/home/partner.jpeg);
}

.gpt3__brand{
    display:  flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.gpt3__brand div{
    flex: 1;
    max-width: 150px;
    min-width: 120px;
    margin: 1rem;

    display: flex;
    justify-content: center;
    align-items: center;
}